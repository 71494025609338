import React, { Component } from 'react';
import { render } from 'react-dom';
import {mdiCamera, mdiClose, mdiFile} from '@mdi/js';
import { Icon } from '@mdi/react';
import Modal from 'react-modal';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import professionalResource from "../resources/professional";
import scheduleResource from "../resources/schedule";
import dataUtils from "../utils/data";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import Button from "./Button";
import Moment from "moment";
import Loader from "./Loader";

Modal.defaultStyles.content = {
    ...Modal.defaultStyles.content,
    // right: 'calc(50% - 405px)',
    // left: 'calc(50% - 405px)',
    // top: 'calc(50% - 405px)',
    // bottom: 'unset',
    // top: 0,
    // bottom: 0,
    // left: 0,
    // right: 0,
    // maxWidth: 810,
    // width: 810,
    // minWidth: 810,
    // padding: 24,
    // borderRadius: 8,
    // background: '#fff',
    // borderColor: '#e7e7e7',
    // height: 'auto',
    maxHeight             : '100%',
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    zIndex                : 10,
    overflow              : 'scroll',

};

Modal.defaultStyles.overlay = {
    ...Modal.defaultStyles.overlay,
    backgroundColor: "rgba(55,55,55, 0.7)"
};


const mapStateToProps = store => ({
    isLogged: store.authReducer.isLogged,
    isLoaded: store.authReducer.isLoaded,
    isLoading: store.authReducer.isLoading,
    loadError: store.authReducer.loadError,
    user: store.authReducer.user,
    token: store.authReducer.token,
    callStatus: store.videoCallReducer.status,
    callToken: store.videoCallReducer.token,
    roomName: store.videoCallReducer.roomName,
    roomId: store.videoCallReducer.roomId,
});

class AgendaSelectModal extends Component {
    constructor(props) {
        super(props);

        const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

        this.state = {
            isRunningService: false,
            removing: false,
            agenda: [],
            calendarWeekends: true,
            maxHeight: 200,
            professional: props.professional,
            availability: props.professional.availability ? props.professional.availability.map(item => {
                let date = new Date();
                let daytoset = weekdays.indexOf(item.weekday); // given: a Date object and a integer representing the week day
                let currentDay = date.getDay();
                let distance = daytoset - currentDay;
                date.setDate(date.getDate() + distance);

                const startTime = item.start.split(':');
                const endTime = item.end.split(':');
                const start = new Date(date.getFullYear(), date.getMonth(), date.getDate(), startTime[0], startTime[1], startTime[2]);
                const end = new Date(date.getFullYear(), date.getMonth(), date.getDate(), endTime[0], endTime[1], endTime[2]);

                return {
                    id: item.id || start.getTime(),
                    start,
                    end,
                    weekday: weekdays[new Date(start).getDay()],
                }
            })  : [],
        };
    }

    calendarRef = React.createRef();

    componentDidMount() {
        const maxHeight = document.getElementById('root').clientHeight;
        this.setState({
            maxHeight: maxHeight * 0.7
        });
    }

    handleDateSelect = (info) => {

        const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

        const id = new Date().getTime();

        this.setState({  // add new event data
            availability: [
                ...this.state.availability,
                {
                    // title: "agenda liberada",
                    id,
                    weekday: weekdays[new Date(info.startStr).getDay()],
                    start: info.startStr,
                    end: info.endStr,
                    extendedProps: {
                        loading: true,
                    }
                }
            ]
        });
        this.createAvailability({
            id,
            weekday: weekdays[new Date(info.startStr).getDay()],
            start: info.startStr,
            end: info.endStr,
        });
    }

    createAvailability = async (availability) => {

        this.wrapperElementThead();
        try {

            const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

            const start = new Date(availability.start);
            const end = new Date(availability.end);
            const availabilityObj = {
                // id: availability.id,
                weekday: weekdays[new Date(availability.start).getDay()],
                start: ((start.getHours() < 10 ? '0' : '') + start.getHours())+":"+((start.getMinutes() <10 ? '0' : '') + start.getMinutes())+":00",
                end: ((end.getHours() < 10 ? '0' : '') + end.getHours())+":"+((end.getMinutes() <10 ? '0' : '') + end.getMinutes())+":00",
            }

            let response = await scheduleResource.createAvailability(this.props.professional.id, availabilityObj, this.props.token);

            if (response.status !== 200) {
                throw new Error(response.data.message);
            }

            // if(this.state.availability.filter(item => String(item.id) === response.data.id).length > 0){
            //     console.log("retornou um id que já tem", response.data.id);
            // }

            this.setState({
                availability: this.state.availability.map(item => {
                    // console.log("add availability", String(item.id) === String(availability.id), item.id, availability.id);
                    // if(String(item.id) === String(availability.id)){
                    //     console.log("atualizou ", item);
                    // }

                    return String(item.id) === String(availability.id) ? {...item, id: response.data.id, extendedProps: {loading: false}} : item;
                })
            }, () => {
                // console.log(this.state.availability.map(item => item.id));
            });

            this.setState({
                isRunningService: false,
            });

        } catch(err){
            console.log(err);

            this.setState({
                availability: this.state.availability.filter(item => String(item.id) !== String(availability.id)),
                isRunningService: false,
            });

            alert(err.message);
            
        }
        this.wrapperElementThead();
    }

    removeAvailability = async (info) => {      

        const availabilityId = String(info.event.id);

        try {
            
            const removingItem = this.state.availability.filter(item => String(item.id) === availabilityId)[0];
            if(removingItem.extendedProps && removingItem.extendedProps.removing){   //evitando tentar remover evento 2 vezes
                return;
            }

            this.setState({
                removing: true,
                availability: this.state.availability.map(item => {
                    return String(item.id) === availabilityId ? {...item, extendedProps: {loading: true, removing: true}} : item;
                })
            });

            let response = await scheduleResource.removeAvailability(this.props.professional.id, availabilityId, this.props.token);

            if (response.status !== 200) {
                throw new Error(response.data.message);
            }

            this.setState({
                removing: false,
                availability: this.state.availability.filter(item => String(item.id) !== String(info.event.id)),
                isRunningService: false,
            });

            info.event.remove();

        } catch(err){
            this.setState({
                removing: false,
                availability: this.state.availability.map(item => String(item.id) === availabilityId ? {...item, extendedProps: {loading: false}} : item),
                isRunningService: false,
            });

            alert(err.message);
        }
        this.wrapperElementThead();
    }

    handleClose = () => {
        const { onCloseRequest } = this.props;
        const { availability } = this.state;

        if(this.state.isRunningService){
            alert("Existem serviços sendo executados. Por gentileza, aguarde a finalização.");
            return;
        }

        onCloseRequest(availability.map(item => {
            return {
                ...item,
                start: Moment(item.start).format('HH:mm')+":00",
                end: Moment(item.end).format('HH:mm')+":00",
            }
        }));

        document.getElementsByTagName('body')[0].style.cssText = 'overflow:scroll';
    }

    wrapperElementThead = () => {
        try{
            let thScheduleModal = document.createElement('th');
            thScheduleModal.classList.add("schedule-thead");
            thScheduleModal.setAttribute("style", "position: sticky;top: -25px;padding: 15px;background-color: #fff;z-index: 10;");
    
            let tableHead = document.querySelectorAll("thead.fc-head")[0];
    
            tableHead.parentNode.insertBefore(thScheduleModal, tableHead);
            thScheduleModal.appendChild(tableHead);
        }catch(err){
        }
    }

    render() {
        return (
            <Modal
                isOpen={this.props.visible}
                onRequestClose={this.handleClose}
                contentLabel={'Selecionar agenda'}
                onAfterOpen={this.wrapperElementThead}
                //shouldCloseOnOverlayClick={true}
                style={{
                    content: {
                        maxWidth: '80%',
                    }
                }}
            >
                <span style={{position: 'absolute', right: 20, top: 20, cursor: 'pointer'}} onClick={this.handleClose} className={'btn-close'}>
                    <Icon path={mdiClose} title="Agenda" size={1} color={'#6F6F6F'}/>
                </span>

                <h2 style={{fontWeight: 'bold', textAlign: 'left'}} className={'title-schedule'}>
                    Configuração da agenda
                </h2>

                <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                    <div style={{flex: 1}}>
                        <FullCalendar
                            slotDuration="00:15"
                            ref={this.calendarRef}
                            defaultView="timeGridWeek"
                            plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin ]}
                            // weekends={false}
                            locale={"pt-br"}
                            loading={true}
                            events={this.state.availability}
                            eventRender={(info) => {
                                render(
                                    <div className={"fc-content"+(Moment(info.event.start).format('HH:mm')+"-"+Moment(info.event.end).format('HH:mm'))}>
                                        <span className="fc-title">
                                            {info.event.title}
                                            {info.event.extendedProps.loading && <Loader negative={true} style={{width: 25, top: -3, right: -3, position: 'absolute'}}/>}
                                        </span>
                                        <span>{Moment(info.event.start).format('HH:mm')} - {Moment(info.event.end).format('HH:mm')}</span>
                                    </div>,
                                    info.el,
                                );
                                return info.el
                            }}
                            selectable={true}
                            // selectMirror={true}
                            // unselectAuto={true}
                            header={{
                                left: '',
                                center: '',
                                right: '',
                            }}
                            allDaySlot={false}
                            allDayText={"Dia todo"}
                            buttonText={{
                                today:    'Hoje',
                                month:    'Mês',
                                week:     'Semana',
                                day:      'Dia',
                                list:     'Lista',
                            }}
                            select={async (info) => {

                                this.setState({
                                    isRunningService: true,
                                });

                                await this.handleDateSelect(info);
                            }}
                            selectOverlap={false}
                            eventClick={async (info) => {
                                // if(this.state.removing){
                                //     return;
                                // }

                                this.setState({
                                    isRunningService: true,
                                });

                                await this.removeAvailability(info);
                            }}
                            columnHeaderText={(date) => {
                                const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
                                return dataUtils.getWeekdayNameShort(weekdays[new Date(date).getDay()]);
                            }}
                            slotLabelFormat={(info) => {    //como aparece do lado esquerdo
                                return (String(info.date.hour).length === 2 ? info.date.hour : "0"+info.date.hour)+":"+(String(info.date.minute).length === 2 ? info.date.minute : "0"+info.date.minute);
                            }}
                            height={this.state.maxHeight}
                            eventBackgroundColor={'#1F295C'}
                            eventBorderColor={'#1F295C'}
                            eventTextColor={'#fff'}
                            minTime={"06:00:00"}
                            maxTime={"24:00:00"}
                        />
                    </div>
                </div>

            </Modal>
        );
    }
}

export default connect(mapStateToProps)(withRouter(AgendaSelectModal));
