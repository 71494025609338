import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router";
import Dashboard from "./dashboard";
import Schedule from "../components/Schedule";
import Professionals from '../screens/professionals';
import ProtectedAdminRoute from "../components/ProtectedAdminRoute";
import ProfessionalDashboard from "../screens/professionalDashboard";


const mapStateToProps = store => ({
    user: store.authReducer.user,
    token: store.authReducer.token,
    professional: store.authReducer.professional,
    loading: store.chatReducer.loading,
    fetching: store.chatReducer.fetching,
    messages: store.chatReducer.messages,
    hasMoreMessages: store.chatReducer.hasMoreMessages,
});

class Root extends Component {
    render(){
        return (
            this.props.user.type === "admin"
                ? <ProtectedAdminRoute component={Professionals} />
                : <ProfessionalDashboard />
        );
    }
}

export default connect(mapStateToProps)(withRouter(Root));
