import React, { Component } from 'react';
import {mdiCamera, mdiClose, mdiFile} from '@mdi/js';
import { Icon } from '@mdi/react';
import Modal from 'react-modal';
import Dropzone from 'react-dropzone';
import Moment from 'moment';

import {ReactComponent as UploadIcon} from '../images/upload.svg';

import Input from './Input';
import Button from './Button';
import Select from 'react-select';

import ehr from '../resources/ehr';
import TextArea from "./TextArea";
import Instagram from "../images/instagram.png";
import Facebook from "../images/facebook.png";
import {btn} from "./DocumentWidget/DocumentWidget.module.scss";
import {connect} from "react-redux";
import professionalResource from "../resources/professional";
import EditImageModal from "../components/editImageModal";
import dataUtils from "../utils/data";

Modal.defaultStyles.content = {
    ...Modal.defaultStyles.content,
    // right: 'calc(50% - 405px)',
    // left: 'calc(50% - 405px)',
    // top: 'calc(50% - 405px)',
    // bottom: 'unset',
    // top: 0,
    // bottom: 0,
    // left: 0,
    // right: 0,
    // maxWidth: 810,
    // width: 810,
    // minWidth: 810,
    // padding: 24,
    // borderRadius: 8,
    // background: '#fff',
    // borderColor: '#e7e7e7',
    // height: 'auto',
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
};

Modal.defaultStyles.overlay = {
    ...Modal.defaultStyles.overlay,
    backgroundColor: "rgba(55,55,55, 0.7)"
};

const mapStateToProps = store => ({
    token: store.authReducer.token,
});

class CreateOrEditProfessionalModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            editImageModalVisible: false,
            updatedImage: false,
            id: props.professional ? props.professional.id : "",
            name: props.professional ? props.professional.name : "",
            email: props.professional ? props.professional.email : "",
            phone: props.professional ? props.professional.phone : "",
            description: props.professional ? props.professional.description : "",
            registry: props.professional ? props.professional.registry : "",
            registryType: props.professional ? {value: props.professional.registryType, label: props.professional.registryType.toUpperCase()} : {value: 'crp', label: 'CRP'},
            instagram: props.professional ? props.professional.instagram : "",
            facebook: props.professional ? props.professional.facebook : "",
            avatar: props.professional ? props.professional.avatar : null,
        };
    }

    onChangeName = (event) => {
        // event.preventDefault();
        this.setState({
            name: event.target.value,
        });
    }

    onChangeEmail = (event) => {
        // event.preventDefault();
        this.setState({
            email: event.target.value,
        });
    }

    onChangeFacebook = (event) => {
        // event.preventDefault();
        this.setState({
            facebook: event.target.value,
        });
    }

    onChangeInstagram = (event) => {
        // event.preventDefault();
        this.setState({
            instagram: event.target.value,
        });
    }

    onChangeDescription = (event) => {
        // event.preventDefault();
        this.setState({
            description: event.target.value,
        });
    }

    onChangePhone = (event) => {
        // event.preventDefault();
        this.setState({
            phone: dataUtils.maskPhone(event.target.value),
        });
    }

    onChangeRegistry = (event) => {
        // event.preventDefault();
        this.setState({
            registry: event.target.value,
        });
    }

    handleChangeRegistryType = (registryType) => {
        this.setState({
            registryType
        });
    }

    onSubmit = async () => {
        try {
            if(this.state.loading){
                return;
            }

            this.setState({
                loading: true,
            });

            if(
                (!this.state.name || this.state.name === "") ||
                (!this.state.email || this.state.email === "") ||
                (!this.state.phone || this.state.phone === "") ||
                (!this.state.description || this.state.description === "") ||
                (!this.state.registryType || this.state.registryType.value === "") ||
                (!this.state.registry || this.state.registry === "")
                // (!this.state.instagram || this.state.instagram === "") ||
                // (!this.state.facebook || this.state.facebook === "")
            ){
                throw new Error("Preencha os campos.")
            }

            let response;
            if(this.props.professional){
                response = await professionalResource.update(this.props.token, this.props.professional.id, {
                    name: this.state.name,
                    email: this.state.email,
                    phone: dataUtils.unmaskPhone(this.state.phone),
                    description: this.state.description,
                    registryType: this.state.registryType.value,
                    registry: this.state.registry,
                    instagram: this.state.instagram,
                    facebook: this.state.facebook,
                    avatar: this.state.avatar
                });
                if (response.status !== 200) {
                    throw new Error(response.data.message);
                }
            }else{
                response = await professionalResource.create(this.props.token, {
                    name: this.state.name,
                    email: this.state.email,
                    phone: dataUtils.unmaskPhone(this.state.phone),
                    description: this.state.description,
                    registryType: this.state.registryType.value,
                    registry: this.state.registry,
                    instagram: this.state.instagram,
                    facebook: this.state.facebook,
                    // avatar: this.state.avatar,
                    type: dataUtils.getProfessionalTypeByRegistryType(this.state.registryType.value)
                });
                if (response.status !== 201) {
                    throw new Error(response.data.message);
                }

                if(this.state.updatedImage){
                    await this.upload(response.data.id);
                }

                response = await professionalResource.update(this.props.token, response.data.id, {
                    avatar: this.state.avatar
                });
                if (response.status !== 200) {
                    throw new Error(response.data.message);
                }
            }

            this.setState({
                loading: false,
            });

            this.props.onCloseRequest(response.data);
        } catch(err){
            this.setState({
                loading: false,
            });

            alert(err.message);
        }
    }

    upload = async (id) => {
        try {
            this.setState({
                uploading: true,
            });

            let responseUpload = await professionalResource.uploadAvatar(this.props.token, id, new File([this.state.avatarBlob], "professional_avatar", { type: "image/jpeg" }));
            console.log("responseUpload", responseUpload);
            if (responseUpload.status !== 201 && responseUpload.status !== 200) {
                throw new Error(responseUpload.data.message);
            }

            this.setState({
                uploading: false,
            });
        } catch(err){
            this.setState({
                uploading: false,
            });

            alert(err.message);
        }
    }

    render() {
        return (
            <Modal
                isOpen={this.props.visible}
                onRequestClose={() => {
                    this.props.onCloseRequest(null)
                }}
                contentLabel={this.props.professional ? 'Editar profissional' : 'Novo profissional'}
                style={{
                    content: {
                        width: '80%',
                    }
                }}
            >
                <span style={{position: 'absolute', right: 20, top: 20, cursor: 'pointer'}} onClick={() => {this.props.onCloseRequest(null)}}>
                    <Icon path={mdiClose} title="Close" size={1} color={'#6F6F6F'}/>
                </span>
                <h2 style={{fontWeight: 'bold', textAlign: 'left'}}>
                    {this.props.professional ? 'Editar profissional' : 'Novo profissional'}
                </h2>

                {/*<form name={'createProfessionalForm'} onSubmit={this.onSubmit}>*/}
                    <div style={{display: 'flex'}}>
                        <div style={{flex: 1, marginRight: 24}}>
                            <div style={{marginBottom: 24, textAlign: 'center'}}>
                                <button
                                    style={{
                                        height: 200,
                                        width: 200,
                                        outline: 'none',
                                        border: 'none',
                                        textAlign: 'center',
                                        borderRadius: '50%',
                                        cursor: 'pointer',
                                        position: 'relative',
                                        backgroundColor: '#fff',
                                        boxShadow: '0 1px 15px 0 rgba(75, 75, 75, 0.3)',
                                        marginBottom: 10
                                    }}
                                    onClick={(event) => {
                                        // event.preventDefault();
                                        this.setState({
                                            editImageModalVisible: true,
                                        });
                                    }}
                                    onMouseEnter={() => {
                                        this.setState({
                                            hoverImage: true
                                        })
                                    }}
                                    onMouseLeave={() => {
                                        this.setState({
                                            hoverImage: false
                                        })
                                    }}
                                >
                                    {this.state.avatar && <img
                                        src={this.state.avatar}
                                        style={{
                                            width: 200,
                                            height: 200,
                                            borderRadius: '50%',
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            zIndex: 0,
                                            opacity: this.state.hoverImage ? 0.2 : 1,
                                            boxShadow: '0 1px 15px 0 rgba(75, 75, 75, 0.3)'
                                        }}
                                    />}

                                    {(!this.state.avatar || (this.state.avatar && this.state.hoverImage)) && <Icon path={mdiCamera} title="Camera" size={3} color={'#6F6F6F'} style={{zIndex: 2, position: 'relative'}}/>}
                                    {(!this.state.avatar || (this.state.avatar && this.state.hoverImage)) && <p style={{zIndex: 2, position: 'relative', fontSize: 18, fontWeight: '600', color: '#6F6F6F'}}>Editar foto</p>}
                                </button>
                            </div>
                            <div style={{marginBottom: 18}}>
                                <Input
                                    styleLabel={{
                                        fontWeight: 400
                                    }}
                                    styleContainer={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: 24,
                                        height: 56
                                    }}
                                    onChange={this.onChangeName}
                                    value={this.state.name}
                                    required={true}
                                    label={'NOME'}
                                    type={'text'}
                                    // placeholder={'Nome do profissional'}
                                />
                            </div>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: 18
                            }}>
                                <div style={{flex: 1, marginRight: 18, width: 75, marginTop: -4}}>
                                    <label style={{color: 'rgb(102, 102, 102)', fontSize: 12, fontWeight: 400}} htmlFor={"registryType"}>CONSELHO</label>
                                    <Select
                                        placeholder="Conselho"
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isSearchable={false}
                                        name="registryType"
                                        options={[
                                            {value: 'crp', label: 'CRP'},
                                            {value: 'crm', label: 'CRM'},
                                            {value: 'crn', label: 'CRN'}
                                        ]}
                                        value={this.state.registryType}
                                        onChange={this.handleChangeRegistryType}
                                        styles={{
                                            container: (provided) => ({
                                                ...provided,
                                                borderColor: '#e7e7e7',
                                            }),
                                            control: (provided) => ({
                                                ...provided,
                                                height: 44,
                                                borderColor: '#e7e7e7',
                                                borderRadius: 4,
                                                boxShadow: '0 2px 6px 0 rgba(231, 231, 231, 0.7)'
                                            }),
                                        }}
                                    />
                                </div>
                                <div style={{flex: 1}}>
                                    <Input
                                        styleContainer={{
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}
                                        styleLabel={{
                                            fontWeight: 400
                                        }}
                                        onChange={this.onChangeRegistry}
                                        value={this.state.registry}
                                        required={true}
                                        label={'REGISTRO PROFISSIONAL'}
                                        type={'text'}
                                        // placeholder={'Registro profissional'}
                                    />
                                </div>
                            </div>
                            <div style={{marginBottom: 18}}>
                                <Input
                                    styleContainer={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                    onChange={this.onChangeEmail}
                                    value={this.state.email}
                                    required={true}
                                    styleLabel={{
                                        fontWeight: 400
                                    }}
                                    label={'EMAIL'}
                                    type={'text'}
                                    // placeholder={'Email'}
                                />
                            </div>
                            <div style={{marginBottom: 18}}>
                                <Input
                                    styleContainer={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                    styleLabel={{
                                        fontWeight: 400
                                    }}
                                    onChange={this.onChangePhone}
                                    value={this.state.phone}
                                    required={true}
                                    label={'CELULAR'}
                                    type={'text'}
                                    // placeholder={'Telefone'}
                                />
                            </div>
                        </div>
                        <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                            <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                                <div style={{marginBottom: 18}}>
                                    <TextArea
                                        styleContainer={{
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}
                                        styleLabel={{
                                            fontWeight: 400
                                        }}
                                        label={"BIO"}
                                        name={"bio"}
                                        value={this.state.description}
                                        onChange={this.onChangeDescription}
                                    />
                                </div>
                                <div style={{marginBottom: 18}}>
                                    <Input
                                        styleContainer={{
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}
                                        styleImage={{
                                            width: 22,
                                            height: 22,
                                            left: 10,
                                            top: 11
                                        }}
                                        style={{
                                            paddingLeft: 40
                                        }}
                                        image={Instagram}
                                        onChange={this.onChangeInstagram}
                                        value={this.state.instagram}
                                        required={true}
                                        // label={'Instagram'}
                                        type={'text'}
                                        placeholder={'Instagram'}
                                    />
                                </div>
                                <div style={{marginBottom: 18}}>
                                    <Input
                                        styleContainer={{
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}
                                        styleImage={{
                                            width: 22,
                                            height: 22,
                                            left: 10,
                                            top: 11
                                        }}
                                        style={{
                                            paddingLeft: 40
                                        }}
                                        image={Facebook}
                                        onChange={this.onChangeFacebook}
                                        value={this.state.facebook}
                                        required={true}
                                        // label={'Facebook'}
                                        type={'text'}
                                        placeholder={'Facebook'}
                                    />
                                </div>
                            </div>
                            <div style={{flex: 0, display: 'flex', justifyContent: 'flex-end'}}>
                                <Button
                                    loading={this.state.loading || this.state.uploading}
                                    text={this.props.professional ? "SALVAR" : "CADASTRAR"}
                                    onClick={this.onSubmit}
                                    style={{
                                        padding: "16px 32px",
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                {/*</form>*/}
                <EditImageModal
                    onCloseRequest={(croppedImageUrl = null, croppedImageBlob) => {
                        console.log("result edit image modal", croppedImageUrl, croppedImageBlob);
                        if(croppedImageUrl){
                            this.setState({
                                editImageModalVisible: false,
                                avatar: croppedImageUrl,
                                avatarBlob: croppedImageBlob,
                                updatedImage: true,
                            }, async () => {
                                if(this.props.professional){
                                    await this.upload(this.props.professional.id);
                                }
                            });
                        }else{
                            this.setState({
                                editImageModalVisible: false,
                            });
                        }
                    }}
                    visible={this.state.editImageModalVisible}
                    // visible={true}
                />
            </Modal>
        );
    }
}

export default connect(mapStateToProps)(CreateOrEditProfessionalModal);
