import _axios from "axios";

import apiConfig from '../configs/api';

export const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json; charset=utf-8",
  "Access-Control-Allow-Headers":
  "Accept, Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With, Access-Control-Allow-Methods"
}

export const axios = _axios.create({
    baseURL: apiConfig.API+"/api",
    headers: defaultHeaders
});

export default async (
    {
        method,
        url,
        headers = undefined,
        params = undefined,
        data = undefined,
        token = undefined,
        onUploadProgress = undefined
    }
) => {
    console.log({
        name: "REQUEST",
        preview: `${method} request in URL: ${url}`,
        value: { method, url, params, data, token, onUploadProgress }
    });

    return axios({
        url,
        method,
        headers: {
            Authorization: token ? `JWT ${token}` : undefined,
            ...headers,
        },
        params,
        data,
        onUploadProgress,
        validateStatus: function (status) {
            // console.log("STATUS VALIDATE", status);

            return status < 500; // Reject only if the status code is greater than or equal to 500
        }
    });
};
