import requestUtils from '../utils/request';
import apiConfig from "../configs/api";

export default {
    getQuiz(alias) {
        return requestUtils({
            url: `${apiConfig.QUIZ_API}/quiz/?alias=${alias}&status=published`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${apiConfig.KEY}`
            }
        });
    },
    getAssessment(id, user) {
        return requestUtils({
            url: `${apiConfig.QUIZ_API}/assessment/${id}?assessmentId=${user}`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${apiConfig.KEY}`
            }
        });
    },
    getQuizAssessment(id, idAssessment) {
        return requestUtils({
            url: apiConfig.QUIZ_API+'/quiz/'+id+'/assessment/'+idAssessment,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${apiConfig.KEY}`
            }
        });
    },
    getQuizAnswers(id, idAssessment) {
        return requestUtils({
            url: apiConfig.QUIZ_API+'/quiz/'+id+'/assessment/'+idAssessment+'/answer',
            method: 'GET',
            headers: {
                Authorization: `Bearer ${apiConfig.KEY}`
            }
        });
    },
};
