import '../../styles/room.scss'

import React, { Component } from 'react';
import { Device } from 'twilio-client';
import callResource from "../../resources/call";
import GenderField from '../GenderField';
import BirthdateField from '../BirthdateField';
import DocumentWidget from '../DocumentWidget';
import { white, documents } from './PhoneRoom.module.scss';
import {ReactComponent as MutedIcon} from '../micIndicator.svg'

import {
  mdiPhoneHangup,
  mdiMicrophoneOff,
  mdiMicrophone,
} from '@mdi/js'
import Icon from "@mdi/react";
import LogoWhite from '../../images/logo-white.svg'

export default class PhoneRoom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      device: null,
      connection: null,
      audioEnabled: true,
      status: 'idle',
      remoteAudioEnabled: true,
    };
  }
  
  onHangup = () => {
    this.props.endCall();
    if (this.state.device)
      this.state.device.disconnectAll();
  }

  getInitials() {
    if (! (this.props.patientInfo && this.props.patientInfo.name)) return;

    const parts = this.props.patientInfo.name.split(' ');
    return parts[0][0] + parts[parts.length - 1][0];
  }

  toogleAudio = (e) => {
    e.preventDefault();

    if (this.state.connection)
      this.state.connection.mute(this.state.audioEnabled);
    this.setState({audioEnabled: !this.state.audioEnabled});
  };

  async fetchContent() {
    const response = await callResource.getPhoneCallToken(this.props.callInfo.token);

    if(!response.data.success){
      throw new Error(response.data.message);
    }

    return {
      token: response.data.token,
    };
  }

  componentDidMount() {
    window.onpopstate = () => {
      if (this.state.device)
        this.state.device.disconnectAll();
        
      this.props.endCall();
    };

    this.fetchContent().then((data) => {
      const device = new Device(data.token, {
        codecPreferences: ["opus", "pcmu"],
        fakeLocalDTMF: true,
        enableRingingState: true
      });

      this.setState({ device });

      device.on("ready", (device) => {
        console.log("Twilio.Device Ready!");
        if (! (this.props.patientInfo && this.props.patientInfo.phone))
          return;

        // get the phone number to connect the call to
        const params = {
          To: this.props.patientInfo.phone
        };

        console.log("Calling " + params.To + "...");
        const connection = device.connect(params);
        this.setState({ connection });

        setTimeout(async () => {
          try {
            await callResource.callStart(
              this.props.callInfo.idAppointment,
              this.props.callInfo.idUserCaller,
              this.props.callInfo.idUserReceiver,
              'phone',
              this.props.callInfo.userName,
              this.props.callInfo.professionalRegistry,
              this.props.callInfo.token
            );
          } catch (e) {
            console.log(e);
          }
        }, 10000);
      });

      device.on("error", (error) => {
        console.log("Twilio.Device Error: " + error.message);
      });

      device.on("connect", (conn) => {
        conn.on('mute', (remoteAudioEnabled) => {
          console.log('mute tooggled');
          this.setStatus({remoteAudioEnabled});
        });

        console.log("Successfully established call!");
        this.setState({status: 'connected'});
      });

      device.on("disconnect", (conn) => {
        console.log("Call ended.");
        this.setState({status: 'disconnected'});
      });
    })
    .catch((reason) => {
      console.log("Chamada nao carregado: " + reason);
    });
  }

  getMessage() {
    switch(this.state.status) {
      case 'idle':
        return 'Chamando...';
      case 'disconnected':
        return 'Chamada finalizada.';
      default:
        return null;
    }
  }

  render() {
    const patientInfo = this.props.patientInfo || {name: "", birthdate: "", gender: "", id: 0};
    const callInfo = this.props.callInfo || {};

    return (
      <div className="room">
          <div className="sidebar">
              <img
                  className={"icon-shadow"}
                  alt={"Logo"}
                  src={LogoWhite}
                  style={{width: 50, height: 'auto'}}
              />
              <div className="info">
                <div className={white}>
                    <h2 style={{fontWeight: 'bold', textAlign: 'left', paddingTop: 12}}>
                      {patientInfo.name}
                    </h2>
                </div>
                <div>
                    <GenderField
                        gender={patientInfo.gender}
                        className={white}
                        iconColor={'#fff'}/>
                </div>
                <div>
                    <BirthdateField
                        birthdate={patientInfo.birthdate}
                        className={white}
                        iconColor={'#fff'}
                    />
                </div>
              </div>
              {(callInfo && callInfo.professionalType !== 'psychologist') ? <div style={{width: '100%'}}>
                    <DocumentWidget
                        className={`${white}
                        ${documents}`}
                        patientId={patientInfo.id}
                    />
                </div> : ''}
          </div>

          {!this.state.remoteAudioEnabled ? <div className="noSound"><MutedIcon /></div> : ''}
          {this.state.status !== 'connected' ? <div style={{color: 'white',zIndex: 1,position: 'absolute',width: '200px',top: '50%',bottom: '50%',left: '40%',right: '50%',fontWeight: 'bold',fontSize: '15pt'}}>{this.getMessage()}</div> : <div className="noVideo">{this.getInitials()}</div>}

          <button className={"main-button end-call-button"} onClick={this.onHangup}>
              <Icon
                  path={mdiPhoneHangup}
                  title="Desligar"
                  size={1.5}
                  color="#fff"
              />
          </button>
          <button className={"main-button audio-control-button"} onClick={this.toogleAudio}>
              <Icon
                  path={this.state.audioEnabled ? mdiMicrophone : mdiMicrophoneOff}
                  title="Audio"
                  size={1.5}
                  color="#373737"
                  style={{marginLeft: -1}}
              />
          </button>
      </div>
  );
  }
};
