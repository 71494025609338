import React, { Component } from 'react';
import {connect} from "react-redux";
import CreateUnavailability from './create';
import ListUnavailability from './list';
import './style.scss';

const mapStateToProps = store => {
  return {
    user: store.authReducer.user,
    token: store.authReducer.token,
}};

class Unavailability extends Component {
    constructor(props){
        super(props);
        this.state = {};
    }

    render() {
      return (
        <div className={"box"} style={{marginBottom: 30}}>
          <div style={{display: 'flex'}}>
            <div style={{flex: 1}}>
                <h3 style={{fontSize: 24}}>Indisponibilidade</h3>
            </div>
          </div>
          <CreateUnavailability professional={this.props.professional}/>
          <ListUnavailability professional={this.props.professional}/>
        </div>
      )
    }
}

export default connect(mapStateToProps)(Unavailability);


