import React, { Component } from 'react';
import {profileButton} from "./ProfileButton.module.scss";
import {connect} from "react-redux";
import {Link} from "react-router-dom";


const mapStateToProps = store => ({
    user: store.authReducer.user,
    professional: store.authReducer.professional,
});

class ProfileButton extends Component {
    constructor(props){
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.setState({
            name: this.props.user.name,
            email: this.props.user.email,
            // title: this.props.professional.title,
            // description: this.props.professional.description,
            // registry: this.props.professional.registry,
            // registryType: this.props.professional.registry_type,
        });
    }
    render() {
        const names = this.props.user.name.trim().split(/\s+/);
        const initials = names[0][0] + names[names.length-1][0];
        return (
            <Link className={profileButton} to="/perfil">
                {initials.toUpperCase()}
            </Link>
        );
    }
}

export default connect(mapStateToProps)(ProfileButton);


