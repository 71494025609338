import callResource from '../resources/call'

export const types = {
    CALL_START: 'CALL_START',
    ON_CALL_RECEIVE: 'ON_CALL_RECEIVE',
    CALL_ACCEPT: 'CALL_ACCEPT',
    ON_CALL_ACCEPT: 'ON_CALL_ACCEPT',
    CLEAR: 'CLEAR',
    END_CALL: 'END_CALL',
    REJECTED_CALL: 'REJECTED_CALL',
};

export const actionCreators = {
    callStart: (idAppointment, idUserCaller, idUserReceiver, patientInfo) => async (dispatch, getState) => {
        try {

            dispatch({
                type: types.CALL_START,
                payload: {
                    patientInfo,
                    callInfo: {
                        idAppointment,
                        idUserCaller,
                        idUserReceiver,
                        patientInfo,
                        professionalRegistry: getState().authReducer.professional.registry,
                        professionalType: getState().authReducer.professional.type,
                        userName: getState().authReducer.user.name,
                        token: getState().authReducer.token,
                    },
                }
            });

        } catch (err) {
            console.log(err);

            alert(err.message);
        }
    },
    onCallReceive: (data) => async (dispatch, getState) => {
        console.log('onCallReceive', data);

        dispatch({
            type: types.ON_CALL_RECEIVE,
            payload: {
                token: data.token,
                callId: data.callId,
                roomId: data.roomId,
                roomName: data.roomName,
            }
        });
    },
    callAccept: () => async (dispatch, getState) => {
        console.log('callAccept');

        try {
            let response = await callResource
              .callAccept(
                getState().videoCallReducer.callId,
                getState().authReducer.user._id,
                getState().authReducer.user.name,
                getState().authReducer.token
              );
            console.log(response);

            if(response.data.success){
                dispatch({
                    type: types.CALL_ACCEPT,
                    payload: {
                        token: response.data.token,
                    }
                });
            }else{
                throw new Error(response.data.message);
            }

        } catch (err) {
            console.log(err);

            alert(err.message);
        }
    },
    onCallAccept: (data) => async (dispatch, getState) => {
        console.log('onCallAccept', data);

        dispatch({
            type: types.ON_CALL_ACCEPT,
            payload: data
        });
    },
    callReject: () => async (dispatch, getState) => {
        console.log('callReject');

        try {
            let response = await callResource.callReject(getState().videoCallReducer.callId, getState().authReducer.user._id, getState().authReducer.token);
            console.log(response);

            if(response.data.success){
                dispatch({
                    type: types.CLEAR
                });
            }else{
                throw new Error(response.data.message);
            }

        } catch (err) {
            console.log(err);

            alert(err.message);
        }
    },
    onCallReject: (data) => async (dispatch, getState) => {
        console.log('onCallReject', data);

        dispatch({
            type: types.REJECTED_CALL
        });
    },
    callCancel: (callId) => async (dispatch, getState) => {
        try {
            await callResource.callCancel(
                callId || getState().videoCallReducer.callId,
                getState().authReducer.user._id,
                getState().authReducer.token
            );
        } catch (err) {
            console.log(err);
        }

        dispatch({
            type: types.CLEAR
        });
    },
    onCallCancel: (data) => async (dispatch, getState) => {
        console.log('onCallCancel', data);

        dispatch({
            type: types.END_CALL
        });
    },
    callEnd: (callId) => async (dispatch, getState) => {
        try {
            await callResource.callEnd(
                callId || getState().videoCallReducer.callId,
                getState().authReducer.user._id,
                getState().authReducer.token
            );
        } catch (err) {
            console.log(err);
        }
        
        dispatch({
            type: types.CLEAR
        });
    },
    onCallEnd: (data) => async (dispatch, getState) => {
        console.log('onCallEnd', data);

        dispatch({
            type: types.END_CALL
        });
    },
    onCallExpire: (data) => async (dispatch, getState) => {
        console.log('onCallExpire', data);

        dispatch({
            type: types.CLEAR
        });
    },
};

const initialState = {
    status: 'idle', //idle, calling, receiving, accepted, ended, rejected
    token: null,
    callId: null,
    roomName: null,
    roomId: null,
    error: null,
    patientInfo: null,
    callInfo: null,
};

export default function call(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case types.CALL_START: {
            return {
                ...state,
                status: "calling",
                callInfo: payload.callInfo,
                token: payload.token,
                callId: payload.callId,
                roomId: payload.roomId,
                roomName: payload.roomName,
                patientInfo: payload.patientInfo,
            };
        }
        case types.ON_CALL_RECEIVE: {
            return {
                ...state,
                status: "receiving",
                callId: payload.callId,
                roomId: payload.roomId,
                roomName: payload.roomName,
            };
        }
        case types.CALL_ACCEPT: {
            return {
                ...state,
                status: "accepted",
                token: payload.token,
            };
        }
        case types.ON_CALL_ACCEPT: {
            return {
                ...state,
                status: "accepted"
            };
        }
        case types.END_CALL: {
            return {
                ...state,
                status: 'ended',
            };
        }
        case types.REJECTED_CALL: {
            return {
                ...state,
                status: 'rejected',
            };
        }
        case types.CLEAR: {
            return initialState;
        }
        default:
            return state;
    }
}
