module.exports = function generatorEmptyData(date){
    return {
        "date": `${date}`,
        "availability": 0,
        "plan": {
          "start": 0,
          "flex": 0,
          "premium": 0
        },
        "trial": {
          "yes": 0,
          "no": 0
        }
    }
}

