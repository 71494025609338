import { item, btnWrapper, btn } from './DocumentWidget.module.scss';

import React, { Component } from 'react';
import {connect} from "react-redux";
import Moment from 'moment';

import Widget from '../Widget';
import DocumentDetails from '../DocumentDetails';
import Loader from '../Loader';
import Button from '../Button';
import CreateDocumentModal from '../CreateDocumentModal';

import ResourceEHR from '../../resources/ehr';

const mapStateToProps = store => ({
    token: store.authReducer.token,
    professional: store.authReducer.professional,
});

class DocumentWidget extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            uploadModalOpen: false,
            documents: [],
        };
    }

    componentDidMount() {
        this.setState({loading: true});
        this.fetchDocuments()
        .finally(() => {
            this.setState({loading: false});
        });
    }

    onNewDocumentClick = (e) => {
        console.log(`[DocumentWidget] - opening modal`);
        e.preventDefault();

        this.setState({
            uploadModalOpen: true
        });
    }

    onModalCloseRequest = async () => {
        this.setState({
            uploadModalOpen: false
        });

        await this.fetchDocuments();
        console.log(`[DocumentWidget] - closing modal`);
    }

    fetchDocuments = async () => {
        if (! (this.props && this.props.patientId))
            return;

        console.log(`[DocumentWidget] - requesting documents`);
        const response = await ResourceEHR.fetch(this.props.patientId, this.props.token);
        if (! response.data.success) {
            throw new Error(response.data.message);
        }

        const documents = response.data.documents.sort((a, b) => {
            return Moment(b.created_at).diff( Moment(a.created_at) );
        });

        this.setState({
            documents: documents,
            uploadModalOpen: false
        });
    }

    documentList() {
        return this.state.documents.length
            ? this.state.documents.map(document => {
                return (<div className={item} key={document._id}><DocumentDetails document={document} /></div>);
            })
            : (<p>Nenhum arquivo foi encontrado.</p>)
        ;
    }

    getCategories(type) {
        return {
            'nutritionist': [{value: 'diet', label: 'Plano alimentar'}],
            'doctor': [
                {value: 'prescription', label: 'Receitas médicas'},
                {value: 'exam-order', label: 'Pedidos de exames'},
                {value: 'medical-certificate', label: 'Atestados'},
                {value: 'other', label: 'Outros'},
            ]
        }[type];
    }

    render() {
        return (
            <Widget className={this.props.className || ''} title={'Documentos'}>
                {this.state.loading ? <Loader/> : this.documentList()}
                <div className={btnWrapper}>
                    <Button text="Novo" className={btn} onClick={this.onNewDocumentClick}/>
                </div>
                <CreateDocumentModal onCloseRequest={this.onModalCloseRequest} categories={this.getCategories(this.props.professional.type) || []} token={this.props.token} isOpen={this.state.uploadModalOpen} patientId={this.props.patientId}/>
            </Widget>
        );
    }
}

export default connect(mapStateToProps)(DocumentWidget);