import React, { Component } from 'react';

import { connect } from 'react-redux';

import Logo from '../images/logo.svg';
import Button from '../components/Button';
import Input from '../components/Input';

import { actionCreators as actionCreatorsAuth } from '../reducers/auth';

import userResource from '../resources/user'
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom';
import Layout from "../components/Layout";

const mapStateToProps = (state) => ({
    isLogged: state.authReducer.isLogged,
    isLogging: state.authReducer.isLogging,
    error: state.authReducer.error,
});

class Login extends Component {

    constructor(props){
        super(props);

        this.state = {
            loading: false,
            email: '',
            password: '',
        }
    }

    onChangeEmail = (event) => {
        this.setState({
            email: event.target.value
        });
    };

    onChangePassword = (event) => {
        this.setState({
            password: event.target.value
        });
    };

    onLogin = async (event) => {
        event.preventDefault();

        try {
            if(!this.state.email || !this.state.password){
                throw new Error('Preencha os campos');
            }

            this.setState({
                loading: true,
            });

            let response = await userResource.login({
                email: this.state.email,
                password: this.state.password,
            });

            if (response.status === 401) {
                throw new Error("Não autorizado.");
            }

            this.setState({
                loading: false,
            });

            await localStorage.setItem('token', response.data.token);
            await localStorage.setItem('type', response.data.user.type);

            this.props.dispatch(actionCreatorsAuth.setIsLogged(response.data.token));

            this.props.history.push('/');
        } catch(err){
            console.log(err);

            this.setState({
                loading: false,
            });

            alert(err.message);
        }
    };

    render(){
        return (
            <Layout
                style={{padding: 0}}
                title={"Entrar"}
                description={"Acesse sua conta bem.care para administrar seus pacientes de consulta online."}
            >
                <div style={{width: 350, marginTop: 140}}>
                    <div className={"auth-box"} style={{textAlign: 'center', padding: 30}}>
                        <form
													style={{textAlign: "center"}}
													onSubmit={this.onLogin}>
                            <Input
															id={"email"}
															name={"email"}
															type={"text"}
															placeholder={"Email"}
															style={{width: "100%", marginBottom: 10}}
															onChange={this.onChangeEmail}
															value={this.state.email}/>
                            <Input
															id={"password"}
															name={"password"}
															type={"password"}
															placeholder={"Senha"}
															style={{width: "100%"}}
															onChange={this.onChangePassword}
															value={this.state.password}/>

                            <Button text={"ENTRAR"} type={"submit"} style={{width: 150}}/>

                            <div style={{marginTop: 40, textAlign: "left", marginBottom: 30}}>
                                <NavLink to="/redefinir" exact className={"link"} style={{fontSize: 14, fontWeight: '600', color: '#FC1481', textDecoration: 'underline'}}>
                                   Esqueci minha senha.
                                </NavLink>
                            </div>
                        </form>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default connect(mapStateToProps)(withRouter(Login));
