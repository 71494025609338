exports.getAppointmentStatusText = (status) => {
    if(status === "created"){
        return "Agendado";
    }else if(status === "confirmed"){
        return "Confirmado";
    }else if(status === "noshow"){
        return "No show";
    }else if(status === "finished"){
        return "Atendido";
    }else if(status === "cancelado"){
        return "Canceled";
    }else{
        return status
    }
};

exports.getAppointmentStatusColor = (status) => {
    if(status === "created"){
        return "#1F295C";
    }else if(status === "confirmed"){
        return "#1F295C";
    }else if(status === "noshow"){
        return "#DC3545";
    }else if(status === "finished"){
        return "#28A745";
    }else if(status === "cancelado"){
        return "#676767";
    }else{
        return "#676767"
    }
};

exports.getProfessionalNameByType = (professionalType) => {
    if(professionalType === "doctor"){
        return "Médico";
    }else if(professionalType === "psychologist"){
        return "Psicólogo";
    }else if(professionalType === "nutritionist"){
        return "Nutricionista";
    }else{
        return professionalType
    }
};

exports.getProfessionalColorByType = (professionalType) => {
    if(professionalType === "doctor"){
        return "#1F295C";
    }else if(professionalType === "psychologist"){
        return "#FFC107";
    }else if(professionalType === "nutritionist"){
        return "#28A745";
    }else{
        return "#666"
    }
};

exports.getProfessionalStatusText = (status) => {
    if(status === "inactive"){
        return "Inativo";
    }else if(status === "active"){
        return "Ativo";
    }else{
        return status
    }
};

exports.getPatientStatusText = (status) => {
    if(status === "reserved"){
        return "Ativo";
    }else if(status === "canceled"){
        return "Cancelado";
    }else{
        return status
    }
};

exports.getPatientStatusColorByType = (status) => {
    if(status === "reserved"){
        return "#28A745";
    }else if(status === "canceled"){
        return "#A7A7A7";
    }else{
        return "#A7A7A7"
    }
};

exports.getProfessionalTypeByRegistryType = (registryType) => {
    if(registryType === "crm"){
        return "doctor";
    }else if(registryType === "crp"){
        return "psychologist";
    }else if(registryType === "crn"){
        return "nutritionist";
    }else{
        throw new Error("RegistryType not recognized.")
    }
};

exports.getWeekdayName = (weekday) => {
    if (weekday === "monday") {
        return "Segunda-feira";
    } else if (weekday === "tuesday") {
        return "Terça-feira";
    } else if (weekday === "wednesday") {
        return "Quarta-feira";
    } else if (weekday === "thursday") {
        return "Quinta-feira";
    } else if (weekday === "friday") {
        return "Sexta-feira";
    } else if (weekday === "saturday") {
        return "Sábado";
    } else if (weekday === "sunday") {
        return "Domingo";
    }
};

exports.getWeekdayNameShort = (weekday) => {
    if (weekday === "monday") {
        return "Seg";
    } else if (weekday === "tuesday") {
        return "Ter";
    } else if (weekday === "wednesday") {
        return "Qua";
    } else if (weekday === "thursday") {
        return "Qui";
    } else if (weekday === "friday") {
        return "Sex";
    } else if (weekday === "saturday") {
        return "Sáb";
    } else if (weekday === "sunday") {
        return "Dom";
    }
};

exports.maskPhone = (v, addCountryCode = false) => {
    // console.log("maskPhone", v);
    let r = v.replace(/\D/g, "");

    const withCountryCode = r.length !== 11;
    // console.log("withCountryCode", withCountryCode, r);
    let countryCode;

    if(addCountryCode && withCountryCode){
        countryCode = r.substring(0, 2);
        r = r.substring(2,r.length);
    }

    r = r.replace(/^0/, "");
    if (r.length > 10) {
        r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (r.length > 5) {
        r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    } else if (r.length > 2) {
        r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else {
        r = r.replace(/^(\d*)/, "($1");
    }

    if(r === "("){
        r = ""
    }else if(r.substr(r.length-1) === "-"){
        r = r.substring(0, r.length-1)
    }

    if(withCountryCode && addCountryCode){
        r = "+"+countryCode+""+r;
    }

    return r;
};

exports.unmaskPhone = (phone) => {
    let value = phone;

    value = value ? value.replace(/\D/g, '') : value;

    return value;
};

exports.formatDuration = (duration) => {
    console.log("duration minutes", duration);

    let sec_num = parseInt(duration, 10); // don't forget the second param
    let hours   = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = sec_num - (hours * 3600) - (minutes * 60);

    // if (hours   < 10) {hours   = "0"+hours;}
    // if (minutes < 10) {minutes = "0"+minutes;}
    // if (seconds < 10) {seconds = "0"+seconds;}

    console.log("minutes", minutes, minutes > 0);
    console.log("seconds", seconds);
    if(hours > 0){
        return hours+' horas'+(minutes > 0 ? ', '+minutes+' minutos': '')+(seconds > 0 ? ', '+seconds+' segundos': '');
    }else if(minutes > 0){
        return minutes+' minutos '+(seconds > 0 ? ', '+seconds+' segundos': '');
    }else {
        return seconds+' segundos';
    }
};
