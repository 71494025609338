import React, {Component} from 'react';
import { Link } from "react-router-dom";
import Icon from '@mdi/react'
import Layout from "../components/Layout";
import Badge from "../components/Badge";
import scheduleResource from "../resources/schedule";
import professionalResource from "../resources/professional";
import {connect} from "react-redux";
import Loader from "../components/Loader";
import CreateOrEditProfessionalModal from "../components/createOrEditProfessionalModal";
import scheduleUtils from '../utils/schedule'
import Moment from 'moment';
import { registerLocale } from "react-datepicker";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import ptBr from 'date-fns/locale/pt-BR';
import {
    mdiAlarm,
    mdiPound,
    mdiCalendarRange,
    mdiCalendar,
    mdiGenderMaleFemale,
    mdiCakeVariant,
    mdiAccount,
    mdiMessageVideo,
    mdiClipboardAccount,
    mdiBottleTonicPlus,
    mdiCheck,
    mdiCheckBoxOutline,
    mdiClipboard,
    mdiContentCopy, mdiCheckboxMarked, mdiSquareEditOutline, mdiMonitorDashboard
} from '@mdi/js';
import Input from "../components/Input";
import {btn} from "../components/DocumentWidget/DocumentWidget.module.scss";
import Button from "../components/Button";
import dataUtils from "../utils/data";
import {withRouter} from "react-router";
import CreateDocumentModal from "../components/CreateDocumentModal";
import ProfessionalRed from "../images/professional-red.svg";
import DashboardRed from "../images/dashboard_red.png";
import Toggle from "react-toggle";
import GraphsDashboard from "../components/GraphsDashboard";
import DashboardTitleGeneralDetails from "../components/DashboardTitleGeneralDetails";
import {apiDashboardGraphsConsolided} from "../utils/apiDashboard";
import { json } from 'body-parser';
import "../styles/dashboard.css";
import emptyDataDashboard from "../utils/emptyDataDashboard";
import utilsDashboard from "../utils/formatterDashboard";

registerLocale('pt-BR', ptBr);

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf())
    date.setDate(date.getDate() + days);
    return date;
}

const mapStateToProps = store => ({
    isLogged: store.authReducer.isLogged,
    isLoaded: store.authReducer.isLoaded,
    isLoading: store.authReducer.isLoading,
    loadError: store.authReducer.loadError,
    user: store.authReducer.user,
    token: store.authReducer.token,
    professional: store.authReducer.professional,
    callStatus: store.videoCallReducer.status,
    callToken: store.videoCallReducer.token,
    roomName: store.videoCallReducer.roomName,
    roomId: store.videoCallReducer.roomId,
});

class Dashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            graphsPsychologist: [{
                "date": "",
                "starter": 0,
                "starterColor": "",
                "flex": 0,
                "flexColor": "",
                "premium": 0,
                "premiumColor": "",
                "livre": 0,
                "livreColor": "",
            }],
            graphsNutritionist: [{
                "date": "",
                "starter": 0,
                "starterColor": "",
                "flex": 0,
                "flexColor": "",
                "premium": 0,
                "premiumColor": "",
                "livre": 0,
                "livreColor": "",
            }],
            graphsDoctor: [{
                "date": "",
                "starter": 0,
                "starterColor": "",
                "flex": 0,
                "flexColor": "",
                "premium": 0,
                "premiumColor": "",
                "livre": 0,
                "livreColor": "",
            }],
            unavailableInfoPsychologist: {
                weekOne: 0,
                weekTwo: 0,
                weekThree: 0,
                weekFour: 0, 
            },
            unavailableInfoNutritionist: {
                weekOne: 0,
                weekTwo: 0,
                weekThree: 0,
                weekFour: 0, 
            },
            unavailableInfoDoctor: {
                weekOne: 0,
                weekTwo: 0,
                weekThree: 0,
                weekFour: 0, 
            },
            nutritionistAmount: 0,
            psychologistAmount: 0,
            doctorAmount: 0,
            starterClients: 0,
            flexClients: 0,
            premiumClients: 0,
        };
    }

    async componentDidMount() {
        this.fetchData();

        let responseApiDashboardPsychologist = await apiDashboardGraphsConsolided.get("/psychologist");
        let responseApiDashboardNutritionist = await apiDashboardGraphsConsolided.get("/nutritionist");
        let responseApiDashboardDoctor = await apiDashboardGraphsConsolided.get("/doctor");

        this.setState({
            loading: false,
        });

        let indexAvailableProfessionalPsychologistAmount = utilsDashboard.indexAnalyzer(responseApiDashboardPsychologist.data, "professional");
        let indexAvailableProfessionalNutritionistAmount = utilsDashboard.indexAnalyzer(responseApiDashboardNutritionist.data, "professional");
        let indexAvailableProfessionalDoctorAmount = utilsDashboard.indexAnalyzer(responseApiDashboardDoctor.data, "professional");

        let indexAvailableClientsAmount = utilsDashboard.indexAnalyzer(responseApiDashboardPsychologist.data, "subscription");

        let professionalsAmount = {
            psychologist: responseApiDashboardPsychologist.data[indexAvailableProfessionalPsychologistAmount]["professional"]["count"],
            nutritionist: responseApiDashboardNutritionist.data[indexAvailableProfessionalNutritionistAmount]["professional"]["count"],
            doctor: responseApiDashboardDoctor.data[indexAvailableProfessionalDoctorAmount]["professional"]["count"],
        };

        let clientsAmount = responseApiDashboardPsychologist.data[indexAvailableClientsAmount]["subscription"];

        this.setState({
            nutritionistAmount: professionalsAmount['nutritionist'],
            doctorAmount: professionalsAmount['doctor'],
            psychologistAmount: professionalsAmount['psychologist'],
            starterClients: clientsAmount['plan']['start'],
            flexClients: clientsAmount['plan']['flex'],
            premiumClients: clientsAmount['plan']['premium'],
        });

        let allDataDashboard = {
            psychologist: responseApiDashboardPsychologist.data,
            nutritionist: responseApiDashboardNutritionist.data,
            doctor: responseApiDashboardDoctor.data,
        }

        for(let i = 0; i < Object.keys(allDataDashboard).length; i++){
            switch(Object.keys(allDataDashboard)[i]){
                case 'psychologist':
                    this.dataDashboardFormatter(allDataDashboard.psychologist, 'psychologist');     
                    break;
                    
                case 'nutritionist':
                    this.dataDashboardFormatter(allDataDashboard.nutritionist, 'nutritionist');     
                    break;

                case 'doctor':
                    this.dataDashboardFormatter(allDataDashboard.doctor, 'doctor');     
                    break;
            }
        }
    }

    fetchData = async () => {
        // const alias = scheduleUtils.getAlias(this.props.professional.type);

        try {
            this.setState({
                loading: true,
            });

            // const response = await professionalResource.fetch(this.props.token, this.state.selectedTypeFilter.join(","), this.state.selectedStatusFilter);
            // console.log(response);
            // if (response.status !== 200) {
            //     throw new Error(response.data.message);
            // }

            
        } catch(err){
            this.setState({
                loading: false,
            });

            alert(err.message);
        }
    };

    getDates(startDate, stopDate) {
        var dateArray = new Array();
        var currentDate = startDate;
        while (currentDate <= stopDate) {
          dateArray.push(currentDate)
          currentDate = currentDate.addDays(1);
        }
        return dateArray;
    }

    dataDashboardFormatter = (dataApiDashboard, category) => {
        dataApiDashboard = utilsDashboard.formatterDataGraphsJson(dataApiDashboard);

        const modeViewPlan = [];
        const modeViewTrial = [];
        const unavailableValues = [];
        let allfreeTimeAmount = 0;
        let freeTimeAvailable = 0;
        let dateRange = (this.getDates(new Date('2020-07-02'), (new Date()).addDays(28))).map(data => Moment(+data).format('YYYY-MM-DD'));

        for(let i = 0; i < dataApiDashboard.length; i++){

            if(dateRange[i] !== dataApiDashboard[i]["date"]){
                dataApiDashboard.splice(i, 0, emptyDataDashboard(dateRange[i]));
            }

            let formattedDate = utilsDashboard.formatterDate(Moment(dataApiDashboard[i]["date"]).format('llll'), dataApiDashboard[i]["date"]);

            let currentDataPlan = {
                date: formattedDate,
                starter: (dataApiDashboard[i]["plan"]["start"] || 0),
                starterColor: "hsl(235, 70%, 50%)",
                flex: (dataApiDashboard[i]["plan"]["flex"] || 0),
                flexColor: "hsl(235, 70%, 50%)",
                premium: (dataApiDashboard[i]["plan"]["premium"]|| 0),
                premiumColor: "hsl(235, 70%, 50%)",
                livre: dataApiDashboard[i]["availability"] - (dataApiDashboard[i]["plan"]["start"] || 0) - (dataApiDashboard[i]["plan"]["flex"] || 0) - (dataApiDashboard[i]["plan"]["premium"] || 0),
                livreColor: "hsl(235, 70%, 50%)",
            }

            let currentDataTrial = {
                date: formattedDate,
                yes: (dataApiDashboard[i]["trial"]["yes"] || 0),
                yesColor: "hsl(235, 70%, 50%)",
                no: (dataApiDashboard[i]["trial"]["no"] || 0),
                noColor: "rgb(0, 255, 0)",
                livre: dataApiDashboard[i]["availability"] - (dataApiDashboard[i]["trial"]["yes"] || 0) - (dataApiDashboard[i]["trial"]["no"] || 0),
                livreColor: "hsl(0, 0%, 66%)",
            }

            allfreeTimeAmount += dataApiDashboard[i]["availability"];
            freeTimeAvailable += currentDataPlan.livre;

            if((i + 1) % 7 === 0){
                let unavailableTimePercentage = 100 - ((freeTimeAvailable * 100) / allfreeTimeAmount);
                unavailableValues.push(unavailableTimePercentage.toFixed(1));
            }

            modeViewPlan.push(currentDataPlan);
            modeViewTrial.push(currentDataTrial);
        }

        let valueUnavailableTime = {
            weekOne: unavailableValues[0],
            weekTwo: unavailableValues[1],
            weekThree: unavailableValues[2],
            weekFour: unavailableValues[3], 
        };

        localStorage.removeItem(`dataPlan-${category}`);
        localStorage.removeItem(`dataTrial-${category}`);
                
        localStorage.setItem(`dataPlan-${category}`, JSON.stringify(modeViewPlan));
        localStorage.setItem(`dataTrial-${category}`, JSON.stringify(modeViewTrial));

        switch(category){
            case 'psychologist':
                this.setState({
                    graphsPsychologist: modeViewPlan,
                    unavailableInfoPsychologist: valueUnavailableTime
                })
                
                break;
                
            case 'nutritionist':
                this.setState({
                    graphsNutritionist: modeViewPlan,
                    unavailableInfoNutritionist: valueUnavailableTime
                })   
                break;

            case 'doctor':
                this.setState({
                    graphsDoctor: modeViewPlan,
                    unavailableInfoDoctor: valueUnavailableTime
                })    
                break;
        }
    }

    render() {
        const {loading,
                graphsDoctor, 
                graphsNutritionist, 
                graphsPsychologist, 
                doctorAmount, 
                nutritionistAmount, 
                psychologistAmount,
                starterClients,
                flexClients,
                premiumClients,
                unavailableInfoDoctor,
                unavailableInfoNutritionist,
                unavailableInfoPsychologist,
            } = this.state;

        const viewChangeOption = event => {
            const categorySelected = event.target.parentNode.parentNode.parentNode.dataset.category;
            const filterSelected = event.target.dataset.filter;
            const allSiblingsButtons = event.target.parentElement.childNodes;

            for(let i = 0; i < allSiblingsButtons.length; i++){
                allSiblingsButtons[i].classList.remove("isActive");
            }
            (event.target).classList.add("isActive");
            
            switch(categorySelected){
                case 'psychologist':
                    if(filterSelected === "plan"){
                        let getPlanInfo = JSON.parse(localStorage.getItem('dataPlan-psychologist'));

                        this.setState({
                            graphsPsychologist: getPlanInfo
                        });
                    }
                    else{
                        let getTrialInfo = JSON.parse(localStorage.getItem('dataTrial-psychologist'));

                        this.setState({
                            graphsPsychologist: getTrialInfo
                        });
                    }
                    break;

                case 'nutritionist':
                    if(filterSelected === "plan"){
                        let getPlanInfo = JSON.parse(localStorage.getItem('dataPlan-nutritionist'));

                        this.setState({
                            graphsNutritionist: getPlanInfo
                        });
                    }
                    else{
                        let getTrialInfo = JSON.parse(localStorage.getItem('dataTrial-nutritionist'));

                        this.setState({
                            graphsNutritionist: getTrialInfo
                        });
                    }
                    break;

                case 'doctor':
                    if(filterSelected === "plan"){
                        let getPlanInfo = JSON.parse(localStorage.getItem('dataPlan-doctor'));

                        this.setState({
                            graphsDoctor: getPlanInfo
                        });
                    }
                    else{
                        let getTrialInfo = JSON.parse(localStorage.getItem('dataTrial-doctor'));

                        this.setState({
                            graphsDoctor: getTrialInfo
                        });
                    }
                    break;
            }       
        }

        let weekDaySlots = {
            [Moment().format('DD/MM')]: [
                {
                    patient: {
                        name: "Jussara Skywalker"
                    },
                    start: new Date(),
                    end: new Date().getTime() + (30 * 60 * 1000)
                },
                {
                    patient: {
                        name: "Will Smith"
                    },
                    start: new Date().getTime() + (120 * 60 * 1000),
                    end: new Date().getTime() + (150 * 60 * 1000)
                }
            ],
            [Moment().add(1, 'day').format('DD/MM')]: [
                {
                    patient: {
                        name: "Will Smith"
                    },
                    start: new Date().getTime() + (120 * 60 * 1000),
                    end: new Date().getTime() + (150 * 60 * 1000)
                }
            ]
        };

        // if(!this.state.loading){
        //     for(let item of this.state.professional.availability){
        //         console.log("availability item", item);
        //         if(!weekDaySlots[item.weekday]){
        //             weekDaySlots[item.weekday] = [item];
        //         }else{
        //             weekDaySlots[item.weekday].push(item);
        //         }
        //     }
        // }
        // // console.log("weekDaySlots", weekDaySlots);

        return (
            <Layout>
                <div>
                    <div>
                        {this.state.loading && <div style={{marginTop: 40}}>
                            <Loader/>
                        </div>}
                        {!this.state.loading && <div>
                            <div style={{display: 'flex'}}>
                                <div style={{flex: 1}}>
                                    <h1 style={{fontWeight: 'bold', textAlign: 'left', marginBottom: 30, color: '#9C0D4F', fontSize: 22}}>
                                        {/*<img alt={"Professional"} src={ProfessionalRed} style={{width: 26, marginBottom: -3, marginRight: 8}} />*/}
                                        {/*<Icon*/}
                                        {/*    path={mdiMonitorDashboard}*/}
                                        {/*    title="Dashboard"*/}
                                        {/*    size={1.2}*/}
                                        {/*    color="#9C0D4F"*/}
                                        {/*    style={{*/}
                                        {/*        marginBottom: -7,*/}
                                        {/*        marginRight: 8*/}
                                        {/*    }}*/}
                                        {/*/>*/}
                                        <img src={DashboardRed} alt={"Dashboard"} style={{width: 24, height: 'auto', marginBottom: -5, marginRight: 8}}/>
                                        Dashboard
                                    </h1>
                                </div>
                            </div>

                            <div className={"containerData"} data-category="psychologist">
                                <DashboardTitleGeneralDetails title={"Psicólogo"} amountProfessional={psychologistAmount} staterAmount={starterClients} flexAmount={flexClients} premiumAmount={premiumClients} unavailableInfo={unavailableInfoPsychologist}/>
                                <div style={{display: 'flex',flexDirection: 'row',justifyContent: 'space-between',margin: '0 13% 0 0', position: 'relative', top:'25px', zIndex:1}}>
                                    <div className={"filterTitle"}>Ocupacão da agenda</div>
                                    <div>
                                        <button className={'buttonRight isActive'} onClick={viewChangeOption} data-filter="plan">Por plano</button>
                                        <button className={'buttonLeft'} onClick={viewChangeOption} data-filter="trial">Trial</button>
                                    </div>
                                </div>
                                <GraphsDashboard  data={graphsPsychologist}/>
                            </div>
                            <div className={"containerData"} data-category="nutritionist">
                                <DashboardTitleGeneralDetails title={"Nutricionista"} amountProfessional={nutritionistAmount} staterAmount={starterClients} flexAmount={flexClients} premiumAmount={premiumClients} unavailableInfo={unavailableInfoNutritionist}/>
                                <div style={{display: 'flex',flexDirection: 'row',justifyContent: 'space-between',margin: '0 13% 0 0', position: 'relative', top:'25px', zIndex:1}}>
                                    <div className={"filterTitle"}>Ocupacão da agenda</div>
                                    <div>
                                        <button  className={'buttonRight isActive'} onClick={viewChangeOption} data-filter="plan">Por plano</button>
                                        <button  className={'buttonLeft'} onClick={viewChangeOption} data-filter="trial">Trial</button>
                                    </div>
                                </div>
                                <GraphsDashboard  data={graphsNutritionist}/>
                            </div>
                            <div className={"containerData"} data-category="doctor">
                                <DashboardTitleGeneralDetails title={"Médico"} amountProfessional={doctorAmount} staterAmount={starterClients} flexAmount={flexClients} premiumAmount={premiumClients} unavailableInfo={unavailableInfoDoctor}/>
                                <div style={{display: 'flex',flexDirection: 'row',justifyContent: 'space-between',margin: '0 13% 0 0', position: 'relative', top:'25px', zIndex:1}}>
                                    <div className={"filterTitle"}>Ocupacão da agenda</div>
                                    <div>
                                        <button className={'buttonRight isActive'} onClick={viewChangeOption} data-filter="plan">Por plano</button>
                                        <button className={'buttonLeft'} onClick={viewChangeOption} data-filter="trial">Trial</button>
                                    </div>
                                </div>
                                <GraphsDashboard  data={graphsDoctor}/>
                            </div>
                        </div>}
                    </div>
                </div>
            </Layout>
        );
    }
}

export default connect(mapStateToProps)(withRouter(Dashboard));
