import requestUtils from '../utils/request';

export default {
    fetch(patientId, token) {
        return requestUtils({
            url: `/v1/patient/${patientId}/document`,
            method: 'GET',
            token,
        });
    },

    async uploadFile(patientId, name, file, expiresAt, category, token) {
        const data = new FormData();
        data.append('name', name);
        data.append('file', file);
        if (expiresAt) data.append('expires_at', expiresAt)
        data.append('owner_id', patientId);
        if (category) data.append('category', category);

        console.log(data);

        return await requestUtils({
            url: `/v1/patient/${patientId}/document`,
            method: 'POST',
            data,
            token
        });
    }

};
