import React, { Component } from 'react';
import { mdiCakeVariant }  from '@mdi/js';
import Icon from '@mdi/react';
import Moment from 'moment';
import 'moment-precise-range-plugin';

import { 
    bdWrapper, 
    bdIcon, 
    bdBirthdateField 
} from "./BirthdateField.module.scss";

export default class BirthdateField extends Component {
    displayAge() {
        const birthdate = Moment(this.props.birthdate);
        const now = Moment();

        const diff = Moment.preciseDiff(birthdate, now);

        return `${diff.split(' ')[0]} anos e ${diff.split(' ')[2]} meses`;
    }

    displayBirthDate() {
        return Moment(this.props.birthdate).format('DD/MM/YYYY');
    }

    render() {
        return (
            <div className={`${bdWrapper} ${this.props.className || ''}`} style={{...this.props.style}}>
                <div className={bdIcon}>
                    <Icon
                        path={mdiCakeVariant}
                        title="Idade"
                        size={0.8}
                        color={this.props.iconColor || '#666'}
                    />
                </div>
                <div className={bdBirthdateField}>
                    <p>{ this.displayAge() }</p>
                    <aside>
                        Nascido em {this.displayBirthDate()}
                    </aside>
                </div> 
            </div>
        );
    }
}
