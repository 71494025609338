import React from 'react';
import {
    dashboardFragment,
    dashboardProfessionalContainer,
    dashboardListItem,
    dashboardListItemTitle,
    dashboardListItemAmount,
    dashboardAmountContainer,
    dashboardAmountContainerItem,
    dashboardListItemAmountLegend,
} from './DashboardTitleGeneralDetails.module.scss';

export default props => {
    const {
        title,
        amountProfessional,
        staterAmount,
        flexAmount,
        premiumAmount,
        unavailableInfo
    } = props;

    let formatterUnavailableInfo = Object.values(unavailableInfo);

    return(
        <React.Fragment>
            <h1 className={dashboardFragment}>{title}</h1>
            <div>
                <ul className={dashboardProfessionalContainer}>
                    <li className={dashboardListItem}>
                        <strong className={dashboardListItemTitle}>Profissionais</strong>
                        <div className={dashboardAmountContainer}>
                            <div className={dashboardAmountContainerItem}>
                                <p className={dashboardListItemAmount}>{amountProfessional}</p>
                            </div>
                        </div>
                    </li>
                    <li className={dashboardListItem}>
                        <strong className={dashboardListItemTitle}>Assinantes</strong>
                        <div className={dashboardAmountContainer}>
                            <div className={dashboardAmountContainerItem}>
                                <p className={dashboardListItemAmount}>{staterAmount}</p>
                                <p className={dashboardListItemAmountLegend}>Starter</p>
                            </div>
                            <div className={dashboardAmountContainerItem}>
                                <p className={dashboardListItemAmount}>{flexAmount}</p>
                                <p className={dashboardListItemAmountLegend}>Flex</p>
                            </div>  
                            <div className={dashboardAmountContainerItem}>
                                <p className={dashboardListItemAmount}>{premiumAmount}</p>
                                <p className={dashboardListItemAmountLegend}>Premium</p>
                            </div>
                        </div>
                    </li>
                    <li className={dashboardListItem}>
                        <strong className={dashboardListItemTitle}>Ocupação da agenda</strong>
                        <div className={dashboardAmountContainer}>
                            <div className={dashboardAmountContainerItem}>
                                <p className={dashboardListItemAmount}>{formatterUnavailableInfo[0]}%</p>
                                <p className={dashboardListItemAmountLegend}>7 dias</p>
                            </div>
                            <div className={dashboardAmountContainerItem}>
                                <p className={dashboardListItemAmount}>{formatterUnavailableInfo[1]}%</p>
                                <p className={dashboardListItemAmountLegend}>14 dias</p>
                            </div>  
                            <div className={dashboardAmountContainerItem}>
                                <p className={dashboardListItemAmount}>{formatterUnavailableInfo[2]}%</p>
                                <p className={dashboardListItemAmountLegend}>21 dias</p>
                            </div>
                            <div className={dashboardAmountContainerItem}>
                                <p className={dashboardListItemAmount}>{formatterUnavailableInfo[3]}%</p>
                                <p className={dashboardListItemAmountLegend}>28 dias</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </React.Fragment>
    )
}