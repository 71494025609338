import requestUtils from '../utils/request';

export default {
    register(body) {
        return requestUtils({
            url: '/v1/register',
            method: 'POST',
            data: body
        });
    },
    login(body) {
        return requestUtils({
            url: '/v1/login',
            method: 'POST',
            data: body
        });
    },
    reset(body) {
        return requestUtils({
            url: '/v1/reset',
            method: 'POST',
            data: body
        });
    },
    resetConfirm(body) {
        return requestUtils({
            url: '/v1/reset/confirm',
            method: 'POST',
            data: body
        });
    },
    load(token) {
        return requestUtils({
            url: '/v1/load',
            method: 'GET',
            token,
        });
    },
    updateProfile(body, token) {
        return requestUtils({
            url: '/v1/profile',
            method: 'PUT',
            data: body,
            token
        });
    },
    updateProfileImage(imageUri, token) {
        let body = new FormData();
        // body.append('image', imageUri);

        const extension = imageUri.substring(imageUri.lastIndexOf('.'));

        body.append('image', {
            uri: imageUri,
            type: `image/${extension.replace('.', '')}`,
            name: `profile_image_${new Date().getTime()}.${extension}`,
        });

        return requestUtils({
            url: '/v1/profile/image',
            method: 'PUT',
            data: body,
            token,
            header: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
};
