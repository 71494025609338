import React, {Component} from 'react';
import PhoneRoom from '../components/PhoneRoom';

import 'react-calendar/dist/Calendar.css';
import { withRouter } from "react-router";

import {connect} from 'react-redux';
import {actionCreators as actionCreatorsCall} from "../reducers/phone-call";


const mapStateToProps = store => ({
    callStatus: store.phoneCallReducer.status,
    callToken: store.phoneCallReducer.token,
    patientName: store.phoneCallReducer.name,
    patientInfo: store.phoneCallReducer.patientInfo,
    callInfo: store.phoneCallReducer.callInfo,
});

class PhoneCall extends Component {

    endCall = () => {
        this.props.dispatch(actionCreatorsCall.callEnd());
    };

    render() {
        return(
            <div style={{backgroundColor: '#000', height: '100vh'}}>
                <PhoneRoom
                  endCall={this.endCall}
                  patientInfo={this.props.patientInfo}
                  callInfo={this.props.callInfo}
                />
            </div>
        )
    }
}

export default connect(mapStateToProps)(withRouter(PhoneCall));

