import { combineReducers } from 'redux';

import auth from './auth';
import videoCall from './video-call';
import chat from './chat';
import phoneCall from './phone-call';
import unavailabilityListReducer from './unavailability';

export default combineReducers({
    authReducer: auth,
    videoCallReducer: videoCall,
    chatReducer: chat,
    phoneCallReducer: phoneCall,
    unavailabilityListReducer
});
