import React, { Component } from 'react';
import { mdiClose, mdiFile } from '@mdi/js';
import { Icon } from '@mdi/react';
import Modal from 'react-modal';
import Dropzone from 'react-dropzone';
import Moment from 'moment';

import Input from '../Input';
import {ReactComponent as UploadIcon} from './upload.svg';
import Button from '../Button';
import Select from 'react-select';

import ehr from '../../resources/ehr';

// Modal.defaultStyles.content = {
//     ...Modal.defaultStyles.content,
//     right: 'calc(50% - 270px)',
//     left: 'calc(50% - 270px)',
//     bottom: 'unset',
//     maxWidth: 540,
//     width: 540,
//     padding: 24,
//     borderRadius: 8,
//     background: '#F7F7F7',
// };

Modal.defaultStyles.content = {
    ...Modal.defaultStyles.content,
    // right: 'calc(50% - 405px)',
    // left: 'calc(50% - 405px)',
    // top: 'calc(50% - 405px)',
    // bottom: 'unset',
    // top: 0,
    // bottom: 0,
    // left: 0,
    // right: 0,
    // maxWidth: 810,
    // width: 810,
    // minWidth: 810,
    // padding: 24,
    // borderRadius: 8,
    // background: '#fff',
    // borderColor: '#e7e7e7',
    // height: 'auto',
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
};

class CreateDocumentModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            requesting: false,
            file: null,
            name: '',
            expiresAt: '',
            category: this.props.categories && this.props.categories.length === 1 ? this.props.categories[0] : null,
        };
    }

    onNameChange = (e) => {
        e.preventDefault();

        this.setState({
            name: e.target.value,
        });
    }

    onSubmit = async (e) => {
        e.preventDefault();
        this.setState({
            requesting: true,
        });

        try {
            await ehr.uploadFile(
                this.props.patientId,
                this.state.name,
                this.state.file,
                Moment(this.state.expiresAt, 'DD/MM/YYYY').isValid()
                    ? Moment(this.state.expiresAt, 'DD/MM/YYYY').toISOString()
                    : null,
                this.state.category.value,
                this.props.token
            );
        } catch (err) {
            console.error(err);
            this.setState({
                requesting: false
            })

            alert(err.message);
        }

        this.setState({
            file: null,
            name: '',
            expiresAt: '',
            categories: [],
            requesting: false
        });
        this.close();
    }

    onExpireChange = (e) => {
        e.preventDefault();

        const numbers = e.target.value.replace(/\D/g, '');
        const parts = [numbers.substr(0, 2)];
        if (numbers.length > 2) {
            parts.push(numbers.substr(2, 2));
        }
        if (numbers.length > 4) {
            parts.push(numbers.substr(4, 4));
        }

        const date = parts.join('/');

        this.setState({
            expiresAt: date,
        });
    }

    close = (e) => {
        if (e) e.preventDefault();

        if (this.props.onCloseRequest)
            this.props.onCloseRequest();
    }

    onDrop = (acceptedFiles) => {
        if (! acceptedFiles.length) {
            return;
        }

        const file = acceptedFiles[0];
        this.setState({
            file: file
        });
    }

    handleChangeCategory = (category) => {
        this.setState({ category });
    }

    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                onAfterOpen={this.props.onAfterClose}
                onRequestClose={this.close}
                contentLabel={'Emitir documento'}
                style={{
                    // content: {
                    //     ...Modal.defaultStyles.content,
                    //     right: 'calc(50% - 270px)',
                    //     left: 'calc(50% - 270px)',
                    //     bottom: 'unset',
                    //     maxWidth: 540,
                    //     width: 540,
                    //     padding: 24,
                    //     borderRadius: 8,
                    //     background: '#F7F7F7',
                    // }
                }}
            >
                <form name={'createDocumentForm'} onSubmit={this.onSubmit}>
                    <span style={{position: 'absolute', right: 20, top: 20, cursor: 'pointer'}} onClick={this.close}>
                        <Icon path={mdiClose} title="Agenda" size={1} color={'#6F6F6F'}/>
                    </span>
                    <h2 style={{fontWeight: 'bold', textAlign: 'left'}}>
                        Emitir Documento
                        <aside style={{color: '#6F6F6F', fontSize: 14}}>Envie documentos como prescrições, atestados e pedidos de exames para o app do seu paciente.</aside>
                    </h2>

                    <Input onChange={this.onNameChange} value={this.state.name} required={true} label={'TÍTULO'} type={'text'} placeholder={'Título descritivo para entendimento do paciente'}/>

                    {
                        this.props.categories && this.props.categories.length > 0
                            ? (<Select
                                placeholder="Tipo do documento"
                                className="basic-single"
                                classNamePrefix="select"
                                isDisabled={this.props.categories.length === 1}
                                isSearchable={true}
                                name="color"
                                options={this.props.categories}
                                onChange={this.handleChangeCategory}
                            />) : ''
                    }

                    <div>
                        <Dropzone multiple={false} onDrop={this.onDrop} style={{'border': '1px solid #e7e7e7', padding: 12, display: 'flex', borderRadius: 8}}>
                        {({getRootProps, getInputProps}) => (
                            <section>
                                <label style={{color: 'rgb(102, 102, 102)', fontSize: 12, fontWeight: 'bold'}}>ANEXO</label>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <div style={{width: '100%', background: 'white', borderRadius: '8px', border: '1px solid #e7e7e7', padding: 12}}>
                                        <div style={{display: 'flex', marginBottom: 12}}>
                                            {!this.state.file
                                                ? <UploadIcon style={{margin: '0 auto'}}/>
                                                : <Icon style={{margin: '0 auto'}} path={mdiFile} title="Arquivo" size={2} color={'#6F6F6F'}/>
                                            }
                                        </div>
                                        <p style={{textAlign: 'center', color: '#AFAFAF', fontSize: 14}}>
                                        {!this.state.file ? 'Arraste aqui seu arquivo para anexar' : this.state.file.name }</p>
                                    </div>
                                </div>
                            </section>
                        )}
                        </Dropzone>
                    </div>
                    <div style={{width: '100%', display: 'flex', alignItems: 'flex-end'}}>
                        <Input onChange={this.onExpireChange} value={this.state.expiresAt} style={{width: '100%'}} label={'VALIDADE (opcional)'} placeholder={'dd/mm/AAAA'}/>
                        <div style={{width: '50%'}}>
                            <Button loading={this.state.requesting} type={'submit'} text={'EMITIR DOCUMENTO'} style={{fontSize: 14, height: 44, padding: '12px 24px', float: 'right'}} onSubmit={this.onSubmit}/>
                        </div>
                    </div>
                </form>
            </Modal>
        );
    }
}

export default CreateDocumentModal;
