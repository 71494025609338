import React, { Component } from 'react';
import {input, boldPlaceholder} from "./Input.module.scss";
import SpinnerWhite from "../../images/spinner_white.svg";
import Spinner from "../../images/spinner.svg";

// import classNames from 'classnames';

export default class Input extends Component {
    render(){
        return (
            <div style={{position: 'relative', ...this.props.styleContainer}}>
                {this.props.label ? <label style={{color: 'rgb(102, 102, 102)', fontSize: 12, fontWeight: 'bold', ...this.props.styleLabel}} htmlFor={this.props.name}>{this.props.label}</label> : ''}
                {this.props.image && <img alt={"leftImage"} src={this.props.image} style={{
                    width: 30,
                    height: 30,
                    position: 'absolute',
                    left: 5,
                    top: 7,
                    ...this.props.styleImage
                }}/>}
                <input
                    id={this.props.id}
                    name={this.props.name}
                    type={this.props.type}
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    className={`${input} ${this.props.boldPlaceholder ? boldPlaceholder : ''}`}
                    style={this.props.style}
                    onChange={this.props.onChange}
                    onKeyDown={this.props.onKeyDown}
                    readOnly={this.props.readOnly || false}
                    disabled={this.props.disabled || false}
                    required={this.props.required}
                />
            </div>
        );
    }
}
