import React, {Component} from 'react';
import Loader from '../components/Loader'

import 'react-calendar/dist/Calendar.css';
import { withRouter } from "react-router";

import {connect} from 'react-redux';
import {socketAPI} from "../configs/socket";

import { actionCreators as actionCreatorsAuth } from '../reducers/auth';

const mapStateToProps = store => ({
    isLogged: store.authReducer.isLogged,
    isLoaded: store.authReducer.isLoaded,
    isLoading: store.authReducer.isLoading,
    loadError: store.authReducer.loadError,
    user: store.authReducer.user,
    videoCallStatus: store.videoCallReducer.status,
    phoneCallStatus: store.phoneCallReducer.status,
});

class Router extends Component {

    async componentDidMount() {
        this.getToken();
    }

    getToken = () => {
        try {
            const token = localStorage.getItem('token');

            const hasToken = token !== 'null' && token !== null;

            this.props.dispatch(actionCreatorsAuth.setIsLogged(hasToken ? token : null));

            if(!hasToken && !this.props.location.pathname.startsWith('/confirmar')) {
                this.props.history.push('/login');
            }
        } catch(err) {
            alert(err.message);
        }
    };

    componentDidUpdate(prevProps, prevState) {
        //login token status update
        // if(prevProps.isLogged !== this.props.isLogged){
        //     console.log("islogged udpate", prevProps.isLogged, this.props.isLogged);
            // this.getToken();
        // }

        //load data status update
        if(!prevProps.isLogged && this.props.isLogged === true){
            this.props.dispatch(actionCreatorsAuth.load());
        }

        //after load status update
        if(!prevProps.isLoaded && this.props.isLoaded && this.props.isLogged){
            // this.props.history.push('/');

            socketAPI.dispatch = this.props.dispatch;   //injecting redux on socket
            socketAPI.connect(this.props.user._id);
        }

        //call status update
        if(
            (prevProps.videoCallStatus === 'idle' && this.props.videoCallStatus === 'calling')
        ){ //on user accept
            this.props.history.push('/video');
        }
         
        if(
          ['accepted', 'calling', 'ended', 'rejected'].includes(prevProps.videoCallStatus)
          && this.props.videoCallStatus === 'idle'
          && window.location.pathname.startsWith('/video')
        ){  //on call end
            this.props.history.goBack();
        }

        if(prevProps.videoCallStatus !== this.props.videoCallStatus){
            console.log("callStatus CHANGED", prevProps.videoCallStatus, this.props.videoCallStatus)
        }


        if(
            (prevProps.phoneCallStatus === 'idle' && this.props.phoneCallStatus === 'calling')
        ){ //on user accept
            this.props.history.push('/phone');
        }
         
        if(['accepted', 'calling'].includes(prevProps.phoneCallStatus) && this.props.phoneCallStatus === 'idle' && window.location.pathname.startsWith('/phone')){  //on call end
            this.props.history.goBack();
        }

        if(prevProps.phoneCallStatus !== this.props.phoneCallStatus){
            console.log("callStatus CHANGED", prevProps.phoneCallStatus, this.props.phoneCallStatus)
        }
        
    }

    render(){
        if(this.props.isLogged === null || this.props.isLoading || (this.props.isLogged && !this.props.isLoaded)){
            return(
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <Loader/>
                </div>
            );
        }

        return this.props.children;
    }
}

export default connect(mapStateToProps)(withRouter(Router));

