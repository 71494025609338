import React, {Component} from 'react';
import {connect} from "react-redux";

import Layout from './Layout';
import Input from './Input';
import TextArea from './TextArea';
import Button from './Button';

import { actionCreators as actionCreatorsAuth } from '../reducers/auth';

const mapStateToProps = store => ({
    user: store.authReducer.user,
    professional: store.authReducer.professional,
});

class Profile extends Component {
    constructor(props){
        super(props);

        this.state = {};
    }

    componentDidMount() {
        this.setState({
            name: this.props.user.name,
            email: this.props.user.email,
            title: this.props.professional?.title,
            description: this.props.professional?.description,
            registry: this.props.professional?.registry,
            registryType: this.props.professional?.registry_type,
        });
    }

    fetchInfo(type) {
        return {
            professional: async () => {

            },
            admin: async() => { },
            user: async() => { }
        }[type]();
    }

    onChangeName = (e) => {
        e.preventDefault();
        this.setState({name: e.target.value});
    }

    onChangeDescription = (e) => {
        e.preventDefault();
        this.setState({description: e.target.value});
    }

    onChangeRegistry = (e) => {
        e.preventDefault();
        this.setState({registry: e.target.value});
    }

    onSaveProfile = async (e) => {
        e.preventDefault();
        // console.log('Calling onSaveProfile...');

        this.props.dispatch(
            actionCreatorsAuth.updateProfile({
                user: {
                    name: this.state.name,
                },
                professional: {
                    registry: this.state.registry,
                    description: this.state.description,
                },
            })
        );
    }

    render() {
        return (
            <Layout>
                <h1 style={{fontWeight: 'bold', textAlign: 'left', marginBottom: 0, marginTop: 10, padding: 0}}>Perfil</h1>
                <h4 style={{color: "#666", fontSize: 14, fontWeight: 'bold'}}>Veja e edite as informações do seu perfil.</h4>

               <div style={{marginTop: 24, maxWidth: 400}}>
                    <form>
                        <Input label={"Nome"} name={"name"} type={"text"} value={this.state.name} onChange={this.onChangeName}/>
                        <Input label={"E-mail"} name={"email"} type={"text"} value={this.state.email} readOnly={true} disabled={true}/>
                        {this.props.user.type === "professional" && <Input label={this.state.registryType} name={"registry"} type={"text"} value={this.state.registry} onChange={this.onChangeRegistry}/>}
                        {this.props.user.type === "professional" && <TextArea label={"Sobre você"} name={"description"} value={this.state.description} onChange={this.onChangeDescription}/>}
                        <Button text={"Salvar"} type={"submit"} style={{width: '100%'}} onClick={this.onSaveProfile}/>
                    </form>
               </div>
            </Layout>
        );
    }
}

export default connect(mapStateToProps)(Profile);
