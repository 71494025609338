import React, { Component } from 'react';
import {connect} from "react-redux";
import unavailabilityResource from "../../resources/unavailability";
import * as moment from 'moment';
import { Grid, Row, Col } from 'react-flexbox-grid';
import {actions} from '../../reducers/unavailability';
import './list.style.scss';

const mapStateToProps = store => {
  return {
    user: store.authReducer.user,
    token: store.authReducer.token,
    unavailabilityList: store.unavailabilityListReducer
}};

class ListUnavailability extends Component {
    constructor(props){
        super(props);
        this.state = {
          unavailabilities: []
        };
    }

    async componentDidMount() {
      const {professional} = this.props
      if (!professional) return;
      const unavailabilities = await unavailabilityResource.fetch(
        this.props.token,
        professional.scheduleID
      ) || [];
      this.props.dispatch(actions.set(unavailabilities))
    }

    async remove(id) {
      const deleted = await unavailabilityResource.delete(
        this.props.token,
        id
      )
      if (deleted) {
        const {unavailabilities} = this.props.unavailabilityList
        this.props.dispatch(
          actions.set(unavailabilities.filter(u => u.id !== id))
        )
      }
    }

    render() {
      const {unavailabilities} = this.props.unavailabilityList
      return (
        <Grid fluid style={{textAlign: 'center', marginTop: 10}}>
          <Row>
            {
              unavailabilities && unavailabilities.map(unavailability => {
                return (
                  <Col
                    key={unavailability.id}
                    xs={12} md={6}
                  >
                    <div className="unavailability-item">
                      <div
                        className="cancel-icon"
                        onClick={() => this.remove(unavailability.id)}>
                          x
                      </div>
                      <p style={{fontSize: 12}}>
                        <b> De: </b> {moment(unavailability.from).format('DD/MM/YYYY HH:mm')}
                      </p>
                      <p style={{fontSize: 12}}>
                        <b> Até: </b> {moment(unavailability.to).format('DD/MM/YYYY HH:mm')}
                      </p>
                    </div>
                  </Col>
                )
              })
            }
          </Row>
        </Grid>
      )
    }
}

export default connect(mapStateToProps)(ListUnavailability);


