import React, { Component } from 'react';
import { 
    wrapper, 
    titleWrapper,
    title,
    infoWrapper,
    info
} from './DocumentDetails.module.scss';
import { ReactComponent as DefaultIcon } from './document.svg';
import { ReactComponent as MedicineIcon } from './medicine.svg';

import Moment from 'moment';

const categoryToIconsMap = (category) => {
    return {
        'prescrição': <MedicineIcon />           
    }[category] || <DefaultIcon />;
};

export default class DocumentDetails extends Component {
    onClickDownloadDocument = (e) => {
        e.preventDefault();

        window.open(this.props.document.download_url, '_blank');
    }

    getDocumentIcon() {
        const categories = this.props.document.tags.filter((tag) => {
            return tag.name === 'category'
        });

        console.log(`[DocumentDetails] - ${this.props.document._id} document categories ${JSON.stringify(categories)}`);

        if (categories.length) {
            return categoryToIconsMap(categories[0].value);
        }

        return <DefaultIcon />
    }

    render() {
        return (
            <div className={wrapper} onClick={this.onClickDownloadDocument}>
                <div className={titleWrapper}>
                    {this.getDocumentIcon()}
                    <div className={title}>
                        <h3>{this.props.document.name}</h3>
                    </div>
                </div>
                <div className={infoWrapper}>
                    <div className={info}>
                        <div>Emitido em:</div>
                        <div>{ Moment(this.props.document.created_at).format('DD/MM/YYYY [às] HH:mm') }</div>
                    </div>
                    {
                        this.props.document.expires_at
                        ? <div className={info}>
                                <div>Valido até:</div>
                                <div>{ Moment(this.props.document.expires_at).format('DD/MM/YYYY') }</div>
                            </div>
                        : ''
                    }
                </div>
            </div>
        );
    }
}