import React, {Component} from 'react';

import Sidebar from "./Sidebar";

class Layout extends Component {
    render() {
        return (
            <div
                style={{
                    display: 'flex',
                    ...this.props.styleWrapper
                }}
            >
                <Sidebar
                    title={this.props.title}
                    description={this.props.description}
                />

                <div
                    style={{
                        display: 'inline-block',
                        width: '100%',
                        backgroundColor: '#f7f7f7',
                        padding: 30,
                        ...this.props.style
                    }}
                >
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default Layout;
