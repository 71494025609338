import React, {Component} from 'react';

import Layout from "../components/Layout";

import {connect} from "react-redux";
import Loader from "../components/Loader";
import chatResource from '../resources/chat'

import {withRouter} from "react-router";
import Chat from "../screens/chat";

const mapStateToProps = store => ({
    isLogged: store.authReducer.isLogged,
    isLoaded: store.authReducer.isLoaded,
    isLoading: store.authReducer.isLoading,
    loadError: store.authReducer.loadError,
    user: store.authReducer.user,
    token: store.authReducer.token,
    professional: store.authReducer.professional,
    callStatus: store.videoCallReducer.status,
    callToken: store.videoCallReducer.token,
    roomName: store.videoCallReducer.roomName,
    roomId: store.videoCallReducer.roomId,
});

class ChatList extends Component {

    constructor(props) {
        super(props);

        const { match: { params } } = props;
        this.state = {
            loading: true,
            chatlist: [],
            activeChat: props.location && props.location.state && props.location.state.conversation ? props.location.state.conversation : null,
        };
    }

    componentDidMount() {
        this.fetchChatList();
    }

    fetchChatList = async () => {

        try {
            this.setState({
                loading: true,
            });

            let response = await chatResource.fetchConversations(this.props.user._id, this.props.token);
            console.log(response);

            if (!response.data.success) {
                throw new Error(response.data.message);
            }

            this.setState({
                loading: false,
                chatlist: [
                    ...response.data.conversations,
                    ...this.state.chatlist,
                ],
            });
        } catch(err){
            console.log(err);

            this.setState({
                loading: false,
            });

            alert(err.message);
        }
    };

    render() {
        return(
            <Layout style={{padding: 0}}>

                {this.state.loading && <div style={{display: 'flex', height: 'calc(100% - 0px)', justifyContent: 'center', alignItems: 'center'}}>
                    <Loader/>
                </div>}

                <div style={{display: 'flex', flexDirection: 'row', height: 'calc(100% - 0px)'}}>
                    <div className={"chat-left-area"}
                         style={{
                             display: 'inline-block',
                             // display: 'flex',
                             width: 300,
                             maxWidth: 300,
                             padding: 20,
                             borderRight: '1px solid #e1e1e1',
                             height: 'calc(100% - 0px)'
                         }}>
                        <h1 style={{fontWeight: 'bold', textAlign: 'left', marginBottom: 0, marginTop: 0, padding: 0}}>Contatos</h1>

                        {!this.state.loading && <div
                            style={{marginTop: 20,
                                position: 'relative',
                                overflowY: 'auto',
                                // height: 'calc(100% - 80px)',
                            }}>
                            {this.state.chatlist.map((item, index) => {
                                return(
                                    <button
                                        key={index}
                                        onClick={() => {
                                            // console.log("item", item);

                                            if(item !== this.state.activeChat){
                                                this.setState({
                                                    activeChat: null
                                                }, () => {
                                                    this.setState({
                                                        activeChat: item
                                                    })
                                                });
                                            }
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            outline: 0,
                                            border: 'none',
                                            width: '100%',
                                            borderTopStyle: 'solid',
                                            borderBottomStyle: 'solid',
                                            borderTopWidth: index === 0 ? 1 : 0,
                                            borderColor: '#e1e1e1',
                                            borderBottomWidth: '1px',
                                            paddingBottom: 10,
                                            paddingTop: 10,
                                            display: 'flex',
                                            backgroundColor: this.state.activeChat ? (String(item.user._id) === String(this.state.activeChat.user._id) ? 'rgba(0,0,0,0.1)' : 'rgba(0,0,0,0)') : 'rgba(0,0,0,0)'
                                        }}
                                    >
                                        <div style={{flex: 0, display: 'flex', alignItems: 'center'}}>
                                            <img alt="user-avatar" src={"https://i.pravatar.cc/300?u="+index} style={{width: 50, height: 50, borderRadius: '50%'}}/>
                                        </div>
                                        <div style={{flex: 1, marginLeft: 15, display: 'flex', alignItems: 'center', height: 50, position: 'relative'}}>
                                            <h3 style={{fontSize: 18, textAlign: 'left'}}>{item.user.name}</h3>
                                        </div>
                                        <div style={{flex: 0, marginLeft: 10, display: 'flex', alignItems: 'center', height: 50, position: 'relative'}}>
                                            {item.unreadMessages > 0 && <div style={{backgroundColor: '#1f295c', color: '#fff', paddingTop: 6, fontSize: 12, height: 24, width: 24, borderRadius: 12}}>{item.unreadMessages > 9 ? '9+' : item.unreadMessages}</div>}
                                        </div>
                                    </button>
                                )
                            })}
                            {this.state.chatlist.length === 0 && <div style={{marginTop: 40, textAlign: 'left'}}>Sem contatos cadastrados.</div>}
                        </div>}
                    </div>

                    <div className={"chat-right-area"} style={{
                        display: 'inline-block',
                        flex: 1,
                        position: 'relative',
                        width: 'calc(100% - 300px)',
                        height: 'calc(100% - 0px)'
                    }}>
                        {this.state.activeChat && <Chat
                            userTarget={this.state.activeChat.user._id}
                            // userTarget={'158568765027122222222222'}
                            // userTarget={'158568765027111111111111'}
                        />}
                    </div>
                </div>
            </Layout>
        );
    }
}

export default connect(mapStateToProps)(withRouter(ChatList));
