import React, {Component} from 'react';
import Room from '../components/VideoRoom';

import 'react-calendar/dist/Calendar.css';
import { withRouter } from "react-router";

import {connect} from 'react-redux';
import {actionCreators as actionCreatorsCall} from "../reducers/video-call";


const mapStateToProps = store => ({
    callStatus: store.videoCallReducer.status,
    roomName: store.videoCallReducer.roomName,
    roomId: store.videoCallReducer.roomId,
    patientName: store.videoCallReducer.name,
    callToken: store.videoCallReducer.token,
    patientInfo: store.videoCallReducer.patientInfo,
    callInfo: store.videoCallReducer.callInfo,
});

class VideoCall extends Component {

    endCall = (callId) => {
        if (this.props.callStatus === 'calling') {
            return this.props.dispatch(actionCreatorsCall.callCancel(callId));
        }

        this.props.dispatch(actionCreatorsCall.callEnd(callId));
    };

    render() {

        return(
            <div style={{backgroundColor: '#000', height: '100vh'}}>
                <Room
                  endCall={this.endCall}
                  callStatus={this.props.callStatus}
                  patientInfo={this.props.patientInfo}
                  callInfo={this.props.callInfo}
                />
            </div>
        )
    }
}

export default connect(mapStateToProps)(withRouter(VideoCall));

