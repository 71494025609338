import requestUtils from '../utils/request';
import axios from 'axios';
import {defaultHeaders} from '../utils/request';
import apiConfig from '../configs/api';

const {SCHEDULE_API} = apiConfig

export default {
    async fetch(token, scheduleId) {
        const response = await axios({
            url: `${SCHEDULE_API}/unavailability${scheduleId ? '?scheduleId='+scheduleId : ''}`,
            method: 'GET',
            headers: {
                ...defaultHeaders,
                Authorization: token ? `Baerer ${token}` : undefined,
                'Content-type': 'application/json'
            },
        })
        return response.data
    },
    async create(token, body) {
      const response = await requestUtils({
          url: `${SCHEDULE_API}/unavailability`,
          method: 'POST',
          token,
          data: body
      });
      return response.data
    },
    async delete(token, id) {
      const response = await requestUtils({
          url: `${SCHEDULE_API}/unavailability/${id}`,
          method: 'DELETE',
          token
      });
      return response.data
    }
};
