import React, {Component} from 'react';

import Layout from '../components/Layout'
import BirthdateField from '../components/BirthdateField';
import GenderField from '../components/GenderField';
import AppointmentWidget from '../components/AppointmentWidget';
import DocumentWidget from '../components/DocumentWidget';

import {withRouter} from "react-router";
import {connect} from "react-redux";
import { Link } from "react-router-dom";

import { actionCreators as actionCreatorsCall } from "../reducers/video-call";
import scheduleResource from "../resources/schedule";
import professionalResource from "../resources/professional";
import Loader from "../components/Loader";
import scheduleUtils from "../utils/schedule";
import chatResource from "../resources/chat";
import dataUtils from "../utils/data";
import ProfessionalCategory from "../components/ProfessionalCategory";
import Unavailability from "../components/Unavailability";

import 'react-calendar/dist/Calendar.css';
import '../styles/notFound.scss';
import Icon from "@mdi/react";
import {
    mdiBottleTonicPlus,
    mdiContentCopy,
    mdiDotsHorizontal,
    mdiPencil,
    mdiPound,
    mdiSquareEditOutline
} from "@mdi/js";
import Moment from "moment";
import Professional from "../images/professional.svg";
import ProfessionalRed from "../images/professional-red.svg";
import "react-toggle/style.css" // for ES6 modules
import Toggle from 'react-toggle'
import CreateOrEditProfessionalModal from "../components/createOrEditProfessionalModal";
import AgendaSelectModal from "../components/agendaSelectModal";
import Medical from '../images/medical.png';
import MedicalBlue from '../images/medical-blue.png';
import MedicalGreen from '../images/medical-green.png';
import NextAppointments from '../components/NextAppointments';

const mapStateToProps = store => ({
    isLogged: store.authReducer.isLogged,
    isLoaded: store.authReducer.isLoaded,
    isLoading: store.authReducer.isLoading,
    loadError: store.authReducer.loadError,
    user: store.authReducer.user,
    token: store.authReducer.token,
    professional: store.authReducer.professional,
    callStatus: store.videoCallReducer.status,
    callToken: store.videoCallReducer.token,
    roomName: store.videoCallReducer.roomName,
    roomId: store.videoCallReducer.roomId,
});

class ProfessionalDetail extends Component {

    constructor(props) {
        super(props);

        const start =  Moment().startOf('day').toDate();
        const end = Moment().startOf('day').toDate();

        this.state = {
            loading: true,
            modalVisible: false,
            agendaSelectModalVisible: false,
            professional: null,
            range: Moment.range(start, end),
            appointments: [],
            dailyAppointments: [],
            reservations: [],
        };
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        console.log(params.id);
        this.getProfessional(params.id);
        
    }

    fetchReservations = async () => {
        try {
            this.setState({
                loadingReservations: true,
            });

            const response = await scheduleResource.getProfessionalReservations(
                this.state.professional.id,
                this.props.token,
            );

            if (response.status !== 200) {
                throw new Error(response.data.message);
            }

            this.setState({
                loadingReservations: false,
                reservations: response.data,
            });
        } catch(err){
            this.setState({
                loadingReservations: false,
            });

            alert(err.message);
        }
    };

    fetchSchedules = async () => {
        const alias = scheduleUtils.getAlias(this.state.professional.type);

        try {
            this.setState({
                loading: true,
            });

            const response = await scheduleResource.fetch(
                alias,
                this.state.professional.scheduleID,
                this.props.token,
                {
                    start: Moment().add(-1, "days").format('YYYY-MM-DDTHH:mm:ssZ'),
                    end: Moment().add(7, "days").format('YYYY-MM-DDTHH:mm:ssZ'),
                    limit: 20
                }
            );

            if (! response.data.success) {
                throw new Error(response.data.message);
            }

            this.setState({
                loading: false,
                appointments: response.data.appointments,
            });
        } catch(err){
            this.setState({
                loading: false,
            });

            alert(err.message);
        }
    };

    fetchDailySchedule = async () => {
        const alias = scheduleUtils.getAlias(this.state.professional.type);

        try {
            this.setState({
                loadingDailySchedule: true,
            });

            const end = this.state.range.end.clone();

            const response = await scheduleResource.fetch(
                alias,
                this.state.professional.scheduleID,
                this.props.token,
                {
                    start: this.state.range.start.format('YYYY-MM-DDTHH:mm:ssZ'),
                    end: end.add(1, 'day').format('YYYY-MM-DDTHH:mm:ssZ'),
                }
            );

            if (! response.data.success) {
                throw new Error(response.data.message);
            }

            this.setState({
                loadingDailySchedule: false,
                dailyAppointments: response.data.appointments,
            });
        } catch(err){
            this.setState({
                loadingDailySchedule: false,
            });

            alert(err.message);
        }
    };

    getProfessional = async (id) => {
        try {
            this.setState({
                loading: true,
            });

            let response = await professionalResource.get(
              this.props.token,
              id,
            );

            // if (!response.data.success) {
            //     this.setState({
            //         redirect: true,
            //         redirectTo: '/404',
            //     });
            //     return;
            // }
            this.setState({
                loading: false,
                // professional: response.data.filter(item => item.id === id)[0],
                professional: response.data,
            }, () => {
                this.fetchDailySchedule();
                this.fetchSchedules();
                this.fetchReservations();
            });

        } catch(err){
            console.log(err);

            this.setState({
                loading: false,
            });
        }
    };

    getProfessionalImageByType = (professionalType) => {
        if(professionalType === "doctor"){
            return MedicalBlue;
        }else if(professionalType === "psychologist"){
            return Medical;
        }else if(professionalType === "nutritionist"){
            return MedicalGreen;
        }
    };

    onChangeActive = async () => {
        try {
            const newStatus = this.state.professional.status === "active" ? "inactive" : "active";

            this.setState({
                professional: {
                    ...this.state.professional,
                    status: newStatus
                }
            });

            let response = await professionalResource.update(
                this.props.token,
                this.state.professional.id,
                {
                    status: newStatus
                }
            );
            console.log(response);

        } catch(err){
            console.log(err);

            this.setState({
                loading: false,
            });
        }
    };

    render(){
        let weekDaySlots = {};

        if(!this.state.loading){
            for(let item of this.state.professional.availability){
                if(!weekDaySlots[item.weekday]){
                    weekDaySlots[item.weekday] = [item];
                }else{
                    weekDaySlots[item.weekday].push(item);
                }
            }
        }
        // console.log("weekDaySlots", weekDaySlots);

        // let appointmentsWeekDaySlots = {
        //     [Moment().format('DD/MM')]: [
        //         {
        //             patient: {
        //                 name: "Jussara Skywalker"
        //             },
        //             start: new Date(),
        //             end: new Date().getTime() + (30 * 60 * 1000)
        //         },
        //         {
        //             patient: {
        //                 name: "Will Smith"
        //             },
        //             start: new Date().getTime() + (120 * 60 * 1000),
        //             end: new Date().getTime() + (150 * 60 * 1000)
        //         }
        //     ],
        //     [Moment().add(1, 'day').format('DD/MM')]: [
        //         {
        //             patient: {
        //                 name: "Will Smith"
        //             },
        //             start: new Date().getTime() + (120 * 60 * 1000),
        //             end: new Date().getTime() + (150 * 60 * 1000)
        //         }
        //     ]
        // };

        let appointmentsWeekDaySlots = {};
        if(!this.state.loading){
            for(let item of this.state.appointments){
                if(!appointmentsWeekDaySlots[Moment(item.start).format('DD/MM')]){
                    appointmentsWeekDaySlots[Moment(item.start).format('DD/MM')] = [item];
                }else{
                    appointmentsWeekDaySlots[Moment(item.start).format('DD/MM')].push(item);
                }
            }
        }

        return (
            <Layout>
                <div>
                    <div>
                        {this.state.loading && <div style={{marginTop: 40}}>
                            <Loader/>
                        </div>}
                        {!this.state.loading && <div>
                            <div style={{display: 'flex'}}>
                                <div style={{flex: 1}}>
                                    <h1 style={{fontWeight: 'bold', textAlign: 'left', marginBottom: 20, color: '#9C0D4F', fontSize: 22}}>
                                        <span style={{borderBottom: '2px solid #9C0D4F', paddingBottom: 3,}}><img alt={"Professional"} src={ProfessionalRed} style={{width: 26, marginBottom: -3, marginRight: 8}} />
                                        Profissionais</span> <span style={{fontSize: 16}}>/ {this.state.professional.name}</span>
                                    </h1>
                                </div>
                                <div style={{flex: 0, display: 'flex', flexDirection: 'row'}}>
                                    <div style={{flex: 0, fontSize: 24, fontWeight: 'bold', color: (this.state.professional?.status === "active" ? '#28A745' : '#373737'), marginRight: 7}}>{this.state.professional?.status === "active" ? 'Ativo' : 'Inativo'}</div>
                                    <div style={{flex: 0}}>
                                        <Toggle
                                            defaultChecked={this.state.professional?.status === "active"}
                                            icons={false}
                                            onChange={this.onChangeActive}
                                        />
                                    </div>
                                    <div style={{flex: 0, marginLeft: 7}}>
                                        <button
                                            style={{
                                                border: 'none',
                                                margin: 0,
                                                outline: 'none',
                                                cursor: "pointer",
                                                background: '#B1B1B1',
                                                padding: 0,
                                                borderRadius: 5,
                                                minWidth: 32,
                                                minHeight: 32,
                                                marginLeft: 7
                                            }}
                                            onClick={() => {
                                                // this.props.history.push('/editar/profissional/'+this.state.professional.id);
                                                this.setState({
                                                    modalVisible: true
                                                });

                                                
                                            }}
                                        >
                                            <Icon
                                                path={mdiSquareEditOutline}
                                                title="Editar"
                                                size={0.7}
                                                color="#fff"
                                                style={{
                                                    marginLeft: 0,
                                                    marginTop: 3
                                                }}
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div style={{display: 'flex'}}>
                                <div style={{flex: 1, textAlign: 'center', marginRight: 30}}>
                                    <img
                                        src={this.state.professional.avatar}
                                        style={{
                                            width: 256,
                                            height: 256,
                                            borderRadius: '50%',
                                            boxShadow: '0 8px 24px 0 rgba(0,0,0, 0.3)'
                                        }}
                                    />
                                    <h3 style={{fontSize: 32, marginTop: 20, marginBottom: 20}}>{this.state.professional.name}</h3>

                                    <div style={{display: 'flex', textAlign: 'center', marginBottom: 40}}>
                                        <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end', marginRight: 10}}>
                                            {/*<Icon*/}
                                            {/*    path={mdiBottleTonicPlus}*/}
                                            {/*    title="Especilidade"*/}
                                            {/*    size={1}*/}
                                            {/*    color={dataUtils.getProfessionalColorByType(this.state.professional.type)}*/}
                                            {/*/>*/}
                                            <img src={this.getProfessionalImageByType(this.state.professional.type)} style={{width: 24, height: 24}}/>
                                            <span style={{marginTop: 5, fontSize: 14, marginLeft: 5, color: dataUtils.getProfessionalColorByType(this.state.professional.type), fontWeight: 'bold'}}>{dataUtils.getProfessionalNameByType(this.state.professional.type)}</span>
                                        </div>
                                        <div style={{flex: 1, marginTop: 3, display: 'flex', justifyContent: 'flex-start', marginLeft: 10}}>
                                            <Icon
                                                path={mdiPound}
                                                title="Id"
                                                size={0.85}
                                                color="#666"
                                            />
                                            <span style={{marginTop: 3, fontSize: 14, color: "#676767", fontWeight: 'bold', marginLeft: 5, display: 'inline-block', maxWidth: 75, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>
                                                {this.state.professional.id}
                                            </span>
                                            <button
                                                style={{
                                                    border: 'none',
                                                    margin: 0,
                                                    outline: 'none',
                                                    cursor: "pointer",
                                                    background: 'none',
                                                    marginLeft: 7
                                                }}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(this.state.professional.id).then(function() {
                                                        /* clipboard successfully set */
                                                        console.log("copied");
                                                    }, function(err) {
                                                        /* clipboard write failed */
                                                        console.log(err);
                                                    });
                                                }}
                                            >
                                                <Icon
                                                    path={mdiContentCopy}
                                                    title="Copiar"
                                                    size={0.7}
                                                    color="#666"
                                                    style={{
                                                        marginLeft: 0,
                                                        marginTop: 3
                                                    }}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                    <div className={"box"} style={{textAlign: 'left', marginBottom: 30}}>
                                        <h3 style={{fontSize: 24}}>Panorama</h3>

                                        <div style={{marginTop: 20, display: 'flex'}}>
                                            <div style={{marginRight: 20}}>
                                                <div style={{fontWeight: '600', fontSize: 16, color: '#666'}}>PACIENTES</div>
                                                <p style={{fontSize: 32}}>{this.state.professional?.reservationStats?.customers}</p>
                                            </div>
                                            <div style={{marginRight: 20}}>
                                                <div style={{fontWeight: '600', fontSize: 16, color: '#666'}}>TERAPIAS SEMANAIS</div>
                                                {/*<p style={{fontSize: 32}}>{this.state.professional?.availabilityStats?.availability || 0}</p>*/}
                                                <p style={{fontSize: 32}}>{this.state.professional?.reservationStats?.occupiedSlots}<span style={{fontSize: 16}}>/{this.state.professional?.reservationStats?.totalSlots}</span></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={'box'} style={{textAlign: 'left', marginBottom: 30}}>
                                        <h3 style={{fontSize: 24}}>Agenda de hoje</h3>

                                        <div style={{marginTop: 20, display: 'flex'}}>
                                            <div style={{marginRight: 20}}>
                                                <div style={{fontWeight: '600', fontSize: 16, color: '#666'}}>AGENDAMENTOS</div>
                                                <p style={{fontSize: 32}}>{this.state.dailyAppointments.filter(item => item.status === "created" || item.status === "confirmed").length}<span style={{fontSize: 14}}>{this.state.dailyAppointments.length > 0 ? "/"+this.state.dailyAppointments.length : ""}</span></p>
                                            </div>
                                            <div style={{marginRight: 20}}>
                                                <div style={{fontWeight: '600', fontSize: 16, color: '#666'}}>CANCELAMENTOS</div>
                                                <p style={{fontSize: 32}}>{this.state.dailyAppointments.filter(item => item.status === "canceled").length}</p>
                                            </div>
                                            <div style={{marginRight: 20}}>
                                                <div style={{fontWeight: '600', fontSize: 16, color: '#666'}}>NO SHOW</div>
                                                <p style={{fontSize: 32}}>{this.state.dailyAppointments.filter(item => item.status === "noshow").length}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{flex: 1}}>
                                    <NextAppointments isAdmin={true} professionalProps={this.state.professional} />
                                    
                                    {/* <div className={"box"} style={{marginBottom: 30, height: 563, overflowY: 'scroll'}}>
                                        <h3 style={{fontSize: 24}}>Próximas consultas</h3>

                                        <div style={{marginTop: 15}}>
                                            {!this.state.loading && this.state.appointments.length === 0 && <p>Sem agendamentos cadastrados.</p>}
                                            
                                            {Object.entries(appointmentsWeekDaySlots).map((item, index) => {
                                                return(
                                                    <div key={index} style={{display: 'flex'}}>
                                                        <div style={{flex: 0, position: 'relative', paddingRight: 10, borderRight: '3px solid #e7e7e7'}}>
                                                            <span style={{marginRight: 10, fontWeight: 'bold', width: 55, display: 'block'}}>{item[0] === String(Moment().format('DD/MM')) ? "Hoje" : item[0]}</span>
                                                            <div style={{
                                                                height: 16,
                                                                width: 16,
                                                                borderRadius: '50%',
                                                                backgroundColor: '#fff',
                                                                // backgroundColor: '#1F295C',
                                                                padding: 2,
                                                                border: '2px solid #ccc',
                                                                position: 'absolute',
                                                                top: 0,
                                                                right: -10
                                                            }}>
                                                                <div style={{
                                                                    backgroundColor: '#FC1481',
                                                                    height: 8,
                                                                    width: 8,
                                                                    borderRadius: '50%',
                                                                }}/>
                                                            </div>
                                                        </div>
                                                        <div style={{flex: 1, marginLeft: 20, paddingBottom: Object.entries(appointmentsWeekDaySlots).length-1 === index ? 0: 30}}>
                                                            {item[1].map((itemAppointments, indexAppointments) => {
                                                                return(
                                                                    <div key={indexAppointments} className={"box"} style={{marginTop: 15, marginBottom: 15, border: '1px solid #e7e7e7', padding: 12, borderRadius: 7}}>
                                                                        <p><b>{itemAppointments.patient.name}</b></p>
                                                                        <p style={{fontSize: 14}}>{Moment(itemAppointments.start).format('HH:mm')} - {Moment(itemAppointments.end).format('HH:mm')}</p>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div> */}

                                    <div className={"box"} style={{marginBottom: 30}}>
                                        <div style={{display: 'flex'}}>
                                            <div style={{flex: 1}}>
                                                <h3 style={{fontSize: 24}}>Configurações da agenda</h3>
                                            </div>
                                            <div style={{flex: 0}}>
                                                <button
                                                    style={{
                                                        border: 'none',
                                                        margin: 0,
                                                        outline: 'none',
                                                        cursor: "pointer",
                                                        background: 'none',
                                                        padding: 1,
                                                        borderRadius: 5,
                                                        minWidth: 30,
                                                        minHeight: 20,
                                                        marginLeft: 7
                                                    }}
                                                    onClick={() => {
                                                        // this.props.history.push('/editar/profissional/'+this.state.professional.id);
                                                        this.setState({
                                                            agendaSelectModalVisible: true
                                                        });

                                                        document.getElementsByTagName('body')[0].style.cssText = 'overflow:hidden';
                                                    }}
                                                >
                                                    <Icon
                                                        path={mdiDotsHorizontal}
                                                        title="Editar"
                                                        size={1.4}
                                                        color="#666"
                                                        style={{
                                                            marginLeft: 3,
                                                            marginTop: -3
                                                        }}
                                                    />
                                                </button>
                                            </div>
                                        </div>

                                        <div style={{marginTop: 15}}>
                                            {!this.state.loading && this.state.professional.availability?.length === 0 && <p>Sem horários cadastrados.</p>}

                                            {!this.state.loading && Object.entries(weekDaySlots).sort((a,b) => {
                                                const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
                                                return weekdays.indexOf(a[0]) - weekdays.indexOf(b[0]);
                                            }).map((item, index) => {
                                                return(
                                                    <div key={index} style={{marginBottom: 10, marginTop: 10, borderRadius: 7, color: '#383838'}}>
                                                        <p style={{fontSize: 14}}><b>{dataUtils.getWeekdayName(item[0])}</b></p>
                                                        <p style={{fontSize: 12}}>
                                                            {item[1].map((itemSlot, indexSlot) => {
                                                                return(
                                                                    <span key={indexSlot} style={{marginRight: 20}}>
                                                                        {itemSlot.start.split(':')[0]}:{itemSlot.start.split(':')[1]} - {itemSlot.end.split(':')[0]}:{itemSlot.end.split(':')[1]}
                                                                        {/*{item[1].length-1 !== indexSlot ? ", " : ""}*/}
                                                                    </span>
                                                                )
                                                            })}
                                                        </p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <ProfessionalCategory professional={this.state.professional}/>
                                    <Unavailability professional={this.state.professional}/>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
                {this.state.modalVisible && <CreateOrEditProfessionalModal
                    onCloseRequest={(updatedProfessional = null) => {
                        // console.log("updatedProfessional", updatedProfessional);
                        this.setState({
                            modalVisible: false,
                            professional: updatedProfessional ? {
                                ...this.state.professional,
                                name: updatedProfessional.name,
                                type: updatedProfessional.type,
                                registry: updatedProfessional.registry,
                                registryType: updatedProfessional.registryType,
                                avatar: updatedProfessional.avatar,
                                email: updatedProfessional.email,
                                phone: updatedProfessional.phone,
                                description: updatedProfessional.description,
                                facebook: updatedProfessional.facebook,
                                instagram: updatedProfessional.instagram,
                            } : this.state.professional
                        });
                    }}
                    professional={this.state.professional}
                    visible={this.state.modalVisible}
                />}
                {this.state.agendaSelectModalVisible && <AgendaSelectModal
                    onCloseRequest={(newAvailability = null) => {
                        this.setState({
                            agendaSelectModalVisible: false,
                            professional: newAvailability ? {
                                ...this.state.professional,
                                availability: newAvailability
                            } : this.state.professional
                        });
                    }}
                    professional={this.state.professional}
                    visible={this.state.agendaSelectModalVisible}
                />}
            </Layout>
        )
    }
}

export default connect(mapStateToProps)(withRouter(ProfessionalDetail));
