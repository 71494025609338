import React, {Component} from 'react';
import { withRouter, Redirect } from "react-router";

import {connect} from 'react-redux';
import {actionCreators as actionCreatorsAuth} from "../reducers/auth";

const mapStateToProps = store => ({
    callStatus: store.videoCallReducer.status,
    callToken: store.videoCallReducer.token,
    roomName: store.videoCallReducer.roomName,
    roomId: store.videoCallReducer.roomId,
});

class Logout extends Component {

    componentDidMount(){
        const {dispatch} = this.props;

        dispatch(actionCreatorsAuth.logout());
    }

    render(){
        return(
            <Redirect to="/login"/>
        );
    }
}

export default connect(mapStateToProps)(withRouter(Logout));

