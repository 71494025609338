import React, { useState, useEffect, useRef } from 'react';

import {ReactComponent as MutedIcon} from './micIndicator.svg'

const Participant = ({ participant, identity, isRemote }) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [videoEnabled, setVideoEnabled] = useState(true);


  const getInitials = () => {
    const parts = identity.split(' ');

    return parts[0][0] + parts[parts.length - 1][0];
  }
  
  const videoRef = useRef();
  const audioRef = useRef();

  const trackpubsToTracks = trackMap => Array.from(trackMap.values())
    .map(publication => publication.track)
    .filter(track => track !== null);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = track => {
      track.on('enabled', myTrack => {
        if (myTrack.kind === 'video') {
          setVideoEnabled(true);
        } else {
          setAudioEnabled(true);
        }
      });
      track.on('disabled', myTrack => {
        if (myTrack.kind === 'video') {
          setVideoEnabled(false);

        } else {
          setAudioEnabled(false);
        }
      });
      if (track.kind === 'video') {
        setVideoTracks(videoTracks => [...videoTracks, track]);
      } else {
        setAudioTracks(audioTracks => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = track => {
      if (track.kind === 'video') {
        setVideoTracks(videoTracks => videoTracks.filter(v => v !== track));
      } else {
        setAudioTracks(audioTracks => audioTracks.filter(a => a !== track));
      }
    };

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  return (
    <div className="participant">
      <div style={{display: 'in-line'}}>
        {!audioEnabled ? <div className="noSound"><MutedIcon /></div> : ''}
        {/* {!videoEnabled && streamIndicative(mdiVideoOff)} */}
      </div>

      <video ref={videoRef} autoPlay={true} style={{display: videoEnabled ? 'block' : 'none'}}/>
      {! videoEnabled ? <div className="noVideo">{getInitials()}</div> : ''}
      <audio ref={audioRef} autoPlay={true} muted={false} />
    </div>
  );
};

export default Participant;
