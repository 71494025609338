module.exports = {
    formatterDate(fullDate, simpleDate){
        const transformFullDate = fullDate.split('');
        const transformSimpleData = simpleDate.split('-');
        
        return `${transformFullDate[0]} ${transformSimpleData[2]}/${transformSimpleData[1]}`;
    },

    formatterDataGraphsJson(dataGraphs){
        let dataGraphsFormatted = [];

        for(let i = 0; i < dataGraphs.length; i++){
            let data = {
                date: dataGraphs[i]["date"],
                availability: dataGraphs[i]["schedule"]["availability"],
                plan: dataGraphs[i]["schedule"]["plan"],
                trial: dataGraphs[i]["schedule"]["trial"]
            }
            dataGraphsFormatted.push(data);
        }
        
        return dataGraphsFormatted;
    },

    indexAnalyzer(dataGraphs, validator){
        for(let i = dataGraphs.length - 1; i >= 0; i--){
            if(dataGraphs[i][validator]){
                return i;
            }
        }
        return 0;
    }
}