import chatResource from '../resources/chat'
import callResource from "../resources/call";

export const types = {
    FETCH_REQUEST: 'FETCH_REQUEST',
    FETCH_RESPONSE: 'FETCH_RESPONSE',
    ADD_MESSAGE: 'ADD_MESSAGE',
    READ_MESSAGES: 'READ_MESSAGES',
    CLEAR: 'CLEAR',
};

export const actionCreators = {
    fetch: (userTarget) => async (dispatch, getState) => {
        try {
            dispatch({
                type: types.FETCH_REQUEST
            });

            const timestamp = getState().chatReducer.messages.length > 0 ? getState().chatReducer.messages[getState().chatReducer.messages.length-1].created_at : new Date();

            let response = await chatResource.fetch(getState().authReducer.user._id, userTarget, timestamp, getState().authReducer.token);
            console.log(response);

            if (!response.data.success) {
                throw new Error(response.data.message);
            }

            dispatch({
                type: types.FETCH_RESPONSE,
                payload: {
                    messages: response.data.messages,
                    activeChatTargetUserId: userTarget
                }
            });

            let messageIds = response.data.messages
                .filter(message => message.status === "not_read" && message.user_to === getState().authReducer.user._id)
                .map(message => message._id);
            if(messageIds.length > 0){
                dispatch(actionCreators.read(messageIds));
            }

        } catch (err) {
            console.log(err);

            dispatch({
                type: types.FETCH_RESPONSE,
                payload: {
                    error: err.message
                }
            });

            alert(err.message);
        }
    },
    read: (messageIds) => async (dispatch, getState) => {
        console.log("read", messageIds);

        try {
            let response = await chatResource.readArray(messageIds, getState().authReducer.token);
            console.log(response);

            if (!response.data.success) {
                throw new Error(response.data.message);
            }

            dispatch({
                type: types.READ_MESSAGES,
                payload: {
                    messageIds
                }
            });
        } catch (err) {
            console.log(err);

            dispatch({
                type: types.READ_MESSAGES,
                payload: {
                    error: err.message
                }
            });

            alert(err.message);
        }
    },
    addMessage: (data) => async (dispatch, getState) => {
        console.log('addMessage', data);

        dispatch({
            type: types.ADD_MESSAGE,
            payload: data
        });
    },
    onMessageReceive: (data) => async (dispatch, getState) => {
        console.log('onMessageReceive', data);

        if(getState().chatReducer.activeChatTargetUserId === data.user_from) {
            dispatch({
                type: types.ADD_MESSAGE,
                payload: data
            });

            if (getState().authReducer.user._id === data.user_to) {
                // console.log("lendo msg", data._id);
                dispatch(actionCreators.read([data._id]));
            }
        }
    },
    onMessageRead: (data) => async (dispatch, getState) => {
        console.log('onMessageRead', data);

        if(getState().chatReducer.activeChatTargetUserId === data.user_to) {
            dispatch({
                type: types.READ_MESSAGES,
                payload: {
                    messageIds: [data._id]
                }
            });
        }
    },
    clear: () => async (dispatch, getState) => {
        dispatch({type: types.CLEAR});
    },
};

const initialState = {
    loading: true,
    fetching: false,
    sending: false,
    messages: [],
    activeChatTargetUserId: null,
    hasMoreMessages: null,
    error: null,
};

export default function chat(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case types.FETCH_REQUEST: {
            return {
                ...state,
                fetching: true,
                error: null,
            };
        }
        case types.FETCH_RESPONSE: {
            if(payload.error){
                return {
                    ...state,
                    loading: false,
                    fetching: false,
                    error: payload.error
                };
            }else{
                return {
                    ...state,
                    loading: false,
                    fetching: false,
                    activeChatTargetUserId: payload.activeChatTargetUserId,
                    messages: [
                        ...state.messages,
                        ...payload.messages
                    ],
                    hasMoreMessages: payload.messages.length > 0
                };
            }
        }
        case types.ADD_MESSAGE: {
            return {
                ...state,
                messages: [
                    payload,
                    ...state.messages,
                ]
            };
        }
        case types.READ_MESSAGES: {
            if(payload.error){
                return {
                    ...state,
                    error: payload.error
                };
            }else{
                return {
                    ...state,
                    error: null,
                    messages: state.messages.map(message => {
                        return {
                            ...message,
                            status: payload.messageIds.includes(message._id) ? 'read' : message.status
                        }
                    })
                };
            }
        }
        case types.CLEAR: {
            return initialState;
        }
        default:
            return state;
    }
}
