import React, {Component} from 'react';
import { Link } from "react-router-dom";
import Icon from '@mdi/react'
import Layout from "../components/Layout";
import Badge from "../components/Badge";
import scheduleResource from "../resources/schedule";
import patientResource from "../resources/patient";
import {connect} from "react-redux";
import Loader from "../components/Loader";
import scheduleUtils from '../utils/schedule'
import Moment from 'moment';
import { registerLocale } from "react-datepicker";
import DateRangePicker from "react-daterange-picker";
import PatientRed from "../images/user-icon-red.png";
import "react-daterange-picker/dist/css/react-calendar.css";
import ptBr from 'date-fns/locale/pt-BR';
import {
  mdiAlarm,
  mdiPound,
  mdiCalendarRange,
  mdiCalendar,
  mdiGenderMaleFemale,
  mdiCakeVariant,
  mdiAccount,
  mdiMessageVideo,
  mdiClipboardAccount,
  mdiBottleTonicPlus,
  mdiCheck,
  mdiCheckBoxOutline,
  mdiClipboard,
  mdiContentCopy, mdiCheckboxMarked, mdiFileDocument, mdiDotsVertical
} from '@mdi/js';
import Input from "../components/Input";
import {btn} from "../components/DocumentWidget/DocumentWidget.module.scss";
import Button from "../components/Button";
import dataUtils from "../utils/data";
import {withRouter} from "react-router";
import Search from "../images/search.png";

registerLocale('pt-BR', ptBr);

const mapStateToProps = store => ({
  isLogged: store.authReducer.isLogged,
  isLoaded: store.authReducer.isLoaded,
  isLoading: store.authReducer.isLoading,
  loadError: store.authReducer.loadError,
  user: store.authReducer.user,
  token: store.authReducer.token,
  professional: store.authReducer.professional,
  callStatus: store.videoCallReducer.status,
  callToken: store.videoCallReducer.token,
  roomName: store.videoCallReducer.roomName,
  roomId: store.videoCallReducer.roomId,
});

class Patients extends Component {

  constructor(props) {
    super(props);

    // const start =  Moment().startOf('day').toDate();
    // const end = Moment().startOf('day').toDate();

    this.state = {
      loading: true,
      selectedStatusFilter: "reserved",
      patients: [],
      filteredPatients: [],
    };

    this.timer = null;
  }

  componentDidMount() {
    this.fetchPatients(0);
  }

  fetchPatients = async (timer = 1000) => {
    clearTimeout(this.timer);

    this.timer = setTimeout(async () => {

      try {
        this.setState({
          loading: true,
          patients: [],
          filteredPatients: [],
          // selectedStatusFilter: "reserved"
        });

        const response = await scheduleResource.getProfessionalReservations(this.props.professional._id, this.props.token, this.state.selectedStatusFilter);
        if (response.status !== 200) {
          throw new Error(response.data.message);
        }

        this.setState({
          loading: false,
          patients: response.data,
          filteredPatients: response.data,
        });
      } catch(err){
        this.setState({
          loading: false,
        });

        alert(err.message);
      }
    }, timer);
  };

  onChangeSearch = (event) => {
    let searchedValue = [];

    if(event.target.value){
      searchedValue = (this.state.patients).filter(item => (item.patient["name"].toLowerCase()).includes((event.target.value).toLowerCase()));
    }
    else{
      searchedValue = this.state.patients;
    }

    this.setState({
      search: event.target.value,
      filteredPatients: searchedValue,
      //filteredPatients: !event.target.value || event.target.value === "" ? this.state.patients : this.state.patients.filter(item => item.name.toLowerCase().search(event.target.value.toLowerCase()) >= 0)
    });
  }

  displayAge(birthDate) {
    const birthdate = Moment(birthDate);
    const now = Moment();

    const diff = Moment.preciseDiff(birthdate, now);

    return `${diff.split(' ')[0]} anos e ${diff.split(' ')[2]} meses`;
  }

  render() {
    return (
      <Layout>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <h1 style={{fontWeight: 'bold', textAlign: 'left', marginBottom: 0, color: '#9C0D4F', fontSize: 24, borderBottom: '2px solid #9C0D4F', paddingBottom: 0,}}>
            <img alt={"Paciente"} src={PatientRed} style={{width: 24, marginBottom: -3, marginRight: 8}} />
            Pacientes
          </h1>
          <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
            <Input
                styleContainer={{
                  display: 'flex',
                  // flex: 1,
                  justifyContent: 'flex-end',
                  marginRight: 12,
                }}
                boldPlaceholder={true}
                style={{
                  minWidth: 350,
                  backgroundColor: '#E7E7E7',
                  // borderRadius: 25,
                  height: 32,
                  paddingLeft: 35,
                  paddingRight: 15,
                  // paddingTop: 0,
                  boxShadow: 'none',
                  // boxShadow: '0 1px 1px 0 rgba(75, 75, 75, 0.3)',
                }}
                styleImage={{
                  width: 16,
                  height: 16,
                  marginLeft: 5,
                  marginTop: 1,
                }}
                placeholder={"Busque pelo nome"}
                name={"search"}
                type={"text"}
                image={Search}
                value={this.state.search}
                onChange={this.onChangeSearch}
            />
            <Button
                text={""}
                icon={mdiDotsVertical}
                iconSize={1}
                iconColor={"#fff"}
                onClick={() => {
                  this.setState({
                    modalVisible: true
                  });
                }}
                style={{
                  backgroundColor: "#B1B1B1",
                  minWidth: 32,
                  fontSize: 28,
                  padding: 0,
                  paddingTop: 0,
                  height: 32,
                  minHeight: 32,
                  borderRadius: 8,
                  boxShadow: 'none'
                }}
            />
          </div>
        </div>

        <div style={{marginTop: 40, marginBottom: 40}}>
          {/*<div style={{}}>*/}
          {/*  <h6 style={{marginBottom: 5, fontWeight: 'bold', fontSize: 14, color: '#666'}}>DATA DO AGENDAMENTO</h6>*/}

          {/*  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>*/}
          {/*    <div>{this.renderSelectionValue()}</div>*/}

          {/*    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>*/}

          {/*      {this.state.isOpen && (*/}
          {/*        <DateRangePicker*/}
          {/*          value={this.state.range}*/}
          {/*          onSelect={this.handleChangeEndDate}*/}
          {/*          singleDateRange={true}*/}
          {/*          onSelectStart={this.handleChangeStartDate}*/}
          {/*        />*/}
          {/*      )}*/}

          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <div style={{marginRight: 20}}>
              <h6 style={{marginBottom: 5, fontWeight: 'bold', fontSize: 14, color: '#666'}}>STATUS</h6>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <Badge
                  style={{
                    ...this.state.selectedStatusFilter === "reserved" ? {backgroundColor: "#28A745", borderColor: "#28A745"} : {}
                  }}
                  styleText={{
                    ...this.state.selectedStatusFilter === "reserved" ? {color: "#fff"} : {}
                  }}
                  onClick={() => {
                    this.setState({
                      selectedStatusFilter: "reserved"
                    }, () => {
                      this.fetchPatients();
                    });
                  }}
                >Ativo</Badge>
                <Badge
                  style={{
                    ...this.state.selectedStatusFilter === "canceled" ? {backgroundColor: "#666", borderColor: "#666"} : {}
                  }}
                  styleText={{
                    ...this.state.selectedStatusFilter === "canceled" ? {color: "#fff"} : {}
                  }}
                  onClick={() => {
                    this.setState({
                      selectedStatusFilter: "canceled"
                    }, () => {
                      this.fetchPatients();
                    });
                  }}
                >Cancelado</Badge>
              </div>
            </div>
          </div>

          <div style={{display: 'block', marginTop: 20}}>
            <table style={{textAlign: 'left'}}>
              <thead>
              <tr>
                <th>
                  <div>
                    <Icon
                      path={mdiClipboardAccount}
                      title="Nome"
                      size={0.7}
                      color="#666"
                    />
                    <span>Nome</span>
                  </div>
                </th>
                <th>
                  <div>
                    <Icon
                      path={mdiCakeVariant}
                      title="Idade"
                      size={0.7}
                      color="#666"
                    />
                    <span>Idade</span>
                  </div>
                </th>
                <th style={{maxWidth: 100}}>
                  <div>
                    <Icon
                      path={mdiGenderMaleFemale}
                      title="Sexo"
                      size={0.7}
                      color="#666"
                    />
                    <span>Sexo</span>
                  </div>
                </th>
                <th>
                  <div>
                    <Icon
                        path={mdiCheckboxMarked}
                        title="Status"
                        size={0.7}
                        color="#666"
                    />
                    <span>Status</span>
                  </div>
                </th>
              </tr>
              </thead>
              <tbody>
              {this.state.filteredPatients.map((item, index) => {
                return(
                  <tr key={index} onClick={() => {this.props.history.push("/paciente/"+item.patient.id)}} style={{cursor: 'pointer'}}>
                    <td>
                      {item.patient.name}
                    </td>
                    <td>
                      {this.displayAge(item.patient.birthdate)}
                    </td>
                    <td>
                      {item.patient.gender === 'M' ? 'Masculino' : 'Feminino'}
                    </td>
                    <td
                      style={{
                        fontWeight: 'bold',
                        ...item.status === "reserved" ? {color: '#28A745'} : {}
                      }}
                    >{dataUtils.getPatientStatusText(item.status)}</td>
                    <td>
                    </td>
                  </tr>
                )
              })}
              {!this.state.loading && this.state.patients.length === 0 && <tr>
                <td colSpan={6}>Sem pacientes cadastrados.</td>
              </tr>}
              {this.state.loading && <tr>
                <td colSpan={6}><Loader/></td>
              </tr>}
              </tbody>
            </table>
          </div>
        </div>
      </Layout>
    );
  }
}

export default connect(mapStateToProps)(withRouter(Patients));
