import React, { Component } from 'react';
import {logoutButton} from "./LogoutButton.module.scss";
import {Link} from "react-router-dom";
import {ReactComponent as LogoutIcon} from './logout.svg'

class LogoutButton extends Component {
    render() {
        return (
            <Link className={logoutButton} to="/logout">
                <LogoutIcon />
            </Link>
        );
    }
}

export default LogoutButton;


