import React, { Component } from 'react';
import {mdiCalendarRange, mdiMonitorDashboard} from "@mdi/js";
import Icon from "@mdi/react";
import {Link} from "react-router-dom";

import Logo from "../images/logo.svg";
import {connect} from "react-redux";
import ProfileButton from './ProfileButton';
import LogoutButton from './LogoutButton';
import SpinnerWhite from "../images/spinner_white.svg";
import Dashboard from "../images/dashboard.png";
import UserIcon from "../images/user-icon.png";
import Professional from "../images/professional.svg";
import {withRouter} from "react-router";


const mapStateToProps = store => ({
    isLogged: store.authReducer.isLogged,
    isLoaded: store.authReducer.isLoaded,
    isLoading: store.authReducer.isLoading,
    loadError: store.authReducer.loadError,
    user: store.authReducer.user,
    callStatus: store.videoCallReducer.status,
    callToken: store.videoCallReducer.token,
    roomName: store.videoCallReducer.roomName,
    roomId: store.videoCallReducer.roomId,
});

class Sidebar extends Component {
    render() {
        // console.log("location", this.props.location);

        return (
            <div className="side-bar">
                <div style={{marginTop: 0, marginLeft: 30, marginBottom: 40, position: 'fixed'}}>
                    <img src={Logo} alt={"Logo"}  style={{width: 50, height: 'auto'}}/>
                </div>

                {this.props.isLogged && <div style={{position: 'fixed', top: 140, width: 360}}>
                    {this.props.user.type !== "admin" && <Link to="/">
                        <div
                            style={{
                                backgroundColor: this.props.location.pathname === '/' ?'rgba(255,255,255,0.4)' : 'transparent',
                                padding: '15px 30px',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <img src={Dashboard} alt={"Dashboard"} style={{width: 28, height: 'auto'}}/>
                            <span style={{marginLeft: 10, fontWeight: 'bold', fontSize: 18, lineHeight: '26px'}}>Dashboard</span>
                        </div>
                    </Link>}
                    {this.props.user.type !== "admin" && <Link to="/agendamento">
                        <div
                            style={{
                                backgroundColor: this.props.location.pathname === '/agendamento' ?'rgba(255,255,255,0.4)' : 'transparent',
                                padding: '15px 30px',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Icon
                                path={mdiCalendarRange}
                                title="Agenda"
                                size={1.2}
                                color="#1f295c"
                                style={{ borderRadius: 5}}
                            />
                            <span style={{marginLeft: 10, fontWeight: 'bold', fontSize: 18, lineHeight: '26px'}}>Agenda</span>
                        </div>
                    </Link>}
                    {false && this.props.user.type === "admin" && <Link to="/">
                        <div
                            className={'menu-option'}
                            style={{
                                backgroundColor: this.props.location.pathname === '/' ?'rgba(255,255,255,0.4)' : 'transparent',
                                padding: '15px 30px',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {/*<Icon*/}
                            {/*    path={mdiMonitorDashboard}*/}
                            {/*    title="Agenda"*/}
                            {/*    size={1.2}*/}
                            {/*    color="#1f295c"*/}
                            {/*    style={{ borderRadius: 5}}*/}
                            {/*/>*/}
                            <img src={Dashboard} alt={"Dashboard"} style={{width: 28, height: 'auto'}}/>
                            <span style={{marginLeft: 10, fontWeight: 'bold', fontSize: 18, lineHeight: '26px'}}>Dashboard</span>
                        </div>
                    </Link>}
                    {this.props.user.type === "admin" && <Link to="/profissionais">
                        <div className={'menu-option'}
                            style={{
                                backgroundColor: (this.props.location.pathname === '/profissionais' || this.props.location.pathname.startsWith('/profissional')) ? 'rgba(255,255,255,0.4)' : 'transparent',
                                padding: '15px 30px',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {/*<Icon*/}
                            {/*    path={mdiCalendarRange}*/}
                            {/*    title="Profissionais"*/}
                            {/*    size={1.2}*/}
                            {/*    color="#1f295c"*/}
                            {/*    style={{ borderRadius: 5}}*/}
                            {/*/>*/}
                            <img alt={"Professional"} src={Professional} style={{width: 26}} />
                            <span style={{marginLeft: 10, fontWeight: 'bold', fontSize: 18, lineHeight: '26px'}}>Profissionais</span>
                        </div>
                    </Link>}
                    {this.props.user.type !== "admin" && <Link to="/pacientes">
                        <div
                            style={{
                                backgroundColor: (this.props.location.pathname === '/pacientes' || this.props.location.pathname.startsWith('/pacientes')) ? 'rgba(255,255,255,0.4)' : 'transparent',
                                padding: '15px 30px',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <img alt={"Pacientes"} src={UserIcon} style={{width: 26}} />
                            <span style={{marginLeft: 10, fontWeight: 'bold', fontSize: 18, lineHeight: '26px'}}>Pacientes</span>
                        </div>
                    </Link>}
                </div>}
                {this.props.isLogged && <ProfileButton />}
                {this.props.isLogged && <LogoutButton />}

                {!this.props.isLogged && <div style={{padding: 30, textAlign: 'right'}}>
                    <h3 style={{marginBottom: 20, color: "#9C0D4F"}}>{this.props.title}</h3>
                    <p style={{fontWeight: 600, color: "#808080"}}>{this.props.description}</p>
                </div>}
            </div>
       );
    }
}

export default connect(mapStateToProps)(withRouter(Sidebar));
