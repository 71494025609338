import requestUtils from '../utils/request';
import querystring from 'querystring';
import apiConfig from "../configs/api";

export default {
    fetch(alias, idSchedule, token, filters = {}, filterStatus) {
        return requestUtils({
            url: '/v1/appointments/'+alias+'/'+idSchedule + '?' + querystring.stringify(filters)+(filterStatus && filterStatus !== "" && filterStatus.length > 0 ? "&status="+filterStatus : ""),
            method: 'GET',
            token,
        });
    },
    get(alias, idSchedule, idAppointment, token) {
        return requestUtils({
            url: '/v1/appointment/'+alias+'/'+idSchedule+'/'+idAppointment,
            method: 'GET',
            token,
        });
    },
    getPatientAppointment(id, token) {
        return requestUtils({
            url: apiConfig.API+'/appointment?patient.id='+id,
            method: 'GET',
            token,
        });
    },
    getPatientAppointmentFiltered(id, token, filters = {}) {
        return requestUtils({
            url: apiConfig.API+'/appointment?patient.id=' + id + '&' + querystring.stringify(filters),
            method: 'GET',
            token,
        });
    },
    setAvailability(id, body, token) {
        return requestUtils({
            url: apiConfig.API+'/professional/'+id+'/availability',
            method: 'POST',
            token,
            data: body,
        });
    },
    updateAvailability(id, body, token) {
        return requestUtils({
            url: apiConfig.API+'/professional/'+id+'/availability/'+body.id,
            method: 'PUT',
            token,
            data: body,
        });
    },
    createAvailability(id, body, token) {
        return requestUtils({
            url: apiConfig.API+'/professional/'+id+'/availability',
            method: 'POST',
            token,
            data: body,
        });
    },
    removeAvailability(id, idAvailability, token) {
        return requestUtils({
            url: apiConfig.API+'/professional/'+id+'/availability/'+idAvailability,
            method: 'DELETE',
            token
        });
    },
    getAvailability(id, token) {
        return requestUtils({
            url: apiConfig.API+'/professional/'+id+'/availability',
            method: 'GET',
            token,
        });
    },
    getProfessionalReservations(id, token, filterStatus) {
        return requestUtils({
            url: apiConfig.API+'/reservation?professionalID='+id+(filterStatus ? "&status="+filterStatus : ""),
            method: 'GET',
            token,
        });
    },
    getProfessionalAppointments(condition, token, filterStatus) {
        return requestUtils({
            url: apiConfig.API+'/appointment?professionalID='+condition+(filterStatus ? "&status="+filterStatus : ""),
            method: 'GET',
            token,
        });
    },
    getPatientByReservation(id, token) {
        return requestUtils({
            url: apiConfig.API+'/reservation?patient.id='+id,
            method: 'GET',
            token,
        });
    },
};
