import requestUtils from '../utils/request';

import apiConfig from '../configs/api';

export default {
    async fetch(token, name) {
        const response = await requestUtils({
            url: `${apiConfig.API}/professional-category${name ? '?name='+name : ''}`,
            method: 'GET',
            token,
        });
        return response.data
    },
    async associate(
      token,
      professionalId,
      categoryId
    ) {
      const response = await requestUtils({
          url: `${apiConfig.API}/professional/${professionalId}/category/associate/${categoryId}`,
          method: 'POST',
          token,
      });
      return response.data
    },
    async desassociate(
      token,
      professionalId,
      categoryId
    ) {
      const response = await requestUtils({
          url: `${apiConfig.API}/professional/${professionalId}/category/desassociate/${categoryId}`,
          method: 'POST',
          token,
      });
      return response.data
    }
};
