import React, {Component} from 'react';

import Layout from './Layout'
import BirthdateField from './BirthdateField';
import GenderField from './GenderField';
import AppointmentWidget from './AppointmentWidget';
import DocumentWidget from './DocumentWidget';

import {withRouter} from "react-router";
import {connect} from "react-redux";
import { Link } from "react-router-dom";

import { actionCreators as actionCreatorsCall } from "../reducers/video-call";
import scheduleResource from "../resources/schedule";
import Loader from "./Loader";
import scheduleUtils from "../utils/schedule";
import chatResource from "../resources/chat";

import 'react-calendar/dist/Calendar.css';
import '../styles/notFound.scss';

const mapStateToProps = store => ({
    isLogged: store.authReducer.isLogged,
    isLoaded: store.authReducer.isLoaded,
    isLoading: store.authReducer.isLoading,
    loadError: store.authReducer.loadError,
    user: store.authReducer.user,
    token: store.authReducer.token,
    professional: store.authReducer.professional,
    callStatus: store.videoCallReducer.status,
    callToken: store.videoCallReducer.token,
    roomName: store.videoCallReducer.roomName,
    roomId: store.videoCallReducer.roomId,
});

class ScheduleDetail extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            redirect: false,
            redirectTo: '',
            creating: false,
            loading: !props.appointment,
            appointment: props.appointment || null
        };
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        if(!this.state.appointment){
            this.getSchedule(params.id);
        }
    }

    getSchedule = async (id) => {
        try {
            this.setState({
                loading: true,
            });

            let response = await scheduleResource.get(
              scheduleUtils.getAlias(this.props.professional.type),
              this.props.professional.schedule_id,
              id,
              this.props.token
            );

            if (!response.data.success) {
                this.setState({
                    redirect: true,
                    redirectTo: '/404',
                });
                return;
            }

            this.setState({
                loading: false,
                appointment: response.data.appointment
            });

        } catch(err){
            console.log(err);
            this.setState({
                loading: false,
            });
        }
    };

    onCall = () => {
        const { match: { params } } = this.props;

        if (this.props.callStatus === 'idle') {
            this.props.dispatch(
              actionCreatorsCall.callStart(
                params.id,
                this.props.user._id,
                this.state.appointment.patient.id,
                this.state.appointment.patient
              )
            );
        } else {
            this.props.dispatch(actionCreatorsCall.callCancel());
        }
    };

    createChatList = async () => {

        try {
            this.setState({
                creating: true,
            });

            let response = await chatResource.createConversation(
              this.props.user._id,
              this.props.user.name,
              this.state.appointment.patient.id,
              this.state.appointment.patient.name,
              this.props.token
            );

            if (!response.data.success) {
                throw new Error(response.data.message);
            }

            this.setState({
                creating: false
            });

            this.props.history.push({
                pathname: '/chat',
                // params: response.data.conversation,
                state: {
                    conversation: response.data.conversation
                }
            });
        } catch(err){
            console.log(err);

            this.setState({
                creating: false,
            });

            alert(err.message);
        }
    };

    render(){
        if (this.state.redirect) {
            return <Layout>
                <div id="notFound">
                    <h1>
                        404
                        <aside>Você tentou acessar um agendamento que não existe.</aside>
                    </h1>
                    <p>Volte para sua <Link to="/">agenda</Link>.</p>
                </div>
            </Layout>
        }
        return (
            <Layout>
                <div>
                    <div>
                        {this.state.loading && <div style={{marginTop: 40}}>
                            <Loader/>
                        </div>}
                        {!this.state.loading && <div>
                            <h1 style={{fontWeight: 'bold', textAlign: 'left', marginBottom: 0}}>
                                {this.state.appointment.patient.name}
                            </h1>

                            <div style={{display: 'flex', paddingTop: 12, paddingBottom: 12}}>
                                <GenderField gender={this.state.appointment.patient.gender}/>
                                <BirthdateField birthdate={this.state.appointment.patient.birthdate}/>
                            </div>
                             <div style={{display: 'flex'}}>
                                {this.props.professional.type !== 'psychologist' && <div style={{display: 'block', width: '100%', marginRight: 24}}>
                                    <DocumentWidget patientId={this.state.appointment.patient.id}/>
                                </div>}
                                <div style={{display: 'block', width: '100%'}}>
                                    <AppointmentWidget appointment={this.state.appointment}/>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </Layout>
        )
    }
}

export default connect(mapStateToProps)(withRouter(ScheduleDetail));
