import React from 'react';

class Badge extends React.Component {
    render() {
        return (
            <button
                onClick={this.props.onClick}
                style={{
                    // flex: 0,
                    // flexDirection: 'row',
                    outline: "none",
                    margin: 0,
                    shadow: "none",
                    padding: 5,
                    paddingLeft: 12,
                    paddingRight: 12,
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderRadius: 20,
                    marginRight: 9,
                    marginBottom: 9,
                    cursor: "pointer",
                    borderColor: "#666",
                    backgroundColor: "#fff",
                    ...this.props.style
                }}
            >
                <p style={{color: '#666', fontSize: 12, fontWeight: 'bold', lineHeight: 1, ...this.props.styleText}}>{this.props.children}</p>
            </button>
        );
    }
}

export default Badge;
