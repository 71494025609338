import apiConfig from '../configs/api'
import requestUtils from "../utils/request";

export default {
    fetch(userFrom, userTo, timestamp, token) {
        return requestUtils({
            url: `${apiConfig.CHAT_API}/api/v1/messages/${userFrom}/${userTo}/${timestamp}`,
            method: 'GET',
            token,
            // data: {
            //     type,
            //     name,
            //     registry
            // },
        });
    },
    create(body, token) {
        return requestUtils({
            url: `${apiConfig.CHAT_API}/api/v1/message`,
            method: 'POST',
            token,
            data: body
        });
    },
    read(idMessage, token) {
        return requestUtils({
            url: `${apiConfig.CHAT_API}/api/v1/message/${idMessage}`,
            method: 'PUT',
            token,
            // data: {}
        });
    },
    readArray(idMessages, token) {
        return requestUtils({
            url: `${apiConfig.CHAT_API}/api/v1/message`,
            method: 'PUT',
            token,
            data: {
                ids: idMessages
            }
        });
    },
    fetchConversations(user, token) {
        return requestUtils({
            url: `${apiConfig.CHAT_API}/api/v1/conversations/${user}`,
            method: 'GET',
            token,
        });
    },
    createConversation(userFrom, userFromName, userTo, userToName, token) {
        return requestUtils({
            url: `${apiConfig.CHAT_API}/api/v1/conversations`,
            method: 'POST',
            token,
            data: {
                userFrom,
                userFromName,
                userTo,
                userToName
            }
        });
    },
    upload(file, token) {
        let body = new FormData();
        body.append('file', file);

        return requestUtils({
            url: `${apiConfig.CHAT_API}/api/v1/upload`,
            method: 'POST',
            data: body,
            token: token,
            header: {
                'Content-Type': 'multipart/form-data',
            },
            // onUploadProgress: (progressEvent) => {
            //     let progress = Math.round(progressEvent.loaded * 100/progressEvent.total);
            //     console.log("upload progress", progress);
            //
            //     this.setState({
            //         progress
            //     });
            // },
        });
    },
    getSignedUrl(key, token) {
        return requestUtils({
            url: `${apiConfig.CHAT_API}/api/v1/file/url/${key}`,
            method: 'GET',
            token,
        });
    },
};
