import React, { Component, createRef } from 'react';
import {mdiCalendarRange, mdiCamera, mdiClose, mdiFile, mdiUpload} from '@mdi/js';
import { Icon } from '@mdi/react';
import Modal from 'react-modal';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Button from "./Button";
import professionalResource from "../resources/professional";
import dataUtils from "../utils/data";
import Upload from "../images/upload.png";
import CloudUpload from "../images/cloud-upload.png";
import Dropzone from "react-dropzone";
import {ReactComponent as UploadIcon} from "./CreateDocumentModal/upload.svg";

const dropzoneRef = createRef();


Modal.defaultStyles.content = {
    ...Modal.defaultStyles.content,
    // right: 'calc(50% - 405px)',
    // left: 'calc(50% - 405px)',
    // top: 'calc(50% - 405px)',
    // bottom: 'unset',
    // top: 0,
    // bottom: 0,
    // left: 0,
    // right: 0,
    // maxWidth: 810,
    // width: 810,
    // minWidth: 810,
    // padding: 24,
    // borderRadius: 8,
    // background: '#fff',
    // borderColor: '#e7e7e7',
    // height: 'auto',
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
};

Modal.defaultStyles.overlay = {
    ...Modal.defaultStyles.overlay,
    backgroundColor: "rgba(55,55,55, 0.7)"
};

class EditImageModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            maxHeight: 200,
            src: null,
            // crop: {
            //     unit: '%',
            //     width: 35,
            //     height: 35,
            //     // x: 100,
            //     // y: 100,
            //     // aspect: 16 / 9,
            //     // aspect: 3 / 2,
            // },
            crop: {
                unit: '%',
                // width: 35,
                // // height: 35,
                // unit: '%',
                width: 50,
                height: 50,
                x: 25,
                y: 25,
                // aspect: 1/1   //16:3
                // aspect: 16 / 9
            },
        };
    }

    componentDidMount() {
        const maxHeight = document.getElementById('root').clientHeight;
        this.setState({
            maxHeight: maxHeight - 80
        });
    }

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;

        let minSize = Math.min(image.width, image.height);

        const aspect = 1;

        this.setState({
            crop: image.height*2 > image.width ? {
                unit: '%',
                width: 50,
                // height,
                x: 25,
                y: 25,
                aspect: 1,
            } : {
                unit: 'px',
                width: image.width / 2,
                x: (image.width / 2) - (image.width / 5),//(100 * ((image.width / 2) - (image.width / 2))) / image.width,
                y: image.width / 2 > image.height ? 0 : image.height / 4,
                aspect: 1,
            }
        });

        return false; // Return false when setting crop state in here.
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop: crop });

        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
    };

    async makeClientCrop(crop) {
        // console.log("makeClientCrop", crop, this.imageRef);
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageResult = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );

            // console.log("croppedImageResult", croppedImageResult);

            this.setState({
                croppedImageUrl: croppedImageResult.url,
                croppedImageBlob: croppedImageResult.blob,
            });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                console.log("blob", blob);

                this.setState({
                    croppedImage: blob
                });

                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve({
                    url: this.fileUrl,
                    blob: blob,
                });
            }, 'image/jpeg');
        });
    }

    onDrop = (acceptedFiles) => {
        if (!acceptedFiles.length) {
            return;
        }

        const file = acceptedFiles[0];
        // this.setState({
        //     file: file
        // });

        const reader = new FileReader();
        reader.addEventListener('load', () =>
            this.setState({ src: reader.result })
        );
        reader.readAsDataURL(file);
    }

    render() {
        return (
            <Modal
                isOpen={this.props.visible}
                onRequestClose={() => {
                    this.props.onCloseRequest(null);
                }}
                contentLabel={'Editar foto de perfil'}
                style={{
                    content: {
                        width: '80%',
                        height: 'calc(100% - 80px)',
                    }
                }}
            >
                <div style={{
                    // maxHeight: this.state.maxHeight,
                    height: '100%',
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    cursor: 'pointer'}}
                >
                    <span style={{flex: 0, position: 'absolute', right: 6, top: 6, cursor: 'pointer'}} onClick={() => {this.props.onCloseRequest(null)}}>
                        <Icon path={mdiClose} title="Fechar" size={1} color={'#6F6F6F'}/>
                    </span>

                    <div style={{display: 'flex', flexDirection: 'column', flex: 1,}}>
                        <div style={{flex: 0, marginTop: 9, marginRight: 9}}>
                            <h2 style={{display: 'flex', fontWeight: 'bold', textAlign: 'left'}}>
                                <span style={{flex: 1,}}>Editar foto de perfil</span>
                                <button
                                    style={{
                                        // height: 160,
                                        // width: 160,
                                        outline: 'none',
                                        border: 'none',
                                        backgroundColor: '#B1B1B1',
                                        width: 32,
                                        height: 32,
                                        textAlign: 'center',
                                        borderRadius: '7px',
                                        cursor: 'pointer',
                                        position: 'relative'
                                    }}
                                    onClick={(event) => {
                                        // event.preventDefault();
                                        // this.fileInput.click();

                                        if (dropzoneRef.current) {
                                            dropzoneRef.current.open()
                                        }
                                    }}
                                >
                                    <img src={Upload} alt={"Upload"} style={{width: 22, height: 'auto', marginTop: 2}}/>
                                </button>
                            </h2>
                        </div>

                        {this.props.visible && <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', overflowY: 'scroll', maxHeight: this.state.maxHeight-175}}>
                            {/*{!this.state.src && <div style={{flex: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', boxShadow: '0 1px 6px rgba(0, 0, 0, 0.2)', borderRadius: 7, padding: 24, margin: 2, width: '100%', backgroundColor: '#fff'}}>*/}
                            {/*    <img src={CloudUpload} alt={"Upload"} style={{width: 100, height: 'auto', marginBottom: 12}}/>*/}
                            {/*    <p style={{color: "#aaa", fontWeight: '500'}}>Arraste uma foto para começar</p>*/}
                            {/*</div>}*/}

                            <div style={{flex: 0, display: this.state.src ? 'none' : 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', boxShadow: '0 1px 6px rgba(0, 0, 0, 0.2)', borderRadius: 7, width: '100%', backgroundColor: '#fff'}}>
                                <Dropzone ref={dropzoneRef} multiple={false} onDrop={this.onDrop} style={{display: 'flex'}}>
                                    {({getRootProps, getInputProps}) => (
                                        <div style={{width: '100%', padding: 24}} {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            {!this.state.src && <div style={{width: '100%', padding: 12, textAlign: 'center'}}>
                                                <img src={CloudUpload} alt={"Upload"} style={{width: 100, height: 'auto', marginBottom: 12}}/>
                                                <p style={{color: "#aaa", fontWeight: '500'}}>Arraste uma foto para começar</p>
                                            </div>}
                                        </div>
                                    )}
                                </Dropzone>
                            </div>

                            <div style={{display: 'block', marginTop: 12, maxWidth: 500}}>

                                {this.state.src && <ReactCrop
                                    src={this.state.src}
                                    crop={this.state.crop}
                                    ruleOfThirds
                                    onImageLoaded={this.onImageLoaded}
                                    onComplete={this.onCropComplete}
                                    onChange={this.onCropChange}
                                    circularCrop={true}
                                    keepSelection={true}
                                    // locked={true}
                                    // minWidth={512}
                                    // minHeight={512}
                                    // maxHeight={this.state.maxHeight}
                                    imageStyle={{
                                        maxHeight: this.state.maxHeight
                                    }}
                                />}

                                {/*<input*/}
                                {/*    style={{*/}
                                {/*        display: 'none',*/}
                                {/*        visibility: 'hidden',*/}
                                {/*    }}*/}
                                {/*    type="file"*/}
                                {/*    name="file"*/}
                                {/*    accept="image/*"*/}
                                {/*    ref={fileInput => this.fileInput = fileInput}*/}
                                {/*    onChange={this.onSelectFile}*/}
                                {/*/>*/}

                                {/*{this.state.croppedImageUrl && (*/}
                                {/*    <img alt="Crop" style={{ maxWidth: '100%' }} src={this.state.croppedImageUrl} />*/}
                                {/*)}*/}
                            </div>
                        </div>}
                    </div>

                    {this.state.src && <div style={{flex: 0, display: 'flex', justifyContent: 'flex-end', marginTop: 20, marginBottom: 0}}>
                        <Button
                            text="SALVAR"
                            onClick={() => {
                                this.props.onCloseRequest(this.state.croppedImageUrl, this.state.croppedImageBlob);
                            }}
                        />
                    </div>}
                </div>
            </Modal>
        );
    }
}

export default EditImageModal;
