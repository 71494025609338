export const types = {
  SET_UNAVAILABILITIES: 'SET_UNAVAILABILITIES',
  PUSH_UNAVAILABILITIES: 'PUSH_UNAVAILABILITIES',
};

const initialState = {
  unavailabilities: []
};

export const actions = {
  set: (payload) => async (dispatch, f) => {
      dispatch({
          type: types.SET_UNAVAILABILITIES,
          payload
      });
  },
  push: (payload) => async (dispatch, f) => {
    dispatch({
        type: types.PUSH_UNAVAILABILITIES,
        payload
    });
}
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
      case types.SET_UNAVAILABILITIES: {
          return {
              ...state,
              unavailabilities: payload,
          };
      }
      case types.PUSH_UNAVAILABILITIES: {
        return {
            ...state,
            unavailabilities: [
              ...state.unavailabilities,
              payload
            ],
        };
      }
      default: return state;
  }
}
