import '../../styles/room.scss'

import React, {useState, useEffect} from 'react';
import callResource from "../../resources/call";
import Video from 'twilio-video';
import Participant from '../Participant';
import GenderField from '../GenderField';
import BirthdateField from '../BirthdateField';
import DocumentWidget from '../DocumentWidget';
import { white, documents } from './VideoRoom.module.scss';

import {
    mdiPhoneHangup,
    mdiVideo,
    mdiVideoOff,
    mdiMicrophoneOff,
    mdiMicrophone,
} from '@mdi/js'
import Icon from "@mdi/react";
import LogoWhite from '../../images/logo-white.svg'

const VideoRoom = ({
  endCall,
  callStatus,
  patientInfo,
  callInfo
}) => {
  const [room, setRoom] = useState(null);
  const [callId, setCallId] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [audioEnabled, setAudioEnabled] = useState([true]);
  const [videoEnabled, setVideoEnabled] = useState([true]);

  const trackpubsToTracks = trackMap => Array.from(trackMap.values())
    .map(publication => publication.track)
    .filter(track => track !== null);

  const toogleAudio = (e) => {
      e.preventDefault();

      trackpubsToTracks(room.localParticipant.audioTracks)
      .forEach(track => {
        audioEnabled ? track.disable() : track.enable();
      });
      setAudioEnabled(!audioEnabled);
    }
  const toogleVideo = (e) => {
      e.preventDefault();
      trackpubsToTracks(room.localParticipant.videoTracks)
      .forEach(track => {
        videoEnabled ? track.disable() : track.enable();
      });
      setVideoEnabled(!videoEnabled);
  };

  async function fetchContent() {
    const roomData = await callResource.callStart(
      callInfo.idAppointment,
      callInfo.idUserCaller,
      callInfo.idUserReceiver,
      'video',
      callInfo.userName,
      callInfo.professionalRegistry,
      callInfo.token
    );

    if(!roomData.data.success){
      throw new Error(roomData.data.message);
    }

    return {
      roomName: roomData.data.roomName,
      token: roomData.data.token,
      callId: roomData.data.callId,
    };
  }

  if (patientInfo === null) {
    patientInfo = {name: "", birthdate: "", gender: "", id: 0};
  }

  const onEndCall = () => {
    if (room) {
      room
        .localParticipant
        .tracks
        .forEach(function (trackPublication) {
          console.log("track", trackPublication);
          trackPublication.track.stop();
      });
      room.disconnect();

    }

    endCall(callId);
  }

  useEffect(() => {
    const participantConnected = participant => {
        setParticipants(prevParticipants => [...prevParticipants, participant]);
    };

    const participantDisconnected = participant => {
        setParticipants(prevParticipants =>
            prevParticipants.filter(p => p !== participant)
        );
    };
    
    window.onpopstate = () => {
      console.log('[Room] - cancelando chamada');
      onEndCall();
    };

    const roomData = fetchContent();

    roomData.then((roomData) => {

      setCallId(roomData.callId);

      Video.connect(roomData.token, {
        name: roomData.roomName
      }).then(room => {
        setRoom(room);
        room.on('participantConnected', participantConnected);
        room.on('participantDisconnected', participantDisconnected);
        room.participants.forEach(participantConnected);
      });
    })
    .catch((reason) => {
      console.log("Video nao carregado: " + reason);
      alert("Vídeo não pode ser carregado, tente novamente.");
    });

    return () => {
        setRoom(currentRoom => {
            if (!(currentRoom && currentRoom.localParticipant.state === 'connected')) {
                return currentRoom;
            }

            currentRoom
              .localParticipant
              .tracks
              .forEach(function (trackPublication) {
                console.log("track", trackPublication);
                trackPublication.track.stop();
            });

            currentRoom.disconnect();
            return null;
        });
    };
  }, []);

    const remoteParticipants = participants.reverse().map(participant => (
        <Participant
          key={participant.sid}
          participant={participant}
          isRemote={true}
          identity={patientInfo ? patientInfo.name : ''}
        />
    ));

    const callMessageStatus = () => {
      if (!["calling","rejected", "ended"].includes(callStatus)) {
        return "";
      }

      const message = {
        'calling': 'Chamando...',
        'ended': 'Chamada finalizada.',
        'rejected': 'Chamada rejeitada.',
      };

      return <span style={{
            color: 'white',
            zIndex: 1,
            position: 'absolute',
            top: '50%',
            width: '100%',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '15pt',
          }}>{message[callStatus]}</span>
    };

    const callMessage = callMessageStatus();

    return (
        <div className="room">
            <div className="sidebar">
                <img
                    className={"icon-shadow"}
                    alt={"Logo"}
                    src={LogoWhite}
                    style={{width: 50, height: 'auto'}}
                />
                <div className="info">
                  <div className={white}>
                      <h2 style={{fontWeight: 'bold', textAlign: 'left', paddingTop: 12}}>
                        {patientInfo.name}
                      </h2>
                  </div>
                  <div >
                      <GenderField
                          gender={patientInfo.gender}
                          className={white}
                          iconColor={'#fff'}/>
                  </div>
                  <div>
                      <BirthdateField
                          birthdate={patientInfo.birthdate}
                          className={white}
                          iconColor={'#fff'}
                      />
                  </div>
                </div>
                {(callInfo && callInfo.professionalType !== 'psychologist') ? <div style={{width: '100%'}}>
                    <DocumentWidget
                        className={`${white}
                        ${documents}`}
                        patientId={patientInfo.id}
                    />
                </div> : ''}
            </div>

            {(room) ? (
              <>
                <div className={'remote-participant'}>
                  {['calling', 'rejected'].includes(callStatus) && callMessage}
                </div>
                <div className={'local-participant'}>
                    <Participant
                        key={room.localParticipant.sid}
                        participant={room.localParticipant}
                        isRemote={true}
                    />
                </div>
              </>
            ) : ''}

            {room && participants.length ? (
                <div className="remote-participants">
                  {['ended'].includes(callStatus) && callMessage}
                  {!['ended'].includes(callStatus) && remoteParticipants}
                </div>
            ) : ''}

            <button className={"main-button end-call-button"} onClick={onEndCall}>
                <Icon
                    path={mdiPhoneHangup}
                    title="Desligar"
                    size={1.5}
                    color="#fff"
                />
            </button>
            <button className={"main-button video-control-button"} onClick={toogleVideo}>
                <Icon
                    path={videoEnabled ? mdiVideo : mdiVideoOff}
                    title="Video"
                    size={1.5}
                    color="#373737"
                />
            </button>
            <button className={"main-button audio-control-button"} onClick={toogleAudio}>
                <Icon
                    path={audioEnabled ? mdiMicrophone : mdiMicrophoneOff}
                    title="Audio"
                    size={1.5}
                    color="#373737"
                    style={{marginLeft: -1}}
                />
            </button>
        </div>
    );
};

export default VideoRoom;
