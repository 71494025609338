import io from 'socket.io-client';

import {actionCreators as actionCreatorsCall} from '../reducers/video-call';
import {actionCreators as actionCreatorsChat} from '../reducers/chat';

import apiConfig from './api'

class SocketAPI {
    constructor() {

        this.socket = null;
        this._dispatch = null;

        // this.connect();
    }

    set dispatch(dispatch) {
        this._dispatch = dispatch;
    }

    connect(token) {
        console.log('Socket connecting...');

        this.socket = io(apiConfig.SOCKET_URL, {
            timeout: 10000,
            autoConnect: true,
            reconnection: true,
            query: { token },    //JWT token here
            transports: ['websocket'],
            jsonp: false
        });

        this.socket.on('connect', async () => {
            console.log('Socket connected!', this.socket.id);

            // this.join("teste");

            // await this._removeSocketListeners();
            this._startSocketListeners();

            // setTimeout(() => {
            //     this.emit("announcements", {room : "teste", msg: "teste mensagem"});
            // }, 2000);
        });
    }

    async disconnect() {
        console.log('Socket disconnect');

        // this.leave("teste");

        await this._removeSocketListeners();

        this.socket.disconnect();
        this.socket = null;
    }

    emit(event, data) {
        console.log('emit ', event, data);

        this.socket.emit(event, data);
    }

    join(room) {
        console.log('join ', room);

        this.emit('join', room);
    }

    leave(room) {
        console.log('Leavining ', room);

        this.emit('leave', room);
    }

    _startSocketListeners() {
        console.log('Starting socket listeners');

        // this.socket.on('announcements', (data) => {
        //     console.log("announcements", data);
        // });

        this.socket.on('call-start', (data) => {
            console.log('Call Start', data);

            this._dispatch(actionCreatorsCall.onCallReceive(data));
        });

        this.socket.on('call-accept', (data) => {
            console.log('Call Accept', data);

            this._dispatch(actionCreatorsCall.onCallAccept(data));
        });

        this.socket.on('call-end', (data) => {
            console.log('Call End', data);

            this._dispatch(actionCreatorsCall.onCallEnd(data));
        });

        this.socket.on('call-cancel', (data) => {
            console.log('Call Cancel', data);

            this._dispatch(actionCreatorsCall.onCallCancel(data));
        });

        this.socket.on('call-reject', (data) => {
            console.log('Call Reject', data);

            this._dispatch(actionCreatorsCall.onCallReject(data));
        });

        this.socket.on('call-expire', (data) => {
            console.log('Call Expire', data);

            this._dispatch(actionCreatorsCall.onCallExpire(data));
        });

        this.socket.on('disconnect', () => {
            console.log('Socket has isconnected!');
        });

        this.socket.on('reconnect', () => {
            console.log('Socket has reconnect!', this.socket);
        });

        this.socket.on('message', (data) => {
            console.log('New message', data);

            this._dispatch(actionCreatorsChat.onMessageReceive(data));
        });

        this.socket.on('message-read', (data) => {
            console.log('Message read', data);

            this._dispatch(actionCreatorsChat.onMessageRead(data));
        });
    }

    async _removeSocketListeners() {
        console.log("Remove socket listeners");

        await this.socket.off('reconnect');
        await this.socket.off('disconnect');
        await this.socket.off('call-start');
        await this.socket.off('call-accept');
        await this.socket.off('call-end');
        await this.socket.off('call-cancel');
        await this.socket.off('call-reject');
        await this.socket.off('call-expire');
    }
}

export const socketAPI = new SocketAPI();
