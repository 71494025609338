import React, {Component} from 'react';

import Layout from '../../components/Layout'
import BirthdateField from '../../components/BirthdateField';
import GenderField from '../../components/GenderField';
import AppointmentWidget from '../../components/AppointmentWidget';
import DocumentWidget from '../../components/DocumentWidget';

import {withRouter} from "react-router";
import {connect} from "react-redux";
import { Link } from "react-router-dom";

import { actionCreators as actionCreatorsCall } from "../../reducers/video-call";
import scheduleResource from "../../resources/schedule";
import professionalResource from "../../resources/professional";
import Loader from "../../components/Loader";
import scheduleUtils from "../../utils/schedule";
import chatResource from "../../resources/chat";
import dataUtils from "../../utils/data";

import 'react-calendar/dist/Calendar.css';
import '../../styles/notFound.scss';
import Icon from "@mdi/react";
import {
    mdiBottleTonicPlus,
    mdiContentCopy,
    mdiDotsHorizontal,
    mdiPencil,
    mdiPound,
    mdiSquareEditOutline
} from "@mdi/js";
import Moment from "moment";
import Professional from "../../images/professional.svg";
import ProfessionalRed from "../../images/professional-red.svg";
import "react-toggle/style.css" // for ES6 modules
import Toggle from 'react-toggle'
import CreateOrEditProfessionalModal from "../../components/createOrEditProfessionalModal";
import AgendaSelectModal from "../../components/agendaSelectModal";
import Medical from '../../images/medical.png'
import MedicalBlue from '../../images/medical-blue.png'
import MedicalGreen from '../../images/medical-green.png'

const mapStateToProps = store => ({
    isLogged: store.authReducer.isLogged,
    isLoaded: store.authReducer.isLoaded,
    isLoading: store.authReducer.isLoading,
    loadError: store.authReducer.loadError,
    user: store.authReducer.user,
    token: store.authReducer.token,
    professional: store.authReducer.professional,
    callStatus: store.videoCallReducer.status,
    callToken: store.videoCallReducer.token,
    roomName: store.videoCallReducer.roomName,
    roomId: store.videoCallReducer.roomId,
});

class ProfessionalDetail extends Component {

    constructor(props) {
        super(props);

        const start =  Moment().startOf('day').toDate();
        const end = Moment().startOf('day').toDate();

        this.state = {
            loading: true,
            modalVisible: false,
            agendaSelectModalVisible: false,
            professional: null,
            range: Moment.range(start, end),
            appointments: [],
            dailyAppointments: [],
            reservations: [],
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        try {
            const alias = scheduleUtils.getAlias(this.props.professionalProps.type);
            const response = await scheduleResource.fetch(
                    alias,
                    this.props.professionalProps.scheduleID,
                    this.props.token,
                    {
                        start: Moment().startOf('hour').format('YYYY-MM-DDTHH:mm:ssZ'),
                        end: Moment().add(7, "days").format('YYYY-MM-DDTHH:mm:ssZ'),
                        limit: 20,
                        status: ['created', 'confirmed', 'done'].join(',')
                    },
            );

            if (! response.data.success) {
                throw new Error(response.data.message);
            }

            this.setState({
                loading: false,
                appointments: response.data.appointments,
            });
        } catch(err){
            this.setState({
                loading: false,
            });

            alert(err.message);
        }
    };

    render(){

        let appointmentsWeekDaySlots = {};
        if(!this.state.loading){
            for(let item of this.state.appointments){
                if(!appointmentsWeekDaySlots[Moment(item.start).format('DD/MM')]){
                    appointmentsWeekDaySlots[Moment(item.start).format('DD/MM')] = [item];
                }else{
                    appointmentsWeekDaySlots[Moment(item.start).format('DD/MM')].push(item);
                }
            }
        }

        // let appointmentsWeekDaySlots = {
        //     [Moment().format('DD/MM')]: [
        //         {
        //             patient: {
        //                 name: "Jussara Skywalker"
        //             },
        //             start: new Date(),
        //             end: new Date().getTime() + (30 * 60 * 1000)
        //         },
        //         {
        //             patient: {
        //                 name: "Will Smith"
        //             },
        //             start: new Date().getTime() + (120 * 60 * 1000),
        //             end: new Date().getTime() + (150 * 60 * 1000)
        //         }
        //     ],
        //     [Moment().add(1, 'day').format('DD/MM')]: [
        //         {
        //             patient: {
        //                 name: "Will Smith"
        //             },
        //             start: new Date().getTime() + (120 * 60 * 1000),
        //             end: new Date().getTime() + (150 * 60 * 1000)
        //         }
        //     ]
        // };

        return (
                <>
                        <div style={{display: 'flex'}}>
                                <div style={{flex: 1}}>
                                    <div className={"box"} style={{marginBottom: 30, height: 'auto', maxHeight: 560, overflowY: 'scroll', minWidth: 460}}>
                                        <h3 style={{fontSize: 24}}>Próximas consultas</h3>

                                        <div style={{marginTop: 15}}>
                                            {!this.state.loading && this.state.appointments.length === 0 && <p>Sem agendamentos cadastrados.</p>}
                                            
                                            {Object.entries(appointmentsWeekDaySlots).map((item, index) => {
                                                return(
                                                    <div key={index} style={{display: 'flex'}}>
                                                        <div style={{flex: 0, position: 'relative', paddingRight: 10, borderRight: '3px solid #e7e7e7'}}>
                                                            <span style={{marginRight: 10, fontWeight: 'bold', width: 55, display: 'block'}}>{item[0] === String(Moment().format('DD/MM')) ? "Hoje" : item[0]}</span>
                                                            <div style={{
                                                                height: 16,
                                                                width: 16,
                                                                borderRadius: '50%',
                                                                backgroundColor: '#fff',
                                                                // backgroundColor: '#1F295C',
                                                                padding: 2,
                                                                border: '2px solid #ccc',
                                                                position: 'absolute',
                                                                top: 0,
                                                                right: -10
                                                            }}>
                                                                <div style={{
                                                                    backgroundColor: '#FC1481',
                                                                    height: 8,
                                                                    width: 8,
                                                                    borderRadius: '50%',
                                                                }}/>
                                                            </div>
                                                        </div>
                                                        <div style={{flex: 1, marginLeft: 20, paddingBottom: Object.entries(appointmentsWeekDaySlots).length-1 === index ? 0: 30}}>
                                                            {item[1].map((itemAppointments, indexAppointments) => {
                                                                return(
                                                                    <div key={indexAppointments} className={"box"} style={{marginTop: 15, marginBottom: 15, border: '1px solid #e7e7e7', padding: 12, borderRadius: 7}}>
                                                                        <p><b>{itemAppointments.patient.name}</b></p>
                                                                        <p style={{fontSize: 14}}>{Moment(itemAppointments.start).format('HH:mm')} - {Moment(itemAppointments.end).format('HH:mm')}</p>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                </>
        )
    }
}

export default connect(mapStateToProps)(withRouter(ProfessionalDetail));
