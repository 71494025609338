import React, { Component } from 'react';

import { connect } from 'react-redux';

import Logo from '../images/logo.svg';
import Button from '../components/Button';
import Input from '../components/Input';

import userResource from '../resources/user'
import { withRouter } from "react-router";
import Layout from "../components/Layout";
import {NavLink} from "react-router-dom";

const mapStateToProps = (state) => ({
    isLogged: state.authReducer.isLogged,
    isLogging: state.authReducer.isLogging,
    error: state.authReducer.error,
});

class Reset extends Component {

    constructor(props){
        super(props);

        this.state = {
            loading: false,
            email: '',
            sentLink: false
        }
    }

    onChangeEmail = (event) => {
        this.setState({
            email: event.target.value
        });
    };

    onReset = async (event) => {
        event.preventDefault();

        try {
            if(!this.state.email){
                throw new Error('Preencha os campos');
            }

            this.setState({
                loading: true,
            });

            let response = await userResource.reset({
                email: this.state.email,
            });

            if (!response.data.success) {
                throw new Error(response.data.message);
            }

            this.setState({
                loading: false,
                sentLink: true
            });

            // alert('Um e-mail foi enviado para você redefinir sua senha.');

            // this.props.history.push('/login');
        } catch(err){
            this.setState({
                loading: false,
            });

            alert(err.message);
        }
    };

    render(){
        return (
            <Layout
                style={{padding: 0}}
                title={this.state.sentLink ? "Link enviado" : "Esqueceu a senha?"}
                description={this.state.sentLink ? "Enviamos um link de redefinição de senha no seu email. Acesse-o para continuar." : "Confirme seu email para enviarmos um link de redefinição de senha."}
            >
                <div style={{width: 350, marginTop: 140}}>
                    <div className={"auth-box"} style={{textAlign: 'center', padding: 30}}>
                        {!this.state.sentLink && <form style={{textAlign: "center"}} onSubmit={this.onReset}>
                            <Input id={"email"} name={"email"} type={"text"} placeholder={"Email"} style={{width: "100%", marginBottom: 10}} onChange={this.onChangeEmail} value={this.state.email}/>

                            <Button text={"Enviar link"} type={"submit"} style={{width: 150}}/>

                            <div style={{marginTop: 40, textAlign: "left", marginBottom: 30}}>
                                <NavLink to="/login" exact className={"link"} style={{fontSize: 14, fontWeight: '600', color: '#FC1481', textDecoration: 'underline'}}>
                                    Voltar para login.
                                </NavLink>
                            </div>
                        </form>}
                        {this.state.sentLink && <div style={{marginTop: 40, textAlign: "left", marginBottom: 30}}>
                            <Button text={"VOLTAR"} style={{width: 150, backgroundColor: "#A8A8A8", color: "#1f295c"}} onClick={() => {
                                this.props.history.push('/login');
                            }}/>
                        </div>}
                    </div>
                </div>
            </Layout>
        );
    }
}

export default connect(mapStateToProps)(withRouter(Reset));
