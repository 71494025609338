import React, {Component} from 'react';
import { Link } from "react-router-dom";
import Icon from '@mdi/react'
import Layout from "../components/Layout";
import Badge from "../components/Badge";
import scheduleResource from "../resources/schedule";
import professionalResource from "../resources/professional";
import {connect} from "react-redux";
import Loader from "../components/Loader";
import CreateOrEditProfessionalModal from "../components/createOrEditProfessionalModal";
import scheduleUtils from '../utils/schedule'
import Moment from 'moment';
import { registerLocale } from "react-datepicker";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import ptBr from 'date-fns/locale/pt-BR';
import AddIcon from '../images/icn.add.png'
import {
  mdiAlarm,
  mdiPound,
  mdiCalendarRange,
  mdiCalendar,
  mdiGenderMaleFemale,
  mdiCakeVariant,
  mdiAccount,
  mdiMessageVideo,
  mdiClipboardAccount,
  mdiBottleTonicPlus,
  mdiCheck,
  mdiCheckBoxOutline,
  mdiClipboard,
  mdiContentCopy, mdiCheckboxMarked, mdiFileDocument, mdiDotsVertical, mdiPlus
} from '@mdi/js';
import Input from "../components/Input";
import {btn} from "../components/DocumentWidget/DocumentWidget.module.scss";
import Button from "../components/Button";
import dataUtils from "../utils/data";
import {withRouter} from "react-router";
import CreateDocumentModal from "../components/CreateDocumentModal";
import ProfessionalRed from "../images/professional-red.svg";
import Search from "../images/search.png";


registerLocale('pt-BR', ptBr);

const mapStateToProps = store => ({
  isLogged: store.authReducer.isLogged,
  isLoaded: store.authReducer.isLoaded,
  isLoading: store.authReducer.isLoading,
  loadError: store.authReducer.loadError,
  user: store.authReducer.user,
  token: store.authReducer.token,
  professional: store.authReducer.professional,
  callStatus: store.videoCallReducer.status,
  callToken: store.videoCallReducer.token,
  roomName: store.videoCallReducer.roomName,
  roomId: store.videoCallReducer.roomId,
});

class Professionals extends Component {

  constructor(props) {
    super(props);

    // const start =  Moment().startOf('day').toDate();
    // const end = Moment().startOf('day').toDate();

    this.state = {
      loading: true,
      selectedStatusFilter: "active",
      selectedTypeFilter: ["psychologist", "nutritionist", "doctor"],
      professionals: [],
      filteredProfessionals: [],
      modalVisible: false
    };

    this.timer = null;
  }

  componentDidMount() {
    this.fetchProfessionals(0);
  }

  fetchProfessionals = async (timer = 1000) => {
    // const alias = scheduleUtils.getAlias(this.props.professional.type);

    clearTimeout(this.timer);

    this.timer = setTimeout(async () => {

      try {
        this.setState({
          loading: true,
          professionals: [],
          filteredProfessionals: [],
          // selectedStatusFilter: "active"
        });

        const response = await professionalResource.fetch(this.props.token, this.state.selectedTypeFilter.join(","), this.state.selectedStatusFilter);
        console.log(response);
        if (response.status !== 200) {
          throw new Error(response.data.message);
        }

        this.setState({
          loading: false,
          professionals: response.data,
          filteredProfessionals: response.data,
        });
      } catch(err){
        this.setState({
          loading: false,
        });

        alert(err.message);
      }
    }, timer);
  };

  onChangeSearch = (event) => {
    this.setState({
      search: event.target.value,
      filteredProfessionals: !event.target.value || event.target.value === "" ? this.state.professionals : this.state.professionals.filter(item => item.name.toLowerCase().search(event.target.value.toLowerCase()) >= 0)
    });
  }

  render() {
    return (
      <Layout>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <h1 style={{fontWeight: 'bold', textAlign: 'left', marginBottom: 0, color: '#9C0D4F', fontSize: 24, borderBottom: '2px solid #9C0D4F', paddingBottom: 0,}}>
            <img alt={"Professional"} src={ProfessionalRed} style={{width: 24, marginBottom: -3, marginRight: 8}} />
            Profissionais
          </h1>
          <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
            <Input
                styleContainer={{
                  display: 'flex',
                  // flex: 1,
                  justifyContent: 'flex-end',
                  marginRight: 12,
                }}
                boldPlaceholder={true}
                style={{
                  minWidth: 350,
                  backgroundColor: '#E7E7E7',
                  // borderRadius: 25,
                  height: 32,
                  paddingLeft: 35,
                  paddingRight: 15,
                  // paddingTop: 0,
                  boxShadow: 'none',
                  // boxShadow: '0 1px 1px 0 rgba(75, 75, 75, 0.3)',
                }}
                styleImage={{
                  width: 16,
                  height: 16,
                  marginLeft: 5,
                  marginTop: 1,
                }}
                placeholder={"Busque pelo nome"}
                name={"search"}
                type={"text"}
                image={Search}
                value={this.state.search}
                onChange={this.onChangeSearch}
            />
            <Button
                text={""}
                image={AddIcon}
                styleImage={{
                  width: 24,
                  height: 24
                }}
                onClick={() => {
                  this.setState({
                    modalVisible: true
                  });
                }}
                style={{
                  backgroundColor: "#28A745",
                  minWidth: 32,
                  fontSize: 28,
                  padding: 0,
                  // paddingTop: 10,
                  height: 32,
                  minHeight: 32,
                  borderRadius: 8,
                  boxShadow: 'none'
                }}
            />
          </div>
        </div>

        <div style={{marginTop: 40, marginBottom: 40}}>
          {/*<div style={{}}>*/}
          {/*  <h6 style={{marginBottom: 5, fontWeight: 'bold', fontSize: 14, color: '#666'}}>DATA DO AGENDAMENTO</h6>*/}

          {/*  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>*/}
          {/*    <div>{this.renderSelectionValue()}</div>*/}

          {/*    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>*/}

          {/*      {this.state.isOpen && (*/}
          {/*        <DateRangePicker*/}
          {/*          value={this.state.range}*/}
          {/*          onSelect={this.handleChangeEndDate}*/}
          {/*          singleDateRange={true}*/}
          {/*          onSelectStart={this.handleChangeStartDate}*/}
          {/*        />*/}
          {/*      )}*/}

          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <div style={{marginRight: 20}}>
              <h6 style={{marginBottom: 5, fontWeight: 'bold', fontSize: 14, color: '#666'}}>STATUS</h6>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <Badge
                  style={{
                    ...this.state.selectedStatusFilter === "active" ? {backgroundColor: "#28A745", borderColor: "#28A745"} : {}
                  }}
                  styleText={{
                    ...this.state.selectedStatusFilter === "active" ? {color: "#fff"} : {}
                  }}
                  onClick={() => {
                    this.setState({
                      selectedStatusFilter: "active"
                    }, () => {
                      this.fetchProfessionals();
                    });
                  }}
                >Ativo</Badge>
                <Badge
                  style={{
                    ...this.state.selectedStatusFilter === "inactive" ? {backgroundColor: "#666", borderColor: "#666"} : {}
                  }}
                  styleText={{
                    ...this.state.selectedStatusFilter === "inactive" ? {color: "#fff"} : {}
                  }}
                  onClick={() => {
                    this.setState({
                      selectedStatusFilter: "inactive"
                    }, () => {
                      this.fetchProfessionals();
                    });
                  }}
                >Inativo</Badge>
              </div>
            </div>

            <div>
              <h6 style={{marginBottom: 5, fontWeight: 'bold', fontSize: 14, color: '#666'}}>ESPECIALIDADE</h6>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <Badge
                    style={{
                      ...this.state.selectedTypeFilter.includes("nutritionist") ? {backgroundColor: "#28A745", borderColor: "#28A745"} : {}
                    }}
                    styleText={{
                      ...this.state.selectedTypeFilter.includes("nutritionist") ? {color: "#fff"} : {}
                    }}
                    onClick={() => {
                      this.setState({
                        selectedTypeFilter: this.state.selectedTypeFilter.includes("nutritionist") ? this.state.selectedTypeFilter.filter(item => item !== "nutritionist") : [...this.state.selectedTypeFilter, "nutritionist"]
                      }, () => {
                        this.fetchProfessionals();
                      });
                    }}
                >Nutrição</Badge>
                <Badge
                    style={{
                      ...this.state.selectedTypeFilter.includes("psychologist") ? {backgroundColor: "#FFC107", borderColor: "#FFC107"} : {}
                    }}
                    styleText={{
                      ...this.state.selectedTypeFilter.includes("psychologist") ? {color: "#fff"} : {}
                    }}
                    onClick={() => {
                      this.setState({
                        selectedTypeFilter: this.state.selectedTypeFilter.includes("psychologist") ? this.state.selectedTypeFilter.filter(item => item !== "psychologist") : [...this.state.selectedTypeFilter, "psychologist"]
                      }, () => {
                        this.fetchProfessionals();
                      });
                    }}
                >Psicologia</Badge>
                <Badge
                    style={{
                      ...this.state.selectedTypeFilter.includes("doctor") ? {backgroundColor: "#1F295C", borderColor: "#1F295C"} : {}
                    }}
                    styleText={{
                      ...this.state.selectedTypeFilter.includes("doctor") ? {color: "#fff"} : {}
                    }}
                    onClick={() => {
                      this.setState({
                        selectedTypeFilter: this.state.selectedTypeFilter.includes("doctor") ? this.state.selectedTypeFilter.filter(item => item !== "doctor") : [...this.state.selectedTypeFilter, "doctor"]
                      }, () => {
                        this.fetchProfessionals();
                      });
                    }}
                >Medicina</Badge>
              </div>
            </div>
          </div>

          <div style={{display: 'block', marginTop: 20}}>
            <table style={{textAlign: 'left'}}>
              <thead>
              <tr>
                <th style={{maxWidth: 100}}>
                  <div>
                    <Icon
                      path={mdiPound}
                      title="Id"
                      size={0.7}
                      color="#666"
                    />
                    <span>Id</span>
                  </div>
                </th>
                <th>
                  <div>
                    <Icon
                      path={mdiClipboardAccount}
                      title="Nome"
                      size={0.7}
                      color="#666"
                    />
                    <span>Nome</span>
                  </div>
                </th>
                <th>
                  <div>
                    <Icon
                      path={mdiBottleTonicPlus}
                      title="Especilidade"
                      size={0.7}
                      color="#666"
                    />
                    <span>Especilidade</span>
                  </div>
                </th>
                <th style={{maxWidth: 100}}>
                  <div>
                    <Icon
                      path={mdiCalendar}
                      title="Ocupação"
                      size={0.7}
                      color="#666"
                    />
                    <span>Ocupação</span>
                  </div>
                </th>
                <th>
                  <div>
                    <Icon
                        path={mdiCheckboxMarked}
                        title="Status"
                        size={0.7}
                        color="#666"
                    />
                    <span>Status</span>
                  </div>
                </th>
                {/*<th>*/}
                {/*  <div>*/}
                {/*    <Icon*/}
                {/*        path={mdiFileDocument}*/}
                {/*        title="Detalhes"*/}
                {/*        size={0.7}*/}
                {/*        color="#666"*/}
                {/*    />*/}
                {/*    <span>Detalhes</span>*/}
                {/*  </div>*/}
                {/*</th>*/}
              </tr>
              </thead>
              <tbody>
              {this.state.filteredProfessionals.map((item, index) => {
                return(
                  <tr key={index} style={{cursor: 'pointer'}} onClick={() => {
                    setTimeout(() => {
                      if(!this.state.clickedOnCopyButton){
                        this.props.history.push("/profissional/"+item.id)
                      }
                    }, 250)
                  }}
                  >
                    <td style={{maxWidth: 100}}>
                      <p style={{maxWidth: 100, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>
                        <button
                          style={{
                            border: 'none',
                            margin: 0,
                            outline: 'none',
                            cursor: "pointer",
                            background: 'none'
                          }}
                          onClick={(event) => {
                            event.preventDefault();

                            this.setState({
                              clickedOnCopyButton: true
                            });

                            navigator.clipboard.writeText(item.id).then(function() {
                              /* clipboard successfully set */
                              console.log("copied");
                            }, function(err) {
                              /* clipboard write failed */
                              console.log(err);
                            });

                            setTimeout(() => {  //to prevent navigation
                              this.setState({
                                clickedOnCopyButton: false
                              });
                            }, 1000);
                          }}
                        >
                          <Icon
                              path={mdiContentCopy}
                              title="Status"
                              size={0.7}
                              color="#666"
                              style={{marginRight: 10}}
                          />
                        </button>
                        <span id={"professional-"+item.id}>{item.id}</span>
                      </p>
                    </td>
                    <td>
                      {item.name}
                    </td>
                    <td
                        style={{
                          fontWeight: 'bold',
                          ...item.type === "nutritionist" ? {color: '#28A745'} : {},
                          ...item.type === "psychologist" ? {color: '#FFC107'} : {},
                          ...item.type === "doctor" ? {color: '#1F295C'} : {},
                        }}
                    >{dataUtils.getProfessionalNameByType(item.type)}</td>
                    <td style={{maxWidth: 100}}>{item.availabilityStats.totalSlots - item.availabilityStats.availability}/{item.availabilityStats.totalSlots}</td>
                    <td
                      style={{
                        fontWeight: 'bold',
                        ...item.status === "active" ? {color: '#28A745'} : {}
                      }}
                    >{dataUtils.getProfessionalStatusText(item.status)}</td>
                    <td>
                    </td>
                  </tr>
                )
              })}
              {!this.state.loading && this.state.professionals.length === 0 && <tr>
                <td colSpan={6}>Sem profissionais cadastrados.</td>
              </tr>}
              {this.state.loading && <tr>
                <td colSpan={6}><Loader/></td>
              </tr>}
              </tbody>
            </table>
          </div>
        </div>

        <CreateOrEditProfessionalModal
            onCloseRequest={(createdProfessional = null) => {
              this.setState({
                modalVisible: false
              });

              if(createdProfessional){
                this.props.history.push("/profissional/"+createdProfessional.id)
              }
            }}
            visible={this.state.modalVisible}
            // visible={true}
        />
      </Layout>
    );
  }
}

export default connect(mapStateToProps)(withRouter(Professionals));
