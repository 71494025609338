import React, { Component } from 'react';
import { wrapper, buttonCall} from "./AppointmentWidget.module.scss";
import Widget from '../Widget';

import Moment from 'moment';
import Icon from '@mdi/react';
import {mdiWhatsapp, mdiVideo} from '@mdi/js';
import {withRouter} from "react-router";
import {connect} from "react-redux";

import { actionCreators as actionCreatorsVideoCall } from '../../reducers/video-call';

const mapStateToProps = store => ({
    callStatus: store.videoCallReducer.status,
    user: store.authReducer.user,
});

class AppointmentWidget extends Component {
    constructor(props) {
        super(props);

        this.state = {
            callPressed: false,
        };
    }

    subtitleText() {
        const date = Moment(this.props.appointment.start).format('DD [de] MMMM');
        const start = Moment(this.props.appointment.start).format('HH:mm');
        const end = Moment(this.props.appointment.end).format('HH:mm');
        return `${date} ${start} - ${end}`;
    }

    onVideoCall = () => {
        const { match: { params } } = this.props;
        this.setState({
            callPressed: true,
        })
        this.props.dispatch(
            actionCreatorsVideoCall.callStart(
                params.id,
                this.props.user._id,
                this.props.appointment.patient.id,
                this.props.appointment.patient
            )
        );
    };

    onWhatsapp = () => {
        const {appointment, user} = this.props;
        window.open(`https://api.whatsapp.com/send?phone=+${appointment.patient.msisdn}&text=Olá ${appointment.patient.name.split(' ')[0]}, eu sou ${user.name.split(' ')[0]} do bem.care. Pronto para começar o nosso atendimento? Por favor entre no seu app bem.care e vamos lá!`)
    }

    render() {
        const { callPressed } = this.state;

        return (
            <Widget title={'Agendamento'} subtitle={this.subtitleText()}>
                <div className={wrapper}>
                    <button
                        onClick={this.onVideoCall}
                        className={buttonCall}
                        disabled={callPressed}
                    >
                        <Icon path={mdiVideo}
                                title="Ligar"
                                size={.8}
                                color="#FFFFFF"
                        />
                    </button>
                    <button
                        onClick={this.onWhatsapp}
                        className={buttonCall}
                        disabled={callPressed}
                    >
                        <Icon path={mdiWhatsapp}
                            title="Ligar"
                            size={.9}
                            color="#FFFFFF"
                        />
                    </button>
                </div>
            </Widget>
        );
    }
}


export default connect(mapStateToProps)(withRouter(AppointmentWidget));