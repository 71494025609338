import React, { Component } from 'react';

import {connect} from "react-redux";
import {mdiPhone, mdiVideo} from '@mdi/js';

import Icon from '@mdi/react';
import Moment from 'moment';

import callAPI from "../../resources/call";
import dataUtils from '../../utils/data';
import Loader from '../Loader';

const mapStateToProps = store => ({
    user: store.authReducer.user,
    token: store.authReducer.token,
});

class CallHistory extends Component {
    constructor(props) {
        super(props);

        const {user: {_id: userID}, patientID} = props;

        this.state = {
            userID,
            patientID,
            loadingCalls: false,
            calls: [],
        };
    }

    componentDidMount() {
        this.fetchCalls();
    }

    fetchCalls = async () => {
        try {

            const { token } = this.props;
            const { patientID } = this.state;

            this.setState({
                loadingCalls: true,
            });

            const response = await callAPI.getUserCalls(patientID, token);

            if (response.status !== 200 || response.data.success !== true) {
                throw new Error(response.data.message);
            }

            const calls = response.data.calls.sort( (a, b) =>
                Moment(b.created_at).toDate().getTime() - Moment(a.created_at).toDate().getTime()
            ).slice(0, Math.min(response.data.calls.length, 5));

            this.setState({
                loadingCalls: false,
                calls,
            });
        } catch(err){
            this.setState({
                loadingCalls: false,
                error: 'Ocorreu um erro ao carregar. Tente novamente.'
            });
        }
    };

    getCallStatusText(status) {
        return {
            'canceled': 'cancelada',
            'expired':  'perdida',
            'rejected': 'não atendida',
            'finished': 'finalizada',
            'started': 'em progresso',
            'created': 'em progresso'
        }[status];
    }

    render() {
        return (
            <div className={"box"} style={{marginBottom: 30}}>
                <h3 style={{fontSize: 24}}>Registro de chamadas</h3>

                {this.state.loadingCalls && <div style={{marginTop: 18}}>
                    <Loader/>
                </div>}

                <div style={{marginTop: 18}}>
                    {!this.state.loadingCalls && this.state.calls.map((item, index) => {
                        return(
                            <div key={index} style={{marginTop: 12, paddingTop: 6, paddingBottom: 6, display: 'flex', flexDirection: 'row', borderBottom: '1px solid #707070'}}>
                                <div style={{flex: 1, opacity: item.status === "expired" ? 0.5 : 1, flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
                                    <Icon
                                        path={item.type === "phone" ? mdiPhone : mdiVideo}
                                        title="Chamada"
                                        size={1}
                                        color="#373737"
                                        style={{
                                            marginRight: 12,
                                            marginTop: -3,
                                        }}
                                    />
                                    <span style={{fontSize: 16, marginTop: 0}}>
                                        {item.status !== "finished" ? `Chamada ${this.getCallStatusText(item.status)}` : dataUtils.formatDuration(Moment(item.finished_at).diff( Moment(item.created_at), "seconds"))}
                                    </span>
                                </div>
                                <div style={{flex: 0, minWidth: 60}}>
                                    <p style={{fontSize: 12, color: '#A7A7A7'}}>
                                        {Moment(item.created_at).calendar(null, {
                                            sameDay: '[Hoje], HH[:]mm',
                                            nextDay: '[Amanhã], HH[:]mm',
                                            lastDay: '[Ontem], HH[:]mm',
                                            sameElse: 'DD [de] MMMM [de] YYYY, HH[:]mm'
                                        })}
                                    </p>
                                </div>
                            </div>
                        );
                    })}

                    {!this.state.loadingCalls && this.state.calls.length === 0 && <p style={{marginTop: 12}}>Sem chamadas.</p>}
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(CallHistory);