import React, { Component } from 'react';
import Spinner from '../../images/spinner.svg';
import SpinnerWhite from '../../images/spinner_white.svg';

export default class Loader extends Component {
    render() {
        // const size = this.props.size ? this.props.size : 22;

        let style = {
            width: 50,
            ...this.props.style,
        };

        let styleContainer = {
            position: "absolute",
            top: "50%",
            left: "50%",
            margin: "-50px 0px 0px -50px",
            ...this.props.styleContainer,
        };

        if(this.props.container){
            return (
                <div style={styleContainer}>
                    <img alt={"Loader"} src={this.props.negative ? SpinnerWhite : Spinner} style={style} />
                </div>
            )
        }else{
                // <Icon style={style} className={`${loaderSpin} ${this.props.negative ? "" : loaderGreen}`} icon={"loading"} size={size}/>
            return (
                <img alt={"Loader"} src={this.props.negative ? SpinnerWhite : Spinner} style={style} />
            );
        }
    }
}
