import React, { Component } from 'react';

import { widgetWrapper, widgetContent } from "./Widget.module.scss";

export default class Widget extends Component {
    render() {
        return (
            <div className={`${widgetWrapper} ${this.props.className || ''}`}>
                <h3 className={"title"}>
                    {this.props.title}
                    {
                        this.props.subtitle 
                        ? <aside>{this.props.subtitle}</aside>
                        : ''
                    }
                </h3>
                <div className={widgetContent}>
                    {this.props.children}
                </div> 
            </div>
        );
    }
}
