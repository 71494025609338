import React, { Component } from 'react';
import { mdiGenderMaleFemale }  from '@mdi/js';
import Icon from '@mdi/react';

import { 
    gWrapper, 
    gIcon, 
    gField 
} from "./GenderField.module.scss";

export default class GenderField extends Component {
    render() {
        return (
            <div className={`${gWrapper} ${this.props.className || ''}`} style={{...this.props.style}}>
                <div className={gIcon}>
                    <Icon
                        path={mdiGenderMaleFemale}
                        title="Sexo"
                        size={0.8}
                        color={this.props.iconColor || '#666'}
                    />
                </div>
                <div className={gField}>
                    <p>{ this.props.gender === 'M' ? 'Masculino' : 'Feminino' }</p>
                </div> 
            </div>
        );
    }
}
