import React, {Component} from 'react';
import { Link } from "react-router-dom";
import Icon from '@mdi/react'
import Layout from "../components/Layout";
import Badge from "../components/Badge";
import scheduleResource from "../resources/schedule";
import professionalResource from "../resources/professional";
import {connect} from "react-redux";
import Loader from "../components/Loader";
import CreateOrEditProfessionalModal from "../components/createOrEditProfessionalModal";
import scheduleUtils from '../utils/schedule'
import Moment from 'moment';
import { registerLocale } from "react-datepicker";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import ptBr from 'date-fns/locale/pt-BR';
import {
    mdiAlarm,
    mdiPound,
    mdiCalendarRange,
    mdiCalendar,
    mdiGenderMaleFemale,
    mdiCakeVariant,
    mdiAccount,
    mdiMessageVideo,
    mdiClipboardAccount,
    mdiBottleTonicPlus,
    mdiCheck,
    mdiCheckBoxOutline,
    mdiClipboard,
    mdiContentCopy, mdiCheckboxMarked, mdiSquareEditOutline, mdiMonitorDashboard
} from '@mdi/js';
import Input from "../components/Input";
import {btn} from "../components/DocumentWidget/DocumentWidget.module.scss";
import Button from "../components/Button";
import dataUtils from "../utils/data";
import {withRouter} from "react-router";
import CreateDocumentModal from "../components/CreateDocumentModal";
import ProfessionalRed from "../images/professional-red.svg";
import DashboardRed from "../images/dashboard_red.png";
import Toggle from "react-toggle";
import NextAppointments from "../components/NextAppointments";

registerLocale('pt-BR', ptBr);

const mapStateToProps = store => ({
    isLogged: store.authReducer.isLogged,
    isLoaded: store.authReducer.isLoaded,
    isLoading: store.authReducer.isLoading,
    loadError: store.authReducer.loadError,
    user: store.authReducer.user,
    token: store.authReducer.token,
    professional: store.authReducer.professional,
    callStatus: store.videoCallReducer.status,
    callToken: store.videoCallReducer.token,
    roomName: store.videoCallReducer.roomName,
    roomId: store.videoCallReducer.roomId,
});

class Dashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            customerAmount: 0,
            totalSlots: 0,
            occupiedSlots: 0,
            totalAppointments: 0,
            confirmedAppointments: 0,
            canceledAppointments: 0,
            professional: null,
            appointments: [],
        };
    }

    async componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        try {
            this.setState({
                loading: true,
            });

            const responseProfessional = await professionalResource.get(this.props.token, this.props.professional._id);     
            const responseReservations = await scheduleResource.getProfessionalAppointments(`${responseProfessional.data['id']}&start=${Moment().format('YYYY-MM-DD')}T00:00:00.000Z&end=${Moment().format('YYYY-MM-DD')}T23:59:59.000Z`, this.props.token, '');
            const alias = scheduleUtils.getAlias(responseProfessional.data.type);
            const scheduleId = responseProfessional.data["scheduleID"];
            const responseSchedule = await scheduleResource.fetch(
                                                alias,
                                                scheduleId,
                                                this.props.token,
                                                {
                                                    start: Moment().add(-1, "days").format('YYYY-MM-DDTHH:mm:ssZ'),
                                                    end: Moment().add(7, "days").format('YYYY-MM-DDTHH:mm:ssZ'),
                                                    limit: 10
                                                }
                                            );
                                            
            let canceledAppointments = (responseReservations.data).filter(reservation => reservation['status'] === 'canceled').length;
            let confirmedAppointments = (responseReservations.data).filter(reservation => reservation['status'] !== 'canceled').length;
            
            if (responseProfessional.status !== 200) {
                throw new Error(responseProfessional.data.message);
            }

            if (responseReservations.status !== 200) {
                throw new Error(responseReservations.data.message);
            }
            
            this.setState({
                loading: false,
                customerAmount: responseProfessional.data["reservationStats"]["customers"],
                totalSlots: responseProfessional.data["reservationStats"]["totalSlots"],
                occupiedSlots: responseProfessional.data["reservationStats"]["occupiedSlots"],
                totalAppointments: responseReservations.data.length,
                confirmedAppointments,
                canceledAppointments,
                professional: responseProfessional.data,
                appointments: responseSchedule.data.appointments,
            });
        } catch(err){
            this.setState({
                loading: false,
            });

            alert(err.message);
        }
    };

    render() {
        const {
            customerAmount,
            totalSlots,
            occupiedSlots,
            totalAppointments,
            confirmedAppointments,
            canceledAppointments,
            professional,
        } = this.state;
        
        let weekDaySlots = {};
        if(!this.state.loading){
            for(let item of this.state.appointments){
                if(!weekDaySlots[Moment(item.start).format('DD/MM')]){
                    weekDaySlots[Moment(item.start).format('DD/MM')] = [item];
                }else{
                    weekDaySlots[Moment(item.start).format('DD/MM')].push(item);
                }
            }
        }

        return (
            <Layout>
                <div>
                    <div>
                        {this.state.loading && <div style={{marginTop: 40}}>
                            <Loader/>
                        </div>}
                        {!this.state.loading && <div>
                            <div style={{display: 'flex'}}>
                                <div style={{flex: 1}}>
                                    <h1 style={{fontWeight: 'bold', textAlign: 'left', marginBottom: 30, color: '#9C0D4F', fontSize: 22}}>
                                        <img src={DashboardRed} alt={"Dashboard"} style={{width: 24, height: 'auto', marginBottom: -5, marginRight: 8}}/>
                                        Dashboard
                                    </h1>
                                </div>
                            </div>

                            <div style={{display: 'flex'}}>
                                <div style={{flex: 1, textAlign: 'center', marginRight: 30}}>
                                    {this.props.professional.type !== "doctor" && 
                                        <div className={"box"} style={{textAlign: 'left', marginBottom: 30}}>
                                            <h3 style={{fontSize: 24}}>Panorama</h3>

                                            <div style={{marginTop: 20, display: 'flex'}}>
                                                <div style={{marginRight: 20}}>
                                                    <div style={{fontWeight: '600', fontSize: 16, color: '#666'}}>PACIENTES</div>
                                                    <p style={{fontSize: 32}}>{customerAmount}</p>
                                                </div>
                                                <div style={{marginRight: 20}}>
                                                    <div style={{fontWeight: '600', fontSize: 16, color: '#666'}}>TERAPIAS SEMANAIS</div>
                                                    <p style={{fontSize: 32}}>{occupiedSlots}<span style={{fontSize: 16}}>/{totalSlots}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className={"box"} style={{textAlign: 'left', marginBottom: 30}}>
                                        <h3 style={{fontSize: 24}}>Agenda de hoje</h3>

                                        <div style={{marginTop: 20, display: 'flex'}}>
                                            <div style={{marginRight: 20}}>
                                                <div style={{fontWeight: '600', fontSize: 16, color: '#666'}}>AGENDAMENTOS</div>
                                                <p style={{fontSize: 32}}>{confirmedAppointments}<span style={{fontSize: 16}}>/{totalAppointments}</span></p>
                                            </div>
                                            <div style={{marginRight: 20}}>
                                                <div style={{fontWeight: '600', fontSize: 16, color: '#666'}}>CANCELAMENTOS</div>
                                                <p style={{fontSize: 32}}>{canceledAppointments}</p>
                                            </div>
                                            {/* <div style={{marginRight: 20}}>
                                                <div style={{fontWeight: '600', fontSize: 16, color: '#666'}}>NO SHOW</div>
                                                <p style={{fontSize: 32}}>1</p>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>

                                <NextAppointments isAdmin={false} professionalProps={this.state.professional} />

                                {/* <div style={{flex: 1, display: 'none'}}>
                                    <div className={"box"} style={{marginBottom: 30, maxHeight: 600, overflowY: 'scroll'}}>
                                        <h3 style={{fontSize: 24}}>Próximas consultas</h3>

                                        <div style={{marginTop: 20}}>
                                            {Object.entries(weekDaySlots).map((item, index) => {
                                                return(
                                                    <div key={index} style={{display: 'flex'}}>
                                                        <div style={{flex: 0, position: 'relative', paddingRight: 10, borderRight: '3px solid #e7e7e7'}}>
                                                            <span style={{marginRight: 10, fontWeight: 'bold', width: 55, display: 'block'}}>{item[0] === String(Moment().format('DD/MM')) ? "Hoje" : item[0]}</span>
                                                            <div style={{
                                                                height: 16,
                                                                width: 16,
                                                                borderRadius: '50%',
                                                                backgroundColor: '#fff',
                                                                padding: 2,
                                                                border: '2px solid #ccc',
                                                                position: 'absolute',
                                                                top: 0,
                                                                right: -10
                                                            }}>
                                                                <div style={{
                                                                    backgroundColor: '#FC1481',
                                                                    height: 8,
                                                                    width: 8,
                                                                    borderRadius: '50%',
                                                                }}/>
                                                            </div>
                                                        </div>
                                                        <div style={{flex: 1, marginLeft: 20, paddingBottom: Object.entries(weekDaySlots).length-1 === index ? 0: 30}}>
                                                            {item[1].map((itemAppointments, indexAppointments) => {
                                                                return(
                                                                    <div key={indexAppointments} className={"box"}  style={{marginTop: 15, marginBottom: 15, padding: 12, borderRadius: 7}}>
                                                                        <p><b>{itemAppointments.patient.name}</b></p>
                                                                        <p style={{fontSize: 14}}>{Moment(itemAppointments.start).format('HH:mm')} - {Moment(itemAppointments.end).format('HH:mm')}</p>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>}
                    </div>
                </div>
            </Layout>
        );
    }
}

export default connect(mapStateToProps)(withRouter(Dashboard));