import React, { Component } from 'react';
// import classNames from 'classnames';
import { button, buttonSecundary, disabled } from "./Button.module.scss";
import colors from "../../styles/colors.module.scss";

import Loader from '../../components/Loader';
import {mdiCakeVariant} from "@mdi/js";
import Icon from "@mdi/react";

export default class Button extends Component {
    render(){

        let btnClass = `${this.props.secundary ? buttonSecundary : ''}
                        ${this.props.disabled ? disabled : ''}
                        ${this.props.className ? this.props.className : colors.bgDarkBlue}
                        ${button}`;

        return (
            <button
                id={this.props.id}
                type={this.props.type}
                className={btnClass}
                style={this.props.style}
                disabled={this.props.disabled}
                onClick={this.props.onClick}
            >
                {this.props.loading && <Loader style={this.props.styleLoader} negative={true} />}
                {this.props.icon && <Icon
                    path={this.props.icon}
                    title=""
                    size={this.props.iconSize || 1}
                    color={this.props.iconColor || '#666'}
                    style={this.props.styleIcon}
                />}
                {this.props.image && <img
                    src={this.props.image}
                    style={this.props.styleImage}
                />}
                {!this.props.loading && this.props.text}
            </button>
        );
    }
}
