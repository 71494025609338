import requestUtils from '../utils/request';
import apiConfig from "../configs/api";

export default {
    get(id, token) {
        return requestUtils({
            url: `${apiConfig.API}/patient/${id}`,
            method: 'GET',
            token,
        });
    },
};
