import React, {Component} from 'react';
import { Link } from "react-router-dom";
import Icon from '@mdi/react'
import Layout from "./Layout";
import scheduleResource from "../resources/schedule";
import {connect} from "react-redux";
import Loader from "./Loader";
import scheduleUtils from '../utils/schedule'
import Moment from 'moment';
import { registerLocale } from "react-datepicker";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import ptBr from 'date-fns/locale/pt-BR';
import {
  mdiAlarm,
  mdiCalendarRange,
  mdiCalendar,
  mdiGenderMaleFemale,
  mdiCakeVariant,
  mdiAccount, mdiMessageVideo, mdiCheckboxMarked
} from '@mdi/js';
import Badge from "./Badge";
import dataUtils from "../utils/data";
import {withRouter} from "react-router";


registerLocale('pt-BR', ptBr);

const mapStateToProps = store => ({
  isLogged: store.authReducer.isLogged,
  isLoaded: store.authReducer.isLoaded,
  isLoading: store.authReducer.isLoading,
  loadError: store.authReducer.loadError,
  user: store.authReducer.user,
  token: store.authReducer.token,
  professional: store.authReducer.professional,
  callStatus: store.videoCallReducer.status,
  callToken: store.videoCallReducer.token,
  roomName: store.videoCallReducer.roomName,
  roomId: store.videoCallReducer.roomId,
});

class Schedule extends Component {

  constructor(props) {
    super(props);

    const start =  Moment().startOf('day').toDate();
    const end = Moment().startOf('day').toDate();

    this.state = {
      loading: true,
      appointments: [],
      isOpen: false,
      range: Moment.range(start, end),
      selectedStatusFilter: [],
    };

    this.timer = null;
  }

  componentDidMount() {
    this.fetchSchedules(0);
  }

  fetchSchedules = async (timer = 1000) => {
    clearTimeout(this.timer);

    this.timer = setTimeout(async () => {
      const alias = scheduleUtils.getAlias(this.props.professional.type);

      try {
        this.setState({
          loading: true,
          appointments: []
        });

        const end = this.state.range.end.clone();

        const response = await scheduleResource.fetch(
            alias,
            this.props.professional.schedule_id,
            this.props.token,
            {
              start: this.state.range.start.format('YYYY-MM-DDTHH:mm:ssZ'),
              end: end.add(1, 'day').format('YYYY-MM-DDTHH:mm:ssZ'),
            },
            this.state.selectedStatusFilter
        );

        if (!response.data.success) {
          throw new Error(response.data.message);
        }

        this.setState({
          loading: false,
          appointments: response.data.appointments,
        });
      } catch (err) {
        this.setState({
          loading: false,
        });

        alert(err.message);
      }
    }, timer);
  };

  getInterval() {
    return this.state.range.end.diff(this.state.range.start, 'days');
  }

  onToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  renderSelectionValue = () => {
    return (
      <div onClick={this.onToggle} className="input-date">
        <Icon
          path={mdiCalendarRange}
          title="Agenda"
          size={0.8}
          color="#AFAFAF"
          className='icon-data'
        />
        <p className="margin-l" id="startDate">  {this.state.range.start.format("DD/MM/YYYY")} </p>

        { this.getInterval() >= 1 && (
          <span> à </span>
        )}
        { this.getInterval() >= 1 && (
          <p> {this.state.range.end.format("DD/MM/YYYY")} </p>
        )}

      </div>
    );
  };

  handleChangeEndDate = (range, states) => {
    this.setState({
      range,
      states,
      isOpen: !this.state.isOpen,
    });

    this.fetchSchedules();
  };

  render() {
    return (
      <Layout>
        <h1 style={{
          fontWeight: 'bold',
          textAlign: 'left',
          marginBottom: 0,
          marginTop: 10,
          padding: 0
        }}>
          Agenda
        </h1>
        <h4 style={{
          color: "#666",
          fontSize: 14,
          fontWeight: 'bold'
        }}>
          Veja os próximos atendimentos a serem realizados.
        </h4>

        {/*{this.state.loading && <div style={{marginTop: 40}}>*/}
        {/*  <Loader/>*/}
        {/*</div>}*/}

        <div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, marginBottom: 20}}>
            <div style={{marginRight: 40}}>
              <h6 style={{marginBottom: 5, fontWeight: 'bold', fontSize: 14, color: '#666'}}>INTERVALO DA BUSCA</h6>

              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <div>{this.renderSelectionValue()}</div>

                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>

                  {this.state.isOpen && (
                    <DateRangePicker
                      value={this.state.range}
                      onSelect={this.handleChangeEndDate}
                      singleDateRange={true}
                      onSelectStart={this.handleChangeStartDate}
                    />
                  )}

                </div>
              </div>
            </div>
          </div>

          <div style={{display: 'block'}}>
            <table style={{textAlign: 'left'}}>
              <thead>
              <tr>
                <th>
                  <div>
                    <Icon
                      path={mdiAlarm}
                      title="Hora"
                      size={0.7}
                      color="#666"
                    />
                    <span>Horário</span>
                  </div>
                </th>
                <th>
                  <div>
                    <Icon
                      path={mdiCalendar}
                      title="Data"
                      size={0.7}
                      color="#666"
                    />
                    <span>Data</span>
                  </div>
                </th>
                <th>
                  <div>
                    <Icon
                      path={mdiAccount}
                      title="Usuário"
                      size={0.7}
                      color="#666"
                    />
                    <span>Nome</span>
                  </div>
                </th>
                <th>
                  <div>
                    <Icon
                      path={mdiGenderMaleFemale}
                      title="Gênero"
                      size={0.7}
                      color="#666"
                    />
                    <span>Sexo</span>
                  </div>
                </th>
                <th>
                  <div>
                    <Icon
                      path={mdiCakeVariant}
                      title="Idade"
                      size={0.7}
                      color="#666"
                    />
                    <span>Idade</span>
                  </div>
                </th>
                <th>
                  <div>
                    <Icon
                        path={mdiCheckboxMarked}
                        title="Status"
                        size={0.7}
                        color="#666"
                    />
                    <span>Status</span>
                  </div>
                </th>
                {/*<th>*/}
                {/*  <div>*/}
                {/*    <Icon*/}
                {/*      path={mdiMessageVideo}*/}
                {/*      title="Teleconsulta"*/}
                {/*      size={0.7}*/}
                {/*      color="#666"*/}
                {/*    />*/}
                {/*    <span>Teleconsulta</span>*/}
                {/*  </div>*/}
                {/*</th>*/}
              </tr>
              </thead>
              <tbody>
              {this.state.appointments.map((item, index) => {
                return(
                  <tr key={index} onClick={() => {this.props.history.push("/paciente/"+item.patient.id)}} style={{cursor: 'pointer'}}>
                    {/*<td>{item.id}</td>*/}
                    <td>{Moment(item.start).format('HH:mm')}</td>
                    <td>{Moment(item.start).format('DD/MM/YYYY')}</td>
                    <td>{item.patient.name}</td>
                    <td>{item.patient.gender === "M" ? "Masculino" : "Feminino"}</td>
                    <td>
                        <span className="font-14">
                            {Moment
                              .preciseDiff(
                                Moment(item.patient.birthdate).format(),
                                Moment().format()
                              ).split(" ")[0]} anos e
                          {' ' +  Moment.preciseDiff(
                            Moment(item.patient.birthdate).format(),
                            Moment().format()
                          ).split(" ")[2]} meses
                        </span>
                      <br></br>
                      <span className="light-gray font-12">
                          Nascido em {Moment(item.patient.birthdate)
                                        .add(3,'hours')
                                        .format('DD/MM/YYYY')}
                      </span>

                    </td>
                    {/*<td>{Moment(item.end).format('DD/MM/YY [às] HH:mm')}</td>*/}
                    <td style={{color: dataUtils.getAppointmentStatusColor(item.status), fontWeight: 'bold'}}>{dataUtils.getAppointmentStatusText(item.status)}</td>
                    {/*<td>*/}
                    {/*  <Link to={{*/}
                    {/*    pathname: "/agendamento/"+item.id,*/}
                    {/*    // state: item*/}
                    {/*  }}>*/}
                    {/*    <span className={"table-action-link"}>Ir para consulta</span>*/}
                    {/*  </Link>*/}
                    {/*</td>*/}
                  </tr>
                )
              })}
              {!this.state.loading && this.state.appointments.length === 0 && <tr>
                <td colSpan={6}>Sem compromissos agendados.</td>
              </tr>}
              {this.state.loading && <tr>
                <td colSpan={6}>
                  <Loader/>
                </td>
              </tr>}
              </tbody>
            </table>
          </div>
        </div>
      </Layout>
    );
  }
}

export default connect(mapStateToProps)(withRouter(Schedule));
