import React, {Component} from 'react';

import Layout from '../components/Layout'
import DocumentWidget from '../components/DocumentWidget';

import {withRouter} from "react-router";
import {connect} from "react-redux";

import scheduleResource from "../resources/schedule";
import callResource from "../resources/call";
import quizResource from "../resources/quiz";
import Loader from "../components/Loader";
import CallHistory from '../components/CallHistory';

import 'react-calendar/dist/Calendar.css';
import '../styles/notFound.scss';
import Icon from "@mdi/react";
import {
    mdiCakeVariant, mdiDotsHorizontal, mdiGenderMaleFemale, mdiPhone, mdiVideo, mdiWhatsapp
} from "@mdi/js";
import Moment from "moment";
import "react-toggle/style.css" // for ES6 modules
import PatientRed from '../images/patient-red.png'
import {actionCreators as actionCreatorsVideoCall} from "../reducers/video-call";

import 'moment/locale/pt-br';
import patient from '../resources/patient';
import { endOfTomorrow } from 'date-fns';

const mapStateToProps = store => ({
    isLogged: store.authReducer.isLogged,
    isLoaded: store.authReducer.isLoaded,
    isLoading: store.authReducer.isLoading,
    loadError: store.authReducer.loadError,
    user: store.authReducer.user,
    token: store.authReducer.token,
    professional: store.authReducer.professional,
    callStatus: store.videoCallReducer.status,
    callToken: store.videoCallReducer.token,
    roomName: store.videoCallReducer.roomName,
    roomId: store.videoCallReducer.roomId,
});

class PatientDetail extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            loadingAppointments: true,
            loadingOnboard: true,
            loadingCalls: true,
            modalVisible: false,
            agendaSelectModalVisible: false,
            patient: null,
            appointments: [],
            filteredAppointments: [],
            calls: [],
            firstAppointment: "",
            appointmentToday: false,
        };
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        this.getPatient(params.id);
    }

    fetchSchedules = async () => {
        try {
            this.setState({
                loadingAppointments: true,
            }); 

            const getFirstAppointment = await scheduleResource.getPatientAppointmentFiltered(
                this.state.patient.id,
                this.props.token,
                {
                    limit: 1
                }
            );

            const response = await scheduleResource.getProfessionalAppointments(
                this.props.professional._id,
                this.props.token,
                `created,confirmed&start=${Moment().add(-1, "days").format('YYYY-MM-DDTHH:mm:ssZ')}&end=${Moment().add(7, "days").format('YYYY-MM-DDTHH:mm:ssZ')}`,
            );

            let filteredAppointments = (response.data).filter(appointment => Number(appointment["patient"]["id"]) === this.state.patient.id);
            
            /* É realizado um forEach sobre cada agendamento e caso tenha um agendamento para a data de hoje, o estado de appointmentToday é alterado para true.
             * Assim, disponibilizará as opções de ligação e Whatsapp.
             */
            filteredAppointments.forEach(appointment => {
                let currentDate = Moment(appointment.start).toDate();
                
                if(currentDate <= Moment().endOf('day') && currentDate >= Moment().startOf('day')){
                    this.setState({
                        appointmentToday: true,
                    });
                }
            });

            if (response.status !== 200) {
                throw new Error(response.data.message);
            }

            if (getFirstAppointment.status !== 200){
                throw new Error(getFirstAppointment.data.message);
            }

            this.setState({
                loadingAppointments: false,
                appointments: filteredAppointments,
                firstAppointment: getFirstAppointment.data,
            });

            let filteredReservations = this.state.appointments.filter(appointment => appointment["status"] !== "canceled");
            filteredReservations = filteredReservations.filter(appointment => appointment["professional"]["id"] === this.props.professional._id);

            this.setState({
                filteredAppointments: filteredReservations,
            });
        } catch(err){
            this.setState({
                loadingAppointments: false,
            });

            alert(err.message);
        }
    };

    fetchCalls = async () => {
        try {
            this.setState({
                loadingCalls: true,
            });

            const response = await callResource.getUserCalls(
                this.state.patient.id,
                this.props.token,
            );

            if (response.status !== 200 || response.data.success !== true) {
                throw new Error(response.data.message);
            }
            const calls = response.data.calls.sort( (a, b) =>
                Moment(b.created_at).toDate().getTime() - Moment(a.created_at).toDate().getTime()
            );

            this.setState({
                loadingCalls: false,
                calls,
            });
        } catch(err){
            this.setState({
                loadingCalls: false,
            });

            alert(err.message);
        }
    };

    getAlias = () => {
        const {professional} = this.props;
        return `${professional.type}-onboarding`;
    }

    fetchOnboard = async () => {
        try {
            this.setState({
                loadingOnboard: true,
            });

            const responseQuiz = await quizResource.getQuiz(this.getAlias());

            let quiz = null
            if (responseQuiz.status >= 200 && responseQuiz.status < 300) {
                quiz = responseQuiz.data[0];
            }

            const responseAssessment = await quizResource.getAssessment(
                this.getAlias(),
                this.state.patient.id,
            );

            let assessment = null;
            if (responseAssessment.status >= 200 && responseAssessment.status < 300) {
                assessment = responseAssessment.data[0];
            }

            this.setState({
                loadingOnboard: false,
                quiz,
                assessment,
                answers: assessment?.answers || []
            });
        } catch(err){
            this.setState({
                loadingOnboard: false,
            });

            alert(err.message);
        }
    };

    getPatient = async (id) => {
        try {
            this.setState({
                loading: true,
            });

            let responseGetPatient = await patient.get(
              id,
              this.props.token,
            );

            this.setState({
                loading: false,
                patient: responseGetPatient.data,
            }, () => {
                this.fetchSchedules();
                this.fetchCalls();
                this.fetchOnboard();
            });

        } catch(err){
            console.log(err);

            this.setState({
                loading: false,
            });
        }
    };

    displayAge(birthDate) {
        const birthdate = Moment(birthDate);
        const now = Moment();

        const diff = Moment.preciseDiff(birthdate, now);

        return `${diff.split(' ')[0]} anos e ${diff.split(' ')[2]} meses`;
    }

    onCall = (appointment) => {
        this.props.dispatch(
            actionCreatorsVideoCall.callStart(
                appointment.id,
                this.props.user._id,
                appointment.patient.id,
                appointment.patient
            )
        );
    }

    onWhatsapp = () => {
        const {appointments} = this.state;
        const {user} = this.props;
        window.open(`https://api.whatsapp.com/send?phone=+${appointments[0].patient.msisdn}&text=Olá ${appointments[0].patient.name.split(' ')[0]}, eu sou ${user.name.split(' ')[0]} do bem.care. Pronto para começar o nosso atendimento? Por favor entre no seu app bem.care e vamos lá!`);
    }

    render(){
        const {filteredAppointments, firstAppointment} = this.state;
        
        return (
            <Layout>
                <div>
                    <div>
                        {this.state.loading && <div style={{marginTop: 40}}>
                            <Loader/>
                        </div>}
                        {!this.state.loading && <div>
                            <div style={{display: 'flex'}}>
                                <div style={{flex: 1}}>
                                    <h1 style={{fontWeight: 'bold', textAlign: 'left', marginBottom: 20, color: '#9C0D4F', fontSize: 22}}>
                                        <span style={{borderBottom: '2px solid #9C0D4F', paddingBottom: 3,}}><img alt={"Pacientes"} src={PatientRed} style={{width: 26, marginBottom: -3, marginRight: 8}} />
                                        Pacientes</span> <span style={{fontSize: 16}}>/ {this.state.patient.name}</span>
                                    </h1>
                                </div>
                                <div style={{flex: 0, display: 'flex', flexDirection: 'row'}}>
                                    <div style={{flex: 0, marginLeft: 7}}>
                                        {this.state.appointmentToday && this.state.appointments && this.state.appointments.length > 0 && 
                                            <div style={{display: 'flex'}}>
                                                <button
                                                    style={{
                                                        border: 'none',
                                                        margin: 0,
                                                        outline: 'none',
                                                        cursor: "pointer",
                                                        background: '#28A745',
                                                        padding: 0,
                                                        borderRadius: 5,
                                                        minWidth: 32,
                                                        minHeight: 32,
                                                        marginLeft: 7
                                                    }}
                                                    onClick={() => {
                                                        this.onCall(this.state.appointments[0]);
                                                    }}
                                                >
                                                    <Icon
                                                        path={mdiVideo}
                                                        title="Editar"
                                                        size={0.7}
                                                        color="#fff"
                                                        style={{
                                                            marginLeft: 0,
                                                            marginTop: 3
                                                        }}
                                                    />
                                                </button>
                                                <button
                                                    style={{
                                                        border: 'none',
                                                        margin: 0,
                                                        outline: 'none',
                                                        cursor: "pointer",
                                                        background: '#28A745',
                                                        padding: 0,
                                                        borderRadius: 5,
                                                        minWidth: 32,
                                                        minHeight: 32,
                                                        marginLeft: 7
                                                    }}
                                                    onClick={this.onWhatsapp}
                                                >
                                                    <Icon path={mdiWhatsapp}
                                                        title="Ligar"
                                                        size={0.9}
                                                        color="#FFFFFF"
                                                        style={{
                                                            marginLeft: 0,
                                                            marginTop: 3
                                                        }}
                                                    />
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div style={{display: 'flex'}}>
                                <div style={{flex: 1, textAlign: 'center', marginRight: 30}}>
                                    <div style={{display: 'flex', marginBottom: 40}}>
                                        <div style={{flex: 0, marginTop: 3, marginRight: 20, display: 'flex', justifyContent: 'flex-start'}}>
                                            <Icon
                                                path={mdiGenderMaleFemale}
                                                title="Gênero"
                                                size={1}
                                                color="#676767"
                                            />
                                            <span style={{marginLeft: 5, marginTop: 5, fontWeight: 'bold', color: '#676767', fontSize: 14}}>{this.state.patient.gender === 'M' ? 'Masculino' : 'Feminino'}</span>
                                        </div>
                                        <div style={{flex: 1, marginTop: 3, display: 'flex', justifyContent: 'flex-start', marginLeft: 10}}>
                                            <Icon
                                                path={mdiCakeVariant}
                                                title="Idade"
                                                size={1}
                                                color="#676767"
                                            />
                                            <span style={{marginTop: 5, marginLeft: 5, fontWeight: 'bold', color: '#676767', fontSize: 14}}>{this.displayAge(this.state.patient.birthdate)}</span>
                                        </div>
                                    </div>
                                    <div className={"box"} style={{textAlign: 'left', marginBottom: 30}}>
                                        <div style={{}}>
                                            <div style={{fontWeight: '600', fontSize: 14, color: '#666'}}>SEU PACIENTE DESDE</div>
                                            <p style={{fontSize: 32, color: '#373737'}}>{Moment(firstAppointment[0]?.start).format('DD [de] MMMM [de] YYYY')}</p>
                                        </div>
                                        <div style={{marginTop: 20, display: 'flex'}}>
                                            <div style={{marginRight: 20}}>
                                                <div style={{fontWeight: '600', fontSize: 14, color: '#666'}}>SESSÕES</div>
                                                <p style={{fontSize: 32, color: '#373737', textAlign: 'center'}}>{this.state.appointments.length}</p>
                                            </div>
                                            <div style={{marginRight: 20}}>
                                                <div style={{fontWeight: '600', fontSize: 14, color: '#666'}}>CANCELAMENTOS</div>
                                                <p style={{fontSize: 32, color: '#373737', textAlign: 'center'}}>{this.state.appointments.filter(item => item.status === "canceled").length}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={'box'} style={{textAlign: 'left', marginBottom: 30}}>
                                        <h3 style={{fontSize: 24}}>Onboarding</h3>

                                        <div style={{marginTop: 18}}>
                                            <div style={{display: 'flex', flexDirection: 'row', borderBottom: '1px solid #AFAFAF', paddingBottom: 12, marginBottom: 0}}>
                                                <div style={{flex: 1}}>
                                                    <p style={{fontSize: 14, color: '#666', fontWeight: 'bold'}}>
                                                        Questão
                                                    </p>
                                                </div>
                                                <div style={{flex: 0, display: 'flex'}}>
                                                    <p style={{fontSize: 14, color: '#666', fontWeight: 'bold'}}>
                                                        Resposta
                                                    </p>
                                                </div>
                                            </div>

                                            {this.state.loadingOnboard && <div style={{marginTop: 0}}>
                                                <Loader/>
                                            </div>}

                                            {!this.state.loadingOnboard && this.state.answers && this.state.answers.length > 0 && this.state.answers.map((item, index) => {
                                                const {quiz} = this.state;
                                                const question = quiz.questions.filter((_, idx) => idx === item.index)[0];
                                                
                                                let description = '';
                                                const getDescription = (question, value) => {
                                                    if (question.config?.options) {
                                                        const option = question.config?.options.filter(option => option.value === value)[0];
                                                        return option ? option.title : value;
                                                    }

                                                    const unit = question.config?.unit ? question.config.unit : '';
                                                    return `${value}${unit}`;
                                                }

                                                if (Array.isArray(item.value)) {
                                                    description = item.value.map(value => getDescription(question, value)).join(',');
                                                } else {
                                                    description = getDescription(question, item.value);
                                                }
                                                

                                                return(
                                                    <div key={index} style={{marginTop: 18, marginBottom: 6, paddingBottom: 12, display: 'flex', flexDirection: 'row', borderBottom: index < this.state.answers.length -1 ? '1px solid #AFAFAF' : 'none'}}>
                                                        <div style={{flex: 1}}>
                                                            <p style={{fontSize: 14, color: '#666'}}>
                                                                {question.title}
                                                            </p>
                                                        </div>
                                                        <div style={{flex: 0, display: 'flex'}}>
                                                            <p style={{fontSize: 14, color: '#666', fontWeight: 'bold'}}>
                                                                {description}
                                                            </p>
                                                        </div>
                                                    </div>
                                                );
                                            })}

                                            {!this.state.loadingOnboard && (!this.state.quiz || this.state.quiz.length === 0 || !this.state.answers || this.state.answers.length === 0) && <p style={{marginTop: 12}}>Onboard não preenchido.</p>}
                                        </div>
                                    </div>
                                </div>
                                <div style={{flex: 1}}>
                                    <div className={'box'} style={{textAlign: 'left', marginBottom: 30}}>
                                        <h3 style={{fontSize: 24}}>Agenda</h3>

                                        {this.state.loadingAppointments && <div style={{marginTop: 18}}>
                                            <Loader/>
                                        </div>}
                                        
                                        {!this.state.loadingAppointments && this.state.filteredAppointments.map((item, index) => {
                                            let shownCall = false;
                                            if (item.status === 'canceled') {
                                                return '';
                                            }

                                            const isFirstInTheFuture = () => {
                                                if (
                                                    (new Date(item.start).getTime() > new Date().getTime()) &&
                                                    !shownCall
                                                ){
                                                    shownCall = true;

                                                    return true;

                                                }else{
                                                    return false
                                                }
                                            };

                                            return(
                                                <div key={index} style={{marginTop: 18, display: 'flex', flexDirection: 'row'}}>
                                                    <div style={{flex: 1}}>
                                                        <p style={{fontSize: 16, color: isFirstInTheFuture() ? '#FC1482' : '#666'}}>
                                                            {Moment(item.start).isSame(new Date(), "day") ? Moment(item.start).format("[Hoje,] HH:mm") :
                                                                Moment(item.start).format("DD [de] MMMM[,] dddd[,] HH:mm")}
                                                        </p>
                                                    </div>
                                                    <div style={{flex: 0, display: 'flex'}}>
                                                        {isFirstInTheFuture() && <span style={{marginRight: 12, display: 'flex'}}>
                                                            <button
                                                                onClick={() => {
                                                                    this.onCall(item)
                                                                }}
                                                                style={{
                                                                    marginRight: 6,
                                                                    backgroundColor: '#28A745',
                                                                    borderRadius: '50%',
                                                                    border: 'none',
                                                                    // padding: 3,
                                                                    width: 26,
                                                                    height: 26,
                                                                    paddingTop: 4,
                                                                    paddingLeft: 1,
                                                                    marginTop: -3,
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                <Icon path={mdiVideo}
                                                                      title="Ligar"
                                                                      size={.65}
                                                                      color="#FFFFFF"
                                                                />
                                                            </button>
                                                            <button
                                                                onClick={() => {
                                                                    this.onCall(item)
                                                                }}
                                                                style={{
                                                                    marginRight: 6,
                                                                    backgroundColor: '#28A745',
                                                                    borderRadius: '50%',
                                                                    border: 'none',
                                                                    // padding: 3,
                                                                    width: 26,
                                                                    height: 26,
                                                                    paddingTop: 4,
                                                                    paddingLeft: 1,
                                                                    marginTop: -3,
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                <Icon path={mdiPhone}
                                                                      title="Ligar"
                                                                      size={.65}
                                                                      color="#FFFFFF"
                                                                />
                                                            </button>
                                                        </span>}
                                                        {/* <Icon
                                                            path={mdiDotsHorizontal}
                                                            title="Menu"
                                                            size={1}
                                                            color="#676767"
                                                            style={{
                                                                // marginLeft: 0,
                                                                // marginTop: 3
                                                            }}
                                                        /> */}
                                                    </div>
                                                </div>
                                            );
                                        })}

                                        {!this.state.loadingAppointments && this.state.filteredAppointments.length === 0  && <p style={{marginTop: 12}}>Sem agendamentos cadastrados.</p>}
                                    </div>

                                    <CallHistory patientID={this.state.patient.id}/>

                                    <div style={{textAlign: 'left', marginBottom: 30}}>
                                        <DocumentWidget patientId={this.state.patient.id}/>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </Layout>
        )
    }
}

export default connect(mapStateToProps)(withRouter(PatientDetail));
