import React from 'react';
import {ResponsiveBar} from '@nivo/bar';
import {
    graphsDashboard
} from './GraphsDashboard.module.scss';


export default ({ data }) => {

    let keysLegend = [];

    for(let i = 0; i < data.length; i++){
        let allKeyOptions = Object.keys(data[i]);
        let allValueOptions = Object.values(data[i]);

        for(let j = 0; j < allValueOptions.length; j++){
            if(typeof allValueOptions[j] === "number"){
                keysLegend.push(allKeyOptions[j]);
            }
        }
    }

    const colors = {
        livre: "#e0dede",
        starter: "#063852",
        flex: "#F0810F",
        premium: "#E6DF44",
        yes: "#BA44E6",
        no: "#44AEE6"
    }

    const getColor = bar => colors[bar.id];

    keysLegend = [...new Set(keysLegend)];

    return(
        <div className={graphsDashboard}>
            <ResponsiveBar
                data={data}
                    keys={[...keysLegend]}
                    indexBy="date"
                    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                    padding={0.8}
                    maxValue={'auto'}
                    colors={{scheme: 'nivo'}, getColor}
                    defs={[
                        {
                            id: 'dots',
                            type: 'patternDots',
                            background: 'inherit',
                            color: '#38bcb2',
                            size: 4,
                            padding: 1,
                            stagger: true
                        },
                        {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: '#eed312',
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10
                        }
                    ]}
                    borderColor={{ theme: 'background' }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: -45,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 50
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: -40
                    }}
                    labelSkipWidth={36}
                    labelSkipHeight={12}
                    labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'bottom',
                            direction: 'row',
                            justify: false,
                            translateX: 15,
                            translateY: 80,
                            itemsSpacing: 10,
                            itemWidth: 70,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 20,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                    animate={true}
                    motionStiffness={70}
                motionDamping={15}
            />
        </div>
    );
} 
