import React, { Component } from 'react';
import {connect} from "react-redux";
import { Multiselect } from 'multiselect-react-dropdown';
import categoryResource from "../../resources/category";

const mapStateToProps = store => {
  return {
    user: store.authReducer.user,
    token: store.authReducer.token,
}};

class ProfessionalCategory extends Component {
    constructor(props){
        super(props);
        this.state = {};
    }

    formatOption(category) {
      return {
        label: category.label,
        id: category.id
      }
    }

    async componentDidMount() {
      const {professional} = this.props
      if (!professional) return;
      const categories = await categoryResource.fetch(
        this.props.token,
        professional.type
      ) || [];

      this.setState({
        categories,
        professional,
        options: categories.map(this.formatOption),
        selectedValues: professional.categories.map(this.formatOption)
      });
    }

    onSelect = async (selected, itemClicked) => {
      const {token, professional} = this.props
      await categoryResource.associate(
        token,
        professional.id,
        itemClicked.id
      )
    }

    onRemove = async (selected, itemClicked) => {
      const {token, professional} = this.props
      await categoryResource.desassociate(
        token,
        professional.id,
        itemClicked.id
      )
    }

    render() {
      const title = "Categorias";
      return (
        <div className={"box"} style={{marginBottom: 30}}>
          <div style={{display: 'flex'}}>
            <div style={{flex: 1}}>
                <h3 style={{fontSize: 24}}>Categorias</h3>
            </div>
          </div>
          <Multiselect
            options={this.state.options}
            selectedValues={this.state.selectedValues}
            onSelect={this.onSelect}
            onRemove={this.onRemove}
            displayValue={'label'}
            placeholder={title}
          />
        </div>
      )
    }
}

export default connect(mapStateToProps)(ProfessionalCategory);


