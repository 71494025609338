import React from "react";
import { Link } from "react-router-dom";
import Layout from "./Layout";

const NotFoundPage = () => {
	return <Layout>
		<div id="notFound">
			<h1>
				404
				<aside>Você se perdeu, e visitou uma página que não existe.</aside>
			</h1>
			<p>Volte para a <Link to="/">primeira página</Link>.</p>
		</div>
	</Layout>
};

export default NotFoundPage;