import React, {Component} from 'react';
import './App.scss';
import './styles/calendar.scss';
import VideoCallContainer from './containers/video-call';
import PhoneCallContainer from './containers/phone-call';
import RouterContainer from './containers/router';
import Logout from './screens/logout';
import Login from './screens/login';
import ResetConfirm from './screens/resetConfirm';
import Reset from './screens/reset';
import ChatList from './screens/chatlist';
import Dashboard from './screens/dashboard';
import Professionals from './screens/professionals';
import Patients from './screens/patients';
import Root from './screens/root';

import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import ReduxPromise from 'redux-promise';

import moment from 'moment';

import combinedReducers from './reducers/index';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Schedule from "./components/Schedule";
import ScheduleDetail from "./components/ScheduleDetail";
import ProfessionalDetail from "./screens/professionalDetail";
import PatientDetail from "./screens/patientDetail";
import Profile from './components/Profile';
import './styles/notFound.scss';
import * as Sentry from '@sentry/browser';
import SentryConfig from './configs/sentry';
import NotFoundPage from "./components/NotFoundPage";
import ProtectedAdminRoute from "./components/ProtectedAdminRoute";

moment.locale('pt-BR');

Sentry.init({
  dsn: SentryConfig.DSN,
  environment: SentryConfig.ENV,
  release: SentryConfig.RELEASE,
});

const middlewares = [ReduxPromise, thunk];
const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore);
const store = compose(createStoreWithMiddleware)(combinedReducers);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <div className="app">
          <main>
            <Router>
              <RouterContainer>
                <Switch>
                  <Route exact path="/">
                    {/*{!type ? <div>EMPTY</div> : (type && type === "admin" ? <Dashboard /> : <Schedule />)}*/}
                    <Root/>
                  </Route>
									<ProtectedAdminRoute exact path="/dashboard" component={Dashboard} />
                  <Route exact path="/login">
                    <Login />
                  </Route>
                  <Route exact path="/redefinir">
                    <Reset />
                  </Route>
                  <Route exact path="/confirmar/:code">
                    <ResetConfirm />
                  </Route>
                  <Route exact path="/agendamento">
                    <Schedule />
                  </Route>
                  <Route exact path="/agendamento/:id">
                    <ScheduleDetail />
                  </Route>
									<ProtectedAdminRoute exact path="/profissionais" component={Professionals} />
									<ProtectedAdminRoute exact path="/profissional/:id" component={ProfessionalDetail} />
                  <Route exact path="/agenda">
                    <Schedule/>
                  </Route>
                  <Route exact path="/pacientes">
                    <Patients/>
                  </Route>
                  <Route exact path="/paciente/:id">
                    <PatientDetail />
                  </Route>
                  <Route exact path="/perfil">
                    <Profile/>
                  </Route>
                  <Route path="/phone">
                    <PhoneCallContainer />
                  </Route>
                  <Route path="/video">
                    <VideoCallContainer />
                  </Route>
                  <Route path="/chat">
                    <ChatList />
                  </Route>
                  <Route path="/logout">
                    <Logout />
                  </Route>
                  <Route path="404" component={NotFoundPage} />
                  <Route component={NotFoundPage} />
                </Switch>
              </RouterContainer>
            </Router>
          </main>
        </div>
      </Provider>
    );
  }
}

export default App;
