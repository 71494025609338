import React, { Component } from 'react';
import {textAreaInput} from "./TextArea.module.scss";
import {input} from "../Input/Input.module.scss";

export default class TextArea extends Component {
    render() {
        return (
            <div className={textAreaInput} style={this.props.styleContainer}>
                {this.props.label ? <label htmlFor={this.props.name} style={{...this.props.styleLabel}}>{this.props.label}</label> : ''}
                <textarea
                    id={this.props.id}
                    name={this.props.name}
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    style={this.props.style}
                    onChange={this.props.onChange}
                    onKeyDown={this.props.onKeyDown}
                    readOnly={this.props.readOnly || false}
                    disabled={this.props.disabled || false}
                    required={this.props.required}
                />
            </div>
        );
    }
}
