import React, { Component } from 'react';
import {connect} from "react-redux";
import unavailabilityResource from "../../resources/unavailability";
import DatePicker from 'react-date-picker';
import TimePicker from 'react-time-picker';
import SendButton from '../SendButton';
import {actions} from '../../reducers/unavailability'
import * as moment from 'moment';
import './style.scss';

const mapStateToProps = store => {
  return {
    user: store.authReducer.user,
    token: store.authReducer.token,
    unavailabilityList: store.unavailabilityListReducer
}};

class CreateUnavailability extends Component {
    constructor(props){
        super(props);
        this.initialState = {
          dateStart: null,
          timeStart: null,
          dateEnd: null,
          timeEnd: null
        }
        this.state = this.initialState;
    }

    onChangeDateStart = async (value) => {
      this.setState({dateStart: value})
      this.setState({dateEnd: value})
    }

    onChangeTimeStart = async (value) => {
      if (!value) return;
      const [hour, minute] = value.split(':')
      this.setState({timeStart: value})
      this.setState({
        from: moment(this.state.dateStart).set({
          hour,
          minute,
        }).toISOString()
      })
    }

    onChangeDateEnd = async (value) => {
      this.setState({dateEnd: value})
    }

    onChangeTimeEnd = async (value) => {
      if (!value) return;
      const [hour, minute] = value.split(':')
      this.setState({timeEnd: value})
      this.setState({
        to: moment(this.state.dateEnd).set({
          hour,
          minute,
        }).toISOString()
      })
    }

    onSubmit = async () => {
      const {from, to} = this.state
      if (!from || !to) { return; }
      const created = await unavailabilityResource.create(this.props.token, {
        from, to, scheduleId: this.props.professional.scheduleID
      })
      if (created) {
        this.props.dispatch(actions.push(created))
        this.setState(this.initialState)
      }
    }

    render() {
      return (
        <>
          <div style={{textAlign: 'center', marginTop: 10}}>
            <label>De: </label>
            <DatePicker
              value={this.state.dateStart}
              onChange={this.onChangeDateStart}
              dateFormat="DD/MM/YYYY"
              clearIcon={null}
              minDate={new Date()}
            />
            <TimePicker 
              onChange={this.onChangeTimeStart}
              value={this.state.timeStart}
              disableClock={true}
              format={'H:m'}
            />
          </div>
          <div style={{textAlign: 'center', marginTop: 10}}>
            <label>Até: </label>
            <DatePicker
              value={this.state.dateEnd}
              onChange={this.onChangeDateEnd}
              dateFormat="DD/MM/YYYY"
              clearIcon={null}
              minDate={this.state.dateStart}
            />
            <TimePicker 
              onChange={this.onChangeTimeEnd}
              value={this.state.timeEnd}
              disableClock={true}
              format={'H:m'}
            />
          </div>
          <div style={{textAlign: 'center'}}>
            <SendButton
              style={{margin: 'auto', marginTop: 15}}
              onClick={this.onSubmit}
            >
              Criar
            </SendButton>
          </div>
        </>
      )
    }
}

export default connect(mapStateToProps)(CreateUnavailability);


