// The types of actions that you can dispatch to modify the state of the store
import userResource from '../resources/user';

export const types = {
    LOGOUT: 'LOGOUT',
    SET_IS_LOGGED: 'SET_IS_LOGGED',
    SET_IS_LOADED: 'SET_IS_LOADED',
    SET_IS_LOADING: 'SET_IS_LOADING',
    UPDATE_PROFILE: 'UPDATE_PROFILE',
};

// Helper functions to dispatch actions, optionally with payloads
export const actionCreators = {
    logout: () => async (dispatch, getState) => {
        localStorage.removeItem('token');
        localStorage.removeItem('type');

        dispatch({
            type: types.LOGOUT
        });
    },
    setIsLogged: (token) => async (dispatch, getState) => {
        try {
            dispatch({
                type: types.SET_IS_LOGGED,
                payload: token
            });
        } catch(err) {
            console.log(err);

            alert(err.message);
        }
    },
    updateProfile: ({user, professional}) => async (dispatch, getState) => {
        const response = await userResource.updateProfile({
            user: user,
            professional: getState().authReducer.user.type === "professional" ? professional : undefined,
        },
        getState().authReducer.token);

        console.log(response);
        if (! response.data.success) {
            throw new Error(response.data.message);
        }

        dispatch({
            type: types.UPDATE_PROFILE,
            payload: {
                user: {
                    ...getState().authReducer.user,
                    ...user,
                },
                professional: {
                    ...getState().authReducer.professional,
                    ...professional,
                },
            },
        });
    },
    load: () => async (dispatch, getState) => {
        try {
            dispatch({
                type: types.SET_IS_LOADING
            });

            let response = await userResource.load(getState().authReducer.token);
            console.log(response);
            if (!response.data.success) {
                throw new Error(response.data.message);
            }

            dispatch({
                type: types.SET_IS_LOADED,
                payload: response.data
            });
        } catch(err) {
            console.log(err);

            dispatch({
                type: types.SET_IS_LOADED,
                payload: null,
                error: err.message
            });
        }
    },

};

// Initial state of the store
const initialState = {
    isLoaded: false,
    isLoading: false,
    loadError: null,
    isLogged: null,
    token: null,
    user: null,
    type: null,
    professional: null,
};

export default function auth(state = initialState, action) {
    const {
        payload,
        type,
        error,
    } = action;

    switch (type) {
        case types.LOGOUT: {
            return {
                ...initialState,
                isLogged: false
            };
        }
        case types.SET_IS_LOGGED: {
            return {
                ...state,
                isLogged: !!payload,
                token: payload || null,
                // email: payload.email,
                // password: payload.password,
            };
        }
        case types.SET_IS_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case types.UPDATE_PROFILE: {
            return {
                ...state,
                user: payload.user,
                professional: payload.professional,
            };
        }
        case types.SET_IS_LOADED: {
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                user: payload ? payload.user : null,
                professional: payload ? payload.professional : null,
                loadError: error,
            };
        }
        default:
            return state;
    }
};
