import apiConfig from '../configs/api'
import requestUtils from "../utils/request";

export default {
    callStart(idAppointment, idUserCaller, idUserReceiver, type, name, registry, token) {
        return requestUtils({
            url: `${apiConfig.API}/api/v1/call/start/${idAppointment}/${idUserCaller}/${idUserReceiver}`,
            method: 'POST',
            token,
            data: {
                type,
                name,
                registry
            },
        });
    },
    callEnd(idCall, idUser, token) {
        return requestUtils({
            url: `${apiConfig.CALL_API}/api/v1/call/end/${idCall}/${idUser}`,
            method: 'PUT',
            token
        });
    },
    callCancel(idCall, idUser, token) {
        return requestUtils({
            url: `${apiConfig.CALL_API}/api/v1/call/cancel/${idCall}/${idUser}`,
            method: 'PUT',
            token
        });
    },
    callAccept(idCall, idUser, name, token) {
        return requestUtils({
            url: `${apiConfig.CALL_API}/api/v1/call/accept/${idCall}/${idUser}`,
            method: 'PUT',
            token,
            data: {
                name
            }
        });
    },
    callReject(idCall, idUser, token) {
        return requestUtils({
            url: `${apiConfig.CALL_API}/api/v1/call/reject/${idCall}/${idUser}`,
            method: 'PUT',
            token
        });
    },
    getPhoneCallToken(token) {
        return requestUtils({
            url: `${apiConfig.API}/api/v1/call/phone/token`,
            method: 'POST',
            token
        });
    },
    getUserCalls(idUser, token) {
        return requestUtils({
            url: `${apiConfig.CALL_API}/api/v1/user/${idUser}/calls`,
            method: 'GET',
            token
        });
    },
};
