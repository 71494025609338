export default {
    KEY: '2uNiA89LvMRQvF7dcQPd3TgBMNG2QceL',
    API: 'https://telehealth-dot-care-bem-app.appspot.com/',
    JOY_API: 'https://joy-api-v2-dot-care-bem-app.appspot.com/',
    SCHEDULE_API: 'https://schedule-dot-care-bem-app.appspot.com/',
    QUIZ_API: 'https://quiz-dot-care-bem-app.appspot.com/',
    CALL_API: 'https://webcall-dot-care-bem-app.appspot.com/',
    SOCKET_URL: 'https://websocket-dot-care-bem-app.appspot.com/',
    CHAT_API: 'https://webchat-dot-tutorial-232302.appspot.com',
};
