export const types = {
    PHONE_CALL_START: 'PHONE_CALL_START',
    PHONE_CALL_CLEAR: 'PHONE_CALL_CLEAR',
};

export const actionCreators = {
    callStart: (idAppointment, idUserCaller, idUserReceiver, patientInfo) => async (dispatch, getState) => {
        dispatch({
            type: types.PHONE_CALL_START,
            payload: {
                patientInfo,
                callInfo: {
                    idAppointment,
                    idUserCaller,
                    idUserReceiver,
                    patientInfo,
                    professionalRegistry: getState().authReducer.professional.registry,
                    professionalType: getState().authReducer.professional.type,
                    userName: getState().authReducer.user.name,
                    token: getState().authReducer.token,
                },
            }
        });
    },
    callEnd: () => async (dispatch, getState) => {
        dispatch({
            type: types.PHONE_CALL_CLEAR
        });
    }
};

const initialState = {
    status: 'idle', //idle, calling, accepted
    error: null,
    patientInfo: null,
    callInfo: null,
};

export default function call(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case types.PHONE_CALL_START: {
            return {
                ...state,
                status: "calling",
                callInfo: payload.callInfo,
                patientInfo: payload.patientInfo,
            };
        }
        case types.PHONE_CALL_CLEAR: {
            return initialState;
        }
        default:
            return state;
    }
}
