
import React, { Component } from 'react';

import Logo from '../images/logo.svg';
import Button from '../components/Button';
import Input from '../components/Input';

import { actionCreators as actionCreatorsAuth } from '../reducers/auth';

import userResource from '../resources/user';

import { connect } from 'react-redux';
import { withRouter } from "react-router";
import Layout from "../components/Layout";
import {NavLink} from "react-router-dom";


const mapStateToProps = (state) => ({
    isLogged: state.authReducer.isLogged,
    isLogging: state.authReducer.isLogging,
    error: state.authReducer.error,
});

class ResetConfirm extends Component {
    constructor(props){
        super(props);

        this.state = {
            loading: false,
            code: '',
            password: '',
        }
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        this.setState({
            code: params.code
        });
    }

    onChangePassword = (event) => {
        this.setState({
            password: event.target.value
        });
    };

    onSubmit = async (event) => {
        event.preventDefault();

        this.setState({loading: true});
        try {
            if(!this.state.password) {
                throw new Error('Preencha os campos');
            }

            console.log(this.state);
            const response = await userResource.resetConfirm({
                token: this.state.code,
                password: this.state.password,
            });

            if (! response.data.success) {
                throw new Error(response.data.message);
            }

            this.setState({
                loading: false,
            });

            await localStorage.setItem('token', response.data.token);

            this.props.dispatch(actionCreatorsAuth.setIsLogged(response.data.token));

            this.props.history.push('/');
        } catch(err){
            console.log(err);

            this.setState({
                loading: false,
            });

            alert(err.message);
        }
    }

    render(){
        return (
            <Layout
                style={{padding: 0}}
                title={"Redefinir senha"}
                description={"Digite sua nova senha."}
            >
                <div style={{width: 350, marginTop: 140}}>
                    <div className={"auth-box"} style={{textAlign: 'center', padding: 30}}>
                        <form style={{textAlign: "center"}} onSubmit={this.onSubmit}>
                            <Input id={"password"} name={"password"} type={"password"} placeholder={"Nova senha"} style={{width: "100%"}} onChange={this.onChangePassword} value={this.state.password}/>

                            <Button text={"REDEFINIR"} type={"submit"} style={{width: 150}}/>

                            <div style={{marginTop: 40, textAlign: "left", marginBottom: 30}}>
                                <NavLink to="/login" exact className={"link"} style={{fontSize: 14, fontWeight: '600', color: '#FC1481', textDecoration: 'underline'}}>
                                    Voltar para login.
                                </NavLink>
                            </div>
                        </form>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default connect(mapStateToProps)(withRouter(ResetConfirm));
