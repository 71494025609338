import requestUtils from '../utils/request';
import querystring from 'querystring';

import apiConfig from '../configs/api';

export default {
    fetch(token, type = null, status = null) {
        return requestUtils({
            url: apiConfig.API+'/professional?'+(type ? "type="+type : "")+(status ? "&status="+status : ""),
            method: 'GET',
            token,
        });
    },
    get(token, id) {
        return requestUtils({
            url: apiConfig.API+'/professional/'+id,
            method: 'GET',
            token,
        });
    },
    create(token, body) {
        return requestUtils({
            url: apiConfig.API+'/professional',
            method: 'POST',
            data: body,
            token,
        });
    },
    update(token, id, body) {
        return requestUtils({
            url: apiConfig.API+'/professional/'+id,
            method: 'PUT',
            data: body,
            token,
        });
    },
    uploadAvatar(token, id, avatar) {
        let body = new FormData();
        body.append('file', avatar);
        body.append('mime', 'image/jpeg');

        return requestUtils({
            url: apiConfig.API+'/professional/'+id+'/avatar',
            method: 'POST',
            data: body,
            token,
            header: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
};
