import React  from 'react';
import { Route } from 'react-router-dom';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import NotFoundPage from "./NotFoundPage";

const mapStateToProps = store => ({
	user: store.authReducer.user,
	token: store.authReducer.token,
	professional: store.authReducer.professional,
	loading: store.chatReducer.loading,
	fetching: store.chatReducer.fetching,
	messages: store.chatReducer.messages,
	hasMoreMessages: store.chatReducer.hasMoreMessages,
});

const ProtectedAdminRoute = ({ component: Component, ...rest }) => {
	const ProtectedProps = { ...rest };
	return ProtectedProps.user.type === 'admin'
		? <Route {...rest} render={props => <Component {...rest} {...props} /> } />
		: <NotFoundPage />;
}

export default connect(mapStateToProps)(withRouter(ProtectedAdminRoute));
