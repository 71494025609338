import React, {Component} from 'react';

import Icon from '@mdi/react'
import {
    mdiPaperclip, mdiCheck, mdiCheckAll, mdiMicrophone, mdiSend, mdiStop, mdiClose
} from '@mdi/js'

import {connect} from "react-redux";
import Loader from "../components/Loader";
import chatResource from '../resources/chat'

import Moment from 'moment'
import {withRouter} from "react-router";
import SendButton from "../components/SendButton";
import Input from "../components/Input";
import colors from "../styles/colors.module.scss";
import {actionCreators as actionCreatorsChat} from '../reducers/chat';

import MicRecorder from 'mic-recorder-to-mp3';
const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const mapStateToProps = store => ({
    user: store.authReducer.user,
    token: store.authReducer.token,
    professional: store.authReducer.professional,
    loading: store.chatReducer.loading,
    fetching: store.chatReducer.fetching,
    messages: store.chatReducer.messages,
    hasMoreMessages: store.chatReducer.hasMoreMessages,
});

class Chat extends Component {

    constructor(props) {
        super(props);

        this.state = {
            // loading: true,
            // fetching: true,
            sending: false,
            // hasMoreMessages: true,
            // messages: [],
            text: '',
            uploadingFileName: null,

            //record
            recording: false,
            duration: 0,
            blobURL: ''
        };

        this.scroll = React.createRef();
        this.timer = null
    }

    componentDidMount() {
        this.props.dispatch(actionCreatorsChat.clear());

        this.fetchMessages();
    }

    componentWillUnmount() {
        clearInterval(this.timer);

        this.props.dispatch(actionCreatorsChat.clear());
    }

    componentDidUpdate(prevState, prevProps){
        if(this.state.scrollTop < 100 && !this.props.fetching && this.props.hasMoreMessages){
            this.fetchMessages();
        }
    }

    fetchMessages = async () => {
        this.props.dispatch(actionCreatorsChat.fetch(this.props.userTarget));

        // try {
        //     this.setState({
        //         fetching: true,
        //     });
        //
        //     const timestamp = this.props.messages.length > 0 ? this.props.messages[this.props.messages.length-1].created_at : new Date();
        //
        //     let response = await chatResource.fetch(this.props.user._id, this.props.userTarget, timestamp, this.props.token);
        //     console.log(response);
        //
        //     if (!response.data.success) {
        //         throw new Error(response.data.message);
        //     }
        //
        //     this.setState({
        //         loading: false,
        //         fetching: false,
        //         messages: [
        //             ...this.props.messages,
        //             ...response.data.messages
        //         ],
        //         hasMoreMessages: response.data.messages.length > 0
        //     });
        //
        //     let messageIds = response.data.messages.filter(message => message.user_to === this.props.user._id).map(message => message._id);
        //     if(messageIds.length > 0){
        //         this.readMessages(messageIds);
        //     }
        // } catch(err){
        //     console.log(err);
        //
        //     this.setState({
        //         fetching: false,
        //     });
        //
        //     alert(err.message);
        // }
    };

    // readMessages = async (messageIds) => {
    //     try {
    //         let response = await chatResource.readArray(messageIds, this.props.token);
    //         console.log(response);
    //
    //         if (!response.data.success) {
    //             throw new Error(response.data.message);
    //         }else{
    //             this.setState({
    //                 messages: this.props.messages.map(message => {
    //                     return {
    //                         ...message,
    //                         status: messageIds.includes(message._id) ? 'read' : message.status
    //                     }
    //                 })
    //             });
    //         }
    //     } catch(err){
    //         console.log(err);
    //     }
    // };

    sendMessage = async (text = this.state.text, type = "text", fileKey = undefined, fileSize = undefined) => {
        try {
            this.setState({
                // loading: false,
                sending: true,
            });

            if(!String(text).trim()){
                throw new Error("Escreva a mensagem.")
            }

            let response = await chatResource.create(
                {
                    content: String(text).trim(),
                    userFrom: this.props.user._id,
                    userTo: this.props.userTarget,
                    type: type,
                    contentKey : fileKey,
                    fileSize
                },
                this.props.token
            );
            console.log(response);

            if (!response.data.success) {
                throw new Error(response.data.message);
            }

            this.props.dispatch(actionCreatorsChat.addMessage(response.data.newMessage));

            this.setState({
                sending: false,
                text: '',
                // messages: [
                //     response.data.newMessage,
                //     ...this.props.messages,
                // ]
            });
        } catch(err){
            console.log(err);

            this.setState({
                sending: false,
            });

            alert(err.message);
        }
    };

    onChangeText = (event) => {
        this.setState({
            text: event.target.value
        });
    };

    uploadFile = async (file, type) => {
        try {
            this.setState({
                uploading: true,
                sending: true,
                uploadingFileName: file.name
            });

            if(!file){
                throw new Error("Arquivo não encontrado.")
            }else if(!type){
                throw new Error("Informe o tipo do arquivo.")
            }

            let response = await chatResource.upload(file, this.props.token);
            console.log(response);

            if (!response.data.success) {
                throw new Error(response.data.message);
            }

            await this.sendMessage(response.data.file.name, type, response.data.file.key, response.data.file.size);

            this.setState({
                uploading: false,
                sending: false,
                uploadingFileName: null,
                blobURL: null
                // messages: [
                //     response.data.newMessage,
                //     ...this.props.messages,
                // ]
            });
        } catch(err){
            console.log(err);

            this.setState({
                uploading: false,
                sending: false,
            });

            alert(err.message);
        }
    };

    onChangeHandler = (event) => {
        console.log("onChangeHandler", event);
        this.uploadFile(event.target.files[0], "file");

        // console.log(event.target.files);

        // this.setState({
        //   selectedFile: event.target.files[0],
        // }, () => {
        //   this.uploadPrescription();
        // });
    };

    startRecord = async () => {
        try{

            await Mp3Recorder.start();

            this.setState({
                recording: true,
                duration: 0
            });

            this.timer = setInterval(() => {
                this.setState({
                    duration: this.state.duration + 1000
                });
            }, 1000);
        }catch (err) {
            console.log("startRecord", err);

            this.setState({
                recording: false
            });
        }
    };

    stopRecord = async () => {
        try{

            // Mp3Recorder.stop()
            //     .getMp3().then(([buffer, blob]) => {
            //     // do what ever you want with buffer and blob
            //     // Example: Create a mp3 file and play
            //     const file = new File(buffer, 'me-at-thevoice.mp3', {
            //         type: blob.type,
            //         lastModified: Date.now()
            //     });
            //
            //     const player = new Audio(URL.createObjectURL(file));
            //     player.play();
            //
            //     this.setState({
            //         recording: false,
            //         blobURL: URL.createObjectURL(file)
            //     });
            // });

            let [buffer, blob] = await Mp3Recorder.stop().getMp3();
            // const blobURL = URL.createObjectURL(blob);
            // console.log("result stopRecord", buffer, blob, blobURL);

            clearInterval(this.timer);

            const fileName = 'audio-'+new Date().getTime()+'.mp3';

            const file = new File(buffer, fileName, {
                type: blob.type,
                lastModified: Date.now()
            });

            this.setState({
                recording: false,
                // blobURL: blobURL
                blobURL: file,
                uploadingFileName: fileName
            });

        }catch (err) {
            console.log("stopRecord", err);

            this.setState({
                recording: false
            });
        }
    };

    cancelAudio = () => {
        this.setState({
            recording: false,
            duration: 0,
            blobURL: null,
            uploadingFileName: null
        });
    };

    render() {

        let sendButtonIcon;
        if(this.state.text === ''){
            if(this.state.recording){
                sendButtonIcon = mdiStop;
            }else{
                if(!this.state.blobURL){
                    sendButtonIcon = mdiMicrophone;
                }else{
                    sendButtonIcon = mdiSend;
                }
            }
        }else{
            sendButtonIcon = mdiSend;
        }

        return(
            <>
                {/*<h1 style={{fontWeight: 'bold', textAlign: 'left', marginBottom: 0, marginTop: 10, padding: 0}}>Mensagens</h1>*/}

                {this.props.loading && <div style={{display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                    <Loader/>
                </div>}

                {!this.props.loading && <div style={{marginTop: 20, position: 'relative', height: 'calc(100% - 25px)'}}>
                    <div
                        style={{
                            padding: 20,
                            maxHeight: 'calc(100% - 80px)',
                            height: 'calc(100% - 80px)',
                            overflowY: 'scroll',
                            display: 'flex',
                            flexDirection: 'column-reverse',
                            justifyContent: this.props.messages.length === 0 || this.props.loading ? 'center' : 'initial'
                        }}
                        onScroll={(event) => {
                            // console.log("scroll", event);

                            const scrollTop = this.scroll.current.scrollTop;
                            // const scrollY = window.scrollY; //Don't get confused by what's scrolling - It's not the window
                            // console.log(`onScroll, window.scrollY: ${scrollY} myRef.scrollTop: ${scrollTop}`);

                            this.setState({
                                scrollTop: scrollTop
                            });
                        }}
                        ref={this.scroll}
                    >
                        {this.props.messages.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    style={{
                                        // padding: 15,
                                        // borderRadius: 5,
                                        // backgroundColor: '#ececec',
                                        maxWidth: '80%',
                                        alignSelf: item.user_from === this.props.user._id ? 'flex-end' : 'flex-start',
                                        marginTop: 5,
                                        // wordBreak: 'break-all'
                                    }}
                                >
                                    <div
                                        style={{
                                            padding: 15,
                                            borderRadius: 5,
                                            backgroundColor: '#ececec',
                                            // maxWidth: '80%',
                                            // marginTop: 5,
                                            wordBreak: 'break-all'
                                        }}
                                    >
                                        {item.type !== 'file' && item.type !== 'audio' && <span>{item.content}</span>}

                                        {item.type ==='file' && <a href={item.content_url} target={"_blank"} rel="noopener noreferrer" style={{display: 'flex', textDecoration: 'underline'}}>
                                            <Icon
                                                path={mdiPaperclip}
                                                title="Arquivo"
                                                size={0.7}
                                                style={{marginRight: 3}}
                                                color={"#1f295c"}
                                            />
                                            <span>{item.content}</span>
                                        </a>}

                                        {item.type === 'audio' && <audio controls>
                                            <source src={item.content_url} type="audio/mpeg"/>
                                        </audio>}

                                        <div style={{fontSize: 11, marginTop: 7, textAlign: 'right'}}>
                                            {Moment(item.created_at).format('DD/MM HH:mm')}
                                            <Icon path={item.status === 'read' ? mdiCheckAll : mdiCheck}
                                                  size={0.5}
                                                  style={{marginLeft: 3, marginBottom: -2}}
                                                  color="#1f295c"
                                            />
                                        </div>
                                    </div>

                                    {/*{(index === this.props.messages.length-1 || (new Date(item.created_at).getDate() !== new Date(this.props.messages[index > 0 ? index-1 : 0].created_at).getDate())) && <div style={{marginTop: 20, fontSize: 14, marginBottom: 20, textAlign: 'center', borderRadius: 5, padding: '5px 10px', backgroundColor: '#1f295c', color: '#fff'}}>{Moment(item.created_at).format('DD/MM')}</div>}*/}
                                </div>
                            )
                        })}

                        {this.props.messages.length === 0 && <div style={{textAlign: 'center'}}>Sem mensagens cadastradas.</div>}
                        {this.props.fetching && <div style={{paddingTop: 20, paddingBottom: 20, textAlign: 'center'}}>
                            <Loader/>
                        </div>}
                    </div>

                    <div style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 10,
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        paddingLeft: 20,
                        paddingRight: 20,
                    }}>

                        <button
                            onClick={() => {
                                if(!this.state.recording && !this.state.blobURL){
                                    this.fileInput.click();
                                }
                            }}
                            style={{
                                cursor: 'pointer',
                                outline: 0,
                                border: 'none',
                                paddingBottom: 10,
                                paddingTop: 10,
                                paddingLeft: 5,
                                paddingRight: 5,
                                background: 'transparent',
                                position: 'absolute'
                            }}
                        >
                            <Icon
                                path={this.state.recording || this.state.blobURL ? mdiMicrophone : mdiPaperclip}
                                color={this.state.recording || this.state.blobURL ? "#f0293e" : "#555"}
                                title="Anexar"
                                size={1}
                            />
                        </button>

                        <Input
                            name={"message"}
                            type={"text"}
                            placeholder={"Digite a mensagem"}
                            styleContainer={{width: '100%', marginRight: 5}}
                            style={{width: '100%', fontSize: 16, minHeight: 45, maxHeight: 150, paddingLeft: 32, paddingTop: 12}}
                            onChange={this.onChangeText}
                            value={this.state.recording ? (this.state.duration === 0 ? "Gravando..." : ((this.state.duration/1000)+" s")) : (this.state.uploadingFileName || this.state.text)}
                            onKeyDown={(event) => {
                                if(event.key === 'Enter') {
                                    this.sendMessage(this.state.text, "text");
                                }
                            }}
                            disabled={this.state.uploading || this.state.sending || this.state.recording}
                        />

                        <div style={{flex: 0, position: 'relative'}}>
                            <SendButton
                                loading={this.state.sending}
                                type={"submit"}
                                onClick={() => {
                                    if(this.state.text === ''){
                                        if(this.state.recording){
                                            this.stopRecord();
                                        }else{
                                            if(!this.state.blobURL){
                                                this.startRecord();
                                            }else{
                                                this.uploadFile(this.state.blobURL, "audio");
                                            }
                                        }
                                    }else{
                                        this.sendMessage(this.state.text, "text");
                                    }
                                }}
                                style={{
                                    paddingLeft: 15,
                                    paddingRight: 15,
                                    width: 50,
                                    minWidth: 50,
                                    maxWidth: 50,
                                    height: '100%',
                                    backgroundColor: this.state.recording ? '#f0293e' : '#1f295c'
                                }}
                                styleLoader={{
                                    // transform: 'scale(0.2)',
                                    width: 30,
                                    height: 30,
                                }}
                            >
                                <Icon path={sendButtonIcon}
                                      size={1}
                                      color="#fff"
                                />
                            </SendButton>

                            {this.state.blobURL && !this.state.uploading && <button
                                onClick={() => {
                                    this.cancelAudio();
                                }}
                                style={{
                                    height: 36,
                                    width: 36,
                                    // paddingRight: 15,
                                    backgroundColor: '#f0293e',
                                    position: 'absolute',
                                    bottom: 50,
                                    right: 9,
                                    borderRadius: '50%',
                                    border: 'none',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer'
                                }}
                            >
                                <Icon path={mdiClose}
                                      size={1}
                                      color="#fff"
                                />
                            </button>}
                        </div>

                        <input
                            style={{
                                display: 'none',
                                visibility: 'hidden',
                            }}
                            type="file"
                            name="file"
                            ref={fileInput => this.fileInput = fileInput}
                            onChange={this.onChangeHandler}
                        />

                        {/*{this.state.blobURL && <audio controls>*/}
                        {/*    <source src={this.state.blobURL} type="audio/mpeg"/>*/}
                        {/*</audio>}*/}
                    </div>
                </div>}
            </>
        );
    }
}

export default connect(mapStateToProps)(withRouter(Chat));
