import React, { Component } from 'react';
import { button, buttonSecundary, disabled } from "./SendButton.module.scss";
import colors from "../../styles/colors.module.scss";

import Loader from '../../components/Loader';

export default class SendButton extends Component {
    render(){
        let btnClass = `${button} 
                        ${!this.props.color ? colors.bgDarkBlue : ""} 
                        ${this.props.secundary ? buttonSecundary : ""}
                        ${this.props.disabled ? disabled : ""}`;

        return (
            <button
                id={this.props.id}
                type={this.props.type}
                className={btnClass}
                style={this.props.style}
                disabled={this.props.disabled}
                onClick={this.props.onClick}
            >
                {this.props.loading && <Loader style={this.props.styleLoader} negative={true} />}
                {!this.props.loading && this.props.children}
            </button>
        );
    }
}
